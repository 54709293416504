<template>
  <div class="noticeListBox">
    <div class="helpLeft">
      <div class="leftBox">
        <div class="order centerBox" v-for="(item, index) of articleList" :key="'help' + item.id">
          <h3 style="padding-left: 10px">
            <!-- <i class="orderIcon"></i> -->
            {{ item.name }}
          </h3>
          <div>
            <p v-for="(itemC, indexC) of item.noticeDetailList" :key="'noticeDetail' + indexC"
              :class="{ active: helpDetailId === itemC.id }">
              <a @click="showThisDetail(itemC)" href="javascript:;">
                {{ itemC.title }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="helpRight">
      <div v-if="helpDetailContent && helpDetailContent !== ''" style="cursor: pointer" class="noticeHeader">
        <i class="el-icon-arrow-left"></i>
        {{ helpDetailContent.title }}
      </div>
      <div v-if="helpDetailContent.detail" class="noticeDetail">
        <div v-if="helpDetailContent.detail !== ''" v-html="helpDetailContent.detail"></div>
        <div v-else class="dataNone">
          <a to="javascript:;">
            <img src="../assets/images/common/dataNone.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";
import Vue from "vue";
import { getUserInfo } from '../assets/js/common/common';
export default {
  name: "ArticleInfo",
  data() {
    return {
      pageNo: 1,
      pageSize: 20,
      totalCount: 0,
      detailShowState: false,
      helpDetailContent: "",
      articleList: [],
      helpDetailId: null,
    };
  },
  created() {
    let helpDetailId = this.$route.query.id;
    if (helpDetailId && helpDetailId !== "") {
      this.helpDetailId = helpDetailId;
    }
    this.getArticleList();
  },
  methods: {
    async getArticleList() {
      let url = "notice/noticeBelow/list";
      let userInfo = getUserInfo();
      let data = {
      };
      if (userInfo && userInfo.company && userInfo.company.id) {
        data.companyId = userInfo.company.id
      }
      let res = await getAction(url, data);
      if (res.code == 200 && res.success) {
        let resData = res.result;
        let articleList = resData.records;
        this.articleList = articleList;
        console.log("获取的帮助中心列表为++++++", res);
        let helpDetailId = this.helpDetailId;
        console.log("获取的帮助中心列表为helpDetailId++++++", helpDetailId);
        // 判断用户是否选中指定的帮助公告信息
        if (!helpDetailId || helpDetailId == null || helpDetailId == "") { // 1.没有选中默认为第一条
          console.log("articleList++++++++", articleList)
          if (
            articleList.length > 0 &&
            articleList[0].noticeDetailList.length > 0
          ) {
            let defaulthelpDetail = articleList[0].noticeDetailList[0];
            this.helpDetailId = defaulthelpDetail.id;
          }
        }
        // 获取帮助中心详情信息
        this.getHelpDetailContent()
      }
    },
    // 获取帮助中心详情数据
    async getHelpDetailContent() {
      let helpDetailId = this.helpDetailId;
      let url = "notice/noticeBelowDetail/queryById"
      let data = {
        id: helpDetailId
      }
      let res = await getAction(url, data);
      console.log("获取的帮助中心详情数据为++++++", res)
      if (res.code == 200 && res.success) {
        this.helpDetailContent = res.result;
      }
    },
    showThisDetail(item) {
      console.log("展示当前的数据为++++++++", item);
      this.helpDetailId = item.id;
      this.getHelpDetailContent()
    },
    // 处理公告时间
    cleanData(item, flag) {
      let date = item.createTime;
      let dateArr = date.split(" ");
      let nianyueri = dateArr[0].split("-");
      if (flag == 1) {
        return `${nianyueri[0].substring(2, 4)}-${nianyueri[1]}`;
      } else if (flag == 2) {
        return nianyueri[2];
      }
    },
    // 查看公告详情
    linkNoticeDetail(item) {
      console.log("当前选择的公告的数据为++++++", item);
      this.detailShowState = true;
      this.helpDetailContent = item;
    },
  },
  watch: {
    $route(to, form) {
      if (to.query && to.query.id) {
        let helpDetailId = to.query.id;
        this.helpDetailId = helpDetailId;
        this.getHelpIdDetail(helpDetailId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/order/left";

.noticeListBox {
  width: 1190px;
  margin: 0 auto;
  @include clear;
  margin-top: 10px;
  margin-bottom: 25px;

  .helpRight {
    float: right;
    width: 930px;

    .noticeHeader {
      height: 35px;
      line-height: 35px;
      padding-left: 15px;
      background: #f3f3f3;
      font-size: 13px;
      color: $font-color;
    }

    .notcieList {
      .noticeItem {
        width: 100%;
        height: 80px;
        padding-top: 5px;
        background: #ffffff;
        position: relative;
        padding-left: 85px;
        box-shadow: 0 9px 5px 0px #bad3e83b;
        cursor: pointer;

        &:hover .noticeTitle,
        &:hover .introduction {
          color: $base-color;
        }

        .noticeDate {
          position: absolute;
          width: 65px;
          height: 63px;
          top: 0;
          left: 0;
          background: url("~@/assets/images/center/notice/dateBg.png") no-repeat center;

          p {
            color: #ffffff;
            width: 100%;
            text-align: center;
          }

          .nianyue {
            font-size: 15px;
            margin-top: 5px;
          }

          .ri {
            font-size: 24px;
            margin-top: -5px;
          }
        }

        .noticeTitle {
          height: 35px;
          line-height: 35px;
          font-size: 16px;
          color: $font-color;
          font-weight: normal;
        }

        .introduction {
          font-size: 13px;
          color: $font-color;
        }
      }
    }
  }
}

::v-deep .noticeDetail {
  * {
    font-size: 100%;
  }
  img {
    width: 100% !important;
  }
}


.pageFooter {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>