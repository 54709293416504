<template>
  <div class="credentialsBox">
    <div class="credentialsHeader">{{ credentialsData.credentialsName }}</div>
    <div class="imgBox">
      <img
        class="credentialsImg"
        :src="credentialsData.imgUrl"
        :alt="credentialsData.credentialsName"
      />
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";
export default {
  name: "Credentials",
  data() {
    return {
      credentialsId: "",
      credentialsData: {}, // 查询的公司资质公告
    };
  },
  created() {
    let credentialsId = this.$route.query.credentialsId;
    if (credentialsId && credentialsId !== "") {
      this.credentialsId = credentialsId;
      this.getCredentialsList();
    }
  },
  methods: {
    getCredentialsList() {
      let _this = this;
      let credentialsId = this.credentialsId;
      let data = {
        id: credentialsId,
      };
      let url = "/company/companyCredentials/queryById";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          console.log("根据ID查询出来的公司资质数据++++++", resData);
          _this.credentialsData = resData;
        }
      });
    },
  },
  watch: {
    $route(to, form) {
      if (to.query && to.query.credentialsId) {
        let credentialsId = to.query.credentialsId;
        this.credentialsId = credentialsId;
        this.getCredentialsList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credentialsBox {
  width: 930px;
  float: right;
  .credentialsHeader {
    height: 35px;
    line-height: 35px;
    padding-left: 15px;
    background: #f3f3f3;
    font-size: 13px;
    color: $font-color;
  }
  .imgBox {
    text-align: center;
    .credentialsImg {
      margin: 0 auto;
      max-width: 930px;
    }
  }
}
</style>