<template>
  <div class="loginContent container">
    <div class="loginHeader container">
      <div class="container_box">
        <div class="logo">
          <router-link to="/index">
            <img src="../assets/images/common/logo.png" alt="" />
          </router-link>
        </div>
        <div class="telBox">
          <img src="../assets/images/login/tel.png" alt="" />
        </div>
      </div>
    </div>
    <div class="loginMain container">
      <div class="container_box">
        <div class="liuchengBox">
          <div class="liucheng liucheng1">
            <p>基本信息</p>
            <p :class="{ flowTrue: changeFlow >= 1 }" class="circle">1</p>
          </div>
          <div class="liucheng liucheng2">
            <p>完善资质</p>
            <p :class="{ flowTrue: changeFlow >= 2 }" class="circle">2</p>
          </div>
          <div class="liucheng liucheng3">
            <p>等待审核</p>
            <p :class="{ flowTrue: changeFlow >= 3 }" class="circle">3</p>
          </div>
        </div>
        <!-- 基本信息 -->
        <div v-if="changeFlow == 1" class="changeFlowPwdBox baseDataContent">
          <div class="baseDataLabel">
            <p class="labelText">营业执照：</p>
            <div class="phoneNumberInput">
              <img class="phoneIcon" src="../assets/images/login/zizhiIcon.png" alt="" />
              <input placeholder="请填写营业执照号码（必填）" @blur="verifyCreditCode" v-model="baseData.creditCode"
                class="phoneNumber" type="text" />
            </div>
            <p class="verifyText">{{ verifyText.creditCode }}</p>
          </div>
          <div class="baseDataLabel">
            <p class="labelText">手机号码：</p>
            <div class="phoneNumberInput">
              <img class="phoneIcon" src="../assets/images/login/mobliePhone.png" alt="" />
              <input oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写绑定的手机号" v-model="baseData.phone" @blur="verifyPhone" class="phoneNumber"
                type="text" />
              <p class="verifyText">{{ verifyText.phone }}</p>
            </div>
          </div>
          <div class="baseDataLabel">
            <p class="labelText">验证码：</p>
            <div class="verifyCodeBox">
              <div class="verifyCode">
                <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
                <input v-model="baseData.verifyCode" @blur="verifyVerifyCode" placeholder="短信验证码" type="text" />
              </div>
              <a :disabled="verfiyState ? true : false" @click="phoneVerify" href="javascript:;"
                class="getVerfiyCodeBtn">
                {{ verfiyState ? `${timeNumber}S再获取` : "发送验证码" }}
              </a>
            </div>
            <p class="verifyText">{{ verifyText.verifyCode }}</p>
          </div>
          <div class="baseDataLabel">
            <p class="labelText">登录密码：</p>
            <div class="phoneNumberInput">
              <img class="phoneIcon" src="../assets/images/login/passwordIcon.png" alt="" />
              <input placeholder="请填写登录密码" class="phoneNumber" @blur="verifyPassword" v-model="baseData.password"
                type="password" />
            </div>
            <p class="verifyText">{{ verifyText.password }}</p>
          </div>
          <div class="baseDataLabel">
            <p class="labelText">确认密码：</p>
            <div class="phoneNumberInput">
              <img class="phoneIcon" src="../assets/images/login/passwordIcon.png" alt="" />
              <input placeholder="请确认登录密码" @blur="verifyConfirmPassword" v-model="baseData.confirmPassword"
                class="phoneNumber" type="password" />
            </div>
            <p class="verifyText">{{ verifyText.confirmPassword }}</p>
          </div>

          <div @click="changeFlowClick(2)" :class="{ changeLoading: changeLoading === true }" class="loginBtn">
            <i v-if="changeLoading" class="el-icon-loading"></i>
            下一步
          </div>
        </div>
        <!-- 补充资质 -->
        <div v-else-if="changeFlow == 2" class="certTypeContent">
          <div class="certTypeHeader">
            <el-tabs v-model="customerType" type="card" @tab-click="changeCustomerType">
              <el-tab-pane v-for="(itemF, indexF) of customerTypeList" :key="`customerType${itemF.id}index${indexF}`"
                :label="itemF.name" :name="`${itemF.id}`">
                <div class="certList">
                  <div class="certTypeTitle">
                    *上传资料必须清晰无遮挡，且加盖公章
                  </div>
                  <div class="certTypeLabel">
                    <div class="certLabel" v-for="(item, index) of certImgList" :key="'certImg' + index">
                      <p class="certTypeName">
                        {{ index + 1 }}.{{ item.certTypeName
                        }}{{ item.mustFlag == "1" ? "(必填)" : "(非必填)" }}
                      </p>
                      <el-upload class="avatar-uploader" action="/web/customer/customerCert/add"
                        :headers="{ 'Shopping-Access-Token': accessToken }"
                        :data="{ type: 12, certTypeId: item.certTypeId }" :show-file-list="false"
                        :on-success="handleAvatarSuccess">
                        <div v-if="item.certPicUrl && item.certPicUrl !== ''" class="certPicUrlBox">
                          <div class="certImgBox">
                            <img v-if="item.certPicUrl" :src="item.certPicUrl" class="certImgUrl" />
                          </div>
                          <div class="certUrlMark">
                            <span @click.stop="handlePictureCardPreview(item)" class="preview">
                              <i class="el-icon-zoom-in"></i>
                            </span>
                            <span slot="trigger" class="edit">
                              <i class="el-icon-edit"></i>
                            </span>
                          </div>
                        </div>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>

                      <p class="certAuditRemark">
                        {{
                            item.certAuditRemark
                              ? `审批驳回：${item.certAuditRemark}`
                              : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div v-if="registerBtnStatus && changeFlowBtn" @click="changeFlowClick(3)"
              :class="{ changeLoading: changeLoading === true }" class="loginBtn">
              <i v-if="uploadloding" class="el-icon-loading"></i>
              上传资质
            </div>
            <div v-else-if="b2bAuditStatus == '0'" @click="linkHome" class="loginBtn">等待审核中</div>
            <div v-else-if="b2bAuditStatus == '2' && changeFlowBtn" @click="changeFlowClick(3)"
              :class="{ changeLoading: changeLoading === true }" class="loginBtn">重新上传</div>

          </div>
        </div>
        <div v-else-if="changeFlow == 3" class="changeFlowPwdBox success">
          <img class="successImg" src="../assets/images/login/pwdSuccess.png" alt="" />
          <p class="successP">已成功提交注册申请，请耐心等待审核！</p>
          <p class="successP">
            点击返回<router-link to="/login">登录</router-link>页面
          </p>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getAction, postAction } from "../api/manage";
import Footer from "../components/common/Footer.vue";
import { SHOPPING_ACCESS_TOKEN, USER_INFO } from "../store/mutation-types";
export default {
  components: { Footer },
  name: "Register",
  data() {
    return {
      verfiyState: false,
      timeNumber: 60,
      changeLoading: false,
      changeFlow: 1,
      changeShowPwd: true,
      changeShowPwd2: true,
      hasIeBrowser: false,
      dialogVisible: false,
      dialogImageUrl: "",
      baseData: {
        // 用户注册基本数据
        creditCode: "", // 营业执照编码
        phone: "", // 注册手机号
        verifyCode: "", // 手机号验证码
        password: "", // 登录密码
        confirmPassword: "", // 确认密码
      },
      verifyText: {
        creditCode: "", // 营业执照编码
        phone: "", // 注册手机号
        verifyCode: "", // 手机号验证码
        password: "", // 登录密码
        confirmPassword: "", // 确认密码
      },
      customerType: "",
      b2bAuditStatus: null,
      customerTypeName: "",
      certTypeId: "",
      accessToken: "",
      changeFlowBtn: true,
      customerRegisterCompanyId: "",
      customerRegisterId: "",
      customerTypeList: [], // 对应注册公司的客商分类
      uploadloding: false, // 上传图片加载状态
      certImgList: [], // 所需资质列表数据
      loginFrom: false,
      registerBtnStatus: false, // 资质按钮状态  true注册上传状态  false是登录显示
    };
  },
  created() {
    let status = this.$route.query.status;
    if (status && status == "2") {
      this.changeFlow = parseInt(2);
      let userInfoEncode = this.$ls.get(USER_INFO);
      let userInfoStr = this.$base64.decode(userInfoEncode);
      let userInfo = JSON.parse(userInfoStr);
      this.accessToken = userInfo.token;
      // 赋值客户注册id
      if (userInfo.id && userInfo.id !== "") {
        this.customerRegisterId = userInfo.id;
      }
      // 赋值客户选择类型
      if (userInfo.typeId && userInfo.typeId !== "") {
        console.log("客户选择的类型为", userInfo)
        this.customerType = userInfo.typeId;
        this.customerTypeName = userInfo.typeName;
        this.loginFrom = true;
      }
      // 赋值客户所注册公司id
      if (userInfo.certCompanyId && userInfo.certCompanyId !== "") {
        this.customerRegisterCompanyId = userInfo.certCompanyId;

        //0 待审核  2已驳回
        this.b2bAuditStatus = userInfo.b2bAuditStatus;
      }
      this.getCustomerTypeList();
      this.registerBtnStatus = false
    } else {
      this.registerBtnStatus = true
    }
  },
  methods: {
    // 获取验证码
    phoneVerify() {
      let _this = this;
      this.verfiyState = true;
      let timeNumber = this.timeNumber;
      let interVal = setInterval(function () {
        timeNumber--;
        _this.timeNumber = timeNumber;
        if (timeNumber == 0) {
          clearInterval(interVal);
          _this.timeNumber = 60;
          _this.verfiyState = false;
        }
      }, 1000);
    },
    // 跳转到首页中
    linkHome() {
      this.$router.push('/')

    },
    // 用户注册流程操作
    changeFlowClick(status) {
      let changeFlowStatus = parseInt(status);
      // 判断各个流程操作
      if (changeFlowStatus == 2) {
        // 提交用户基本数据获得用户注册ID
        this.submitCustomerBaseData();
      } else if (changeFlowStatus == 3) {
        // 验证客户所注册的资质是否全部上传
        let certList = this.certImgList;
        let updateStatus = true;
        for (let key of certList) {
          console.log("key.mustFlag++++++",key.mustFlag)
          console.log("key.certPicUrl++++++",key.certPicUrl)
          if (key.mustFlag == "1" && (!key.certPicUrl || key.certPicUrl == "")) {
            this.$message({
              message: `请上传${key.certTypeName}资质图片！`,
              type: "error",
            });
            updateStatus = false
            return false;
          }
        }
        if (updateStatus) {
          // 资质上传成功后将用户选择的注册类型进行修改
          this.changeRegister();
        } else {
          this.$message({
            message: `请上传所需资质图片！`,
            type: "error",
          });
        }
      }
    },
    // 设置用户注册选择的注册身份
    changeRegister() {
      let url = "/customer/customerRegister/update";
      let customerRegisterId = this.customerRegisterId;
      let typeId = this.customerType;
      let typeName = this.customerTypeName;
      let data = {
        id: customerRegisterId,
        typeId: typeId,
        typeName: typeName,
      };
      postAction(url, data).then((res) => {
        console.log("上传资质传给后台的数据为++++++", res);
        if (res.code == 200 && res.success) {
          this.changeFlow = 3;
        }
      });
    },
    // 获取平台存在的客商类型列表

    // 用户基本数据提交操作
    submitCustomerBaseData() {
      let _this = this;
      let url = "/customer/customerRegister/add";
      let verifyText = this.verifyText;
      // 验证营业执照
      this.verifyCreditCode();
      // 验证手机号码
      this.verifyPhone();
      // 验证验证码
      this.verifyVerifyCode();
      // 验证用户的登录密码和确认密码
      this.verifyPassword();
      this.verifyConfirmPassword();
      // 没有提示信息了在提交数据
      if (JSON.stringify(verifyText) == "{}") {
        let data = this.baseData;
        console.log("注册添加的基本信息为++++++", data);
        this.changeLoading = true;
        // 注册先使用公司id为W01的数据
        data.companyId = "W01";
        data.password = this.$md5(data.password).substring(8, 24);
        data.confirmPassword = this.$md5(data.confirmPassword).substring(8, 24);
        postAction(url, data).then((res) => {
          console.log("添加注册返回的数据为++++++", res);
          if (res.code === 200 && res.success) {
            _this.changeFlow = 2;
            let result = res.result;
            let customer = result.customer;
            let customerRegister = result.customerRegister;
            let customerToken = customer.token;
            let loginToken = _this.$base64.encode(customerToken);
            _this.$ls.set(
              SHOPPING_ACCESS_TOKEN,
              loginToken,
              24 * 60 * 60 * 1000
            );
            _this.$store.commit("SET_TOKEN", loginToken);
            _this.customerRegisterId = customerRegister.id;
            _this.accessToken = customerToken;
            _this.customerRegisterCompanyId = customerRegister.companyId;
            // 注册成功后拿取对应的注册id获取资质列表
            _this.getCustomerTypeList();
          } else {
            _this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    },
    // 验证营业执照号码
    verifyCreditCode() {
      let creditCode = this.baseData.creditCode;
      if (creditCode === "") {
        this.verifyText.creditCode = "*营业执照号码不能为空！";
      } else if (
        !/(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/.test(
          creditCode
        )
      ) {
        this.verifyText.creditCode = "*请输入正确的营业执照号码！";
      } else {
        this.verifyText.creditCode = "";
        delete this.verifyText.creditCode;
      }
    },
    // 验证客户输入的手机号码
    verifyPhone() {
      let phone = this.baseData.phone;
      if (phone === "") {
        this.verifyText.phone = "*手机号码不能为空！";
      } else if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(phone)) {
        this.verifyText.phone = "*请输入正确的手机号码！";
      } else {
        this.verifyText.phone = "";
        delete this.verifyText.phone;
      }
    },
    // 验证手机验证码
    verifyVerifyCode() {
      let verifyCode = this.baseData.verifyCode;
      if (verifyCode === "") {
        this.verifyText.verifyCode = "*请输入验证码！";
      } else {
        this.verifyText.verifyCode = "";
        delete this.verifyText.verifyCode;
      }
    },
    // 验证用户填写的登录密码
    verifyPassword() {
      let password = this.baseData.password;
      if (password === "") {
        this.verifyText.password = "*请输入登录密码！";
      } else {
        this.verifyText.password = "";
        delete this.verifyText.password;
      }
    },
    // 验证用户填写的登录密码
    verifyConfirmPassword() {
      let password = this.baseData.password;
      let confirmPassword = this.baseData.confirmPassword;
      if (confirmPassword === "") {
        this.verifyText.confirmPassword = "*请再次输入登录密码！";
      } else if (confirmPassword !== password) {
        this.verifyText.confirmPassword = "*两次输入的密码不一致！";
      } else {
        this.verifyText.confirmPassword = "";
        delete this.verifyText.confirmPassword;
      }
    },
    // 获取平台存在的客户类型
    getCustomerTypeList() {
      let _this = this;
      let url = "/customer/customerType/list";
      let customerRegisterId = this.customerRegisterId;
      let customerRegisterCompanyId = this.customerRegisterCompanyId;
      let data = {
        customerRegisterId: customerRegisterId,
        companyId: customerRegisterCompanyId,
      };
      getAction(url, data).then((res) => {
        console.log("获取当前客商类型对应的资质的数据", res);
        if (res.code === 200 && res.success) {
          _this.customerTypeList = res.result;
          if (res.result && res.result.length > 0) {
            let customerType = _this.customerType;
            console.log("customerType++++++++", customerType)
            if (!customerType || customerType == "" || customerType == "0") {
              _this.customerType = `${res.result[0].id}`;
              _this.customerTypeName = `${res.result[0].name}`;
            }
            // 获取当前类型对应的资质列表
            _this.getCertFileList();
          }
        }
      });
    },
    // 获取当前类型对应的资质列表
    getCertFileList() {
      let _this = this;
      let customerType = this.customerType;
      let loginFrom = this.loginFrom;
      let url = "/customer/customerTypeCertMap/listByTypeId";
      let data = {
        typeId: customerType,
      };
      getAction(url, data).then((res) => {
        console.log("当前返回的资质列表为", res);
        if (res.code === 200 && res.success) {
          _this.certImgList = res.result;
          // 获取资质列表完成后，判断用户是否需要重新上传
          let certAuditRemarkCount = res.result.length;
          for (let key of _this.certImgList) {
            if (key.certAuditStatus == "2" || !key.certPicUrl  || key.certPicUrl == '') {
              certAuditRemarkCount--;
            }
          }
          // 全部资质上传完成后显示上传按钮
          if (certAuditRemarkCount !== _this.certImgList.length) {
            _this.changeFlowBtn = false;
          } else {
            _this.changeFlowBtn = true;
          }
        }
      });
    },
    // 选择客户资质类型
    changeCustomerType(tab) {
      let customerType = tab.name;
      this.customerType = customerType;
      this.customerTypeName = tab.label;
      this.getCertFileList();
    },
    // 删除资质图片
    handleRemove(file) {
      console.log(file);
    },
    // 图片预览操作
    handlePictureCardPreview(item) {
      this.dialogImageUrl = item.certPicUrl;
      this.dialogVisible = true;
    },
    // 修改图片时，清空驳回信息
    // clearAuditRemark(item) {
    //   item.certAuditRemark = "";
    // },
    // 上传资质图片
    handleAvatarSuccess(response) {
      if (response.code == 200 && response.success) {
        console.log("上传资质证书成功返回的数据为++++", response);
        this.getCertFileList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/login/forgetPwd";
</style>