<template>
  <div class="areaContainer" :style="brandDetail &&
      brandDetail.backgroundColor &&
      brandDetail.backgroundColor !== ''
      ? `background-color:${brandDetail.backgroundColor}`
      : ''
    ">
    <!-- banner区域 -->
    <div v-if="brandDetail && brandDetail.picUrl && brandDetail.picUrl !== ''" class="areaBanner container">
      <img :src="brandDetail.picUrl" alt="brandDetail.name" />
    </div>
    <!-- end banner区域 -->
    <!-- 奖券列表 -->
    <div class="activityBox container_box ">

      <div id="yhqNav" v-if="ticketDataList.length > 0" class="ticketListContent activityItem">
        <div class="ticketListMain">
          <div class="ticketDataBox" v-for="(item, index) of ticketDataList" :key="'Rightcart' + index">
            <div class="ticketContent" :class="{
              productTicket: item.astrictProductFlag ==
                1
            }">
              <div class="ticketTop">
                <!-- 满减券名称 -->
                <div v-if="item.ticketType == 1" class="price">
                  <p class="typeText">￥</p>
                  <p class="money">{{ item.ticketDiscountMoney }}</p>
                </div>
                <!-- 打折券名称 -->
                <div v-else-if="item.ticketType == 2" class="price">
                  <p class="money">{{ parseFloat((item.ticketDiscountMoney * 10).toPrecision(12)) }}</p>
                  <p class="typeText">折</p>
                </div>
                <!-- 优惠券的名称 -->
                <div class="ticketName">
                  <p class="name">{{ item.ticketName }}</p>
                </div>
                <div class="ticketTime">
                  <p>有效期限：{{ timeClear(item.startTime) }}-{{
                    timeClear(item.endTime)
                  }}</p>
                </div>
              </div>
              <div class="ticketBottom">
                <div class="ticketDetail">
                  <p class="detail"><span>适用范围:</span>{{ item.ticketDetail }}</p>
                </div>
                <div class="ticketBtnBox">
                  <a @click="useTicket(item)" v-if="item.ticketReceiveStatus == 1" class="goUseBtn"
                    :class="{ goUseBtn2: item.astrictProductFlag !== '0' }" href="javascript:;">去使用</a>
                  <a v-else-if="item.ticketReceiveStatus == 0" class="ticketOverBtn" href="javascript:;">已领完</a>

                  <a v-else class="lingquBtn" :class="{ lingquBtn2: item.astrictProductFlag !== '0' }"
                    @click="lingquTicket(item)" href="javascript:;">领取</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";
import { getUserInfo } from "../assets/js/common/common";
export default {
  name: "CouponList",
  data() {
    return {
      ticketDataList: [],
      loginState: false,
      areaId: {},
      brandDetail: {}, // 活动专区相应的数据
    };
  },
  created() {
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
    }
    let areaId = this.$route.query.id
    if (areaId && areaId !== '') {
      this.areaId = areaId
    }
    this.getZoneDetailData();
    this.getTicketList();
  },
  methods: {
    // 处理时间格式
    timeClear(time) {
      if (time && typeof time !== "undefined") {
        let timeArr = time.split(" ");
        return timeArr[0];
      }
    },
    // 去使用优惠券
    useTicket(item) {
      let astrictProductFlag = item.astrictProductFlag
      if (astrictProductFlag !== '0') {
        let discountTicketId = item.ticketId;
        this.$router.push({
          name: 'productList',
          query: {
            discountTicketId: discountTicketId
          }
        })
      } else {
        this.$router.push({
          name: 'productList'
        })
      }
    },
    // 领取优惠券
    lingquTicket(item) {
      let _this = this;
      let data = {
        id: item.id,
      };
      let url =
        "/discountTicket/discountTicketStrategyReceive/receiveDiscountTicket";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.getTicketList();
        }
      });
    },
    // 获取奖券列表
    getTicketList() {
      let _this = this;
      this.ticketLoaing = true;
      let url =
        "/discountTicket/discountTicketStrategyReceive/getCanReceiveDiscountTicketlist";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          this.ticketLoaing = false;
          let resData = res.result;
          _this.ticketDataList = resData;
          if (!resData || resData.length === 0) {
            _this.deleteNavList(1);
          }
        }
      });
    },
    async getZoneDetailData() {
      let areaId = this.areaId
      let url = "/area/brandAreavo/get";
      let data = {
        id: areaId
      }
      let res = await getAction(url, data);
      console.log("获取当前的奖券专区的配置信息++++++", res)
      if (res.code == 200 && res.success) {
        this.brandDetail = res.result;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/activity/activity";
@import "../assets/styles/product/img/img";

.pageFooter {
  margin-bottom: 50px;
}
</style>