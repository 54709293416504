<template>
  <div>
    <div class="indexFloor container_box">
      <div v-for="(item, index) of floorListBeforeData" :key="'floorIndexBefore' + index" class="floorItem"
        :class="{ floorNavClass: (item.floor.title && item.floor.title !== '') }">
        <!-- 产品楼层 -->
        <div v-if="item.floor.typeId === 2 && item.sort == 0" class="productFloor">
          <div v-if="item.floor.title && item.floor.title !== ''" class="floorTitle" :style="item.floor.titleThemeColor
            ? `color:${item.floor.titleThemeColor}`
            : ''
            ">
            <div class="title">
              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, #ffffff, ${item.floor.titleThemeColor});`
                : ''
                " class="leftbg"></span>
              <span>{{ item.floor.title }}</span>
              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, ${item.floor.titleThemeColor}, #ffffff);`
                : ''
                " class="rightbg"></span>
            </div>

            <div v-if="item.floor.timeWarnFlag &&
              item.floor.timeWarnFlag === 1 &&
              item.floor.djsShow
              " :style="`color: ${item.floor.titleThemeColor}`" class="xsqg-content fl">
              {{ item.floor.djsText }}
              <i :style="`background-color: ${item.floor.titleThemeColor}`" v-if="item.floor.suT !== '00'">{{
                item.floor.suT ? item.floor.suT : "00"
              }}</i>
              <span v-if="item.floor.suT !== '00'">天</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suH ? item.floor.suH : "00"
              }}</i>
              <span>时</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suF ? item.floor.suF :
                "00"
              }}</i>
              <span>分</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suM ?
                item.floor.suM : "00"
              }}</i>
              <span>秒</span>
            </div>
            <div v-if="item.floorDetailList &&
                luoboProductData(item.floorDetailList).length > 1
                " class="tagIcon fr">
              <i @click="prevLunBo(item)" class="prevIcon el-icon-caret-left"></i>
              <i @click="nextLunBo(item)" class="nextIcon el-icon-caret-right"></i>
            </div>
          </div>
          <div class="productListMain">
            <ul :id="'productFloor' + item.floor.id" v-if="item.floorDetailList" :ref="'productFloor' + item.floor.id"
              :style="floorStyle(item)">
              <li class="floorItemLi" v-for="(itemC, indexC) of luoboProductData(item.floorDetailList)"
                :key="'floorItem1' + indexC">
                <router-link target="_blank" v-for="(itemAC, indexAC) of itemC" :key="'floorItemC1' + indexAC"
                  :to="{ path: '/productDetail', query: { productId: itemAC.productId } }">
                  <img v-lazy="floorDetailImg(itemAC)" alt="产品图片" />
                  <p v-if="itemAC.title && itemAC.title !== ''" :title="itemAC.title" class="productData">
                    {{ itemAC.title }}
                  </p>
                  <p v-else-if="itemAC.product &&
                    itemAC.product.name &&
                    itemAC.product.name !== ''
                    " :title="itemAC.product.name" class="productData">
                    {{ itemAC.product.name }} {{ itemAC.product.format }}
                  </p>
                  <p class="productPrice">
                    {{ paroductPriceShow(itemAC.product) }}
                  </p>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- END 产品楼层 -->
        <!-- 图片楼层 -->
        <div v-else-if="item.floor.typeId === 3 && item.sort == 0" class="imgFloor">
          <div v-if="item.floor.title && item.floor.title !== ''" class="floorTitle" :style="item.floor.titleThemeColor
            ? `color:${item.floor.titleThemeColor}`
            : ''
            ">
            <div class="title">

              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, #ffffff, ${item.floor.titleThemeColor});`
                : ''
                " class="leftbg"></span>
              <span>{{ item.floor.title }}</span>
              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, ${item.floor.titleThemeColor}, #ffffff);`
                : ''
                " class="rightbg"></span>
            </div>
            <div v-if="item.floor.timeWarnFlag &&
              item.floor.timeWarnFlag === 1 &&
              item.floor.djsShow
              " :style="`color: ${item.floor.titleThemeColor}`" class="xsqg-content fl">
              {{ item.floor.djsText }}
              <i :style="`background-color: ${item.floor.titleThemeColor}`" v-if="item.floor.suT !== '00'">{{
                item.floor.suT ? item.floor.suT : "00"
              }}</i>
              <span v-if="item.floor.suT !== '00'">天</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suH ? item.floor.suH : "00"
              }}</i>
              <span>时</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suF ? item.floor.suF :
                "00"
              }}</i>
              <span>分</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suM ?
                item.floor.suM : "00"
              }}</i>
              <span>秒</span>
            </div>

            <a v-if="item.floor.url && item.floor.url !== ''" :href="item.floor.url" target="_blank" class="activeMore">
              查看更多<i class="el-icon el-icon-arrow-right"></i>
            </a>
          </div>
          <div class="imgListMain">
            <a v-for="(itemC, indexC) of item.floorDetailList" :key="'imageFloorItem' + indexC" @click="gotoUrl(itemC)"
              href="javascript:;">
              <img v-lazy="floorDetailImg(itemC)" alt="" />
            </a>
          </div>
        </div>
        <!-- END 图片楼层 -->
        <div v-else-if="item.floor.typeId === 4 && item.sort == 0" class="mainFloor">
          <div v-if="item.floor.title && item.floor.title !== ''" class="floorTitle" :style="item.floor.titleThemeColor
            ? `color:${item.floor.titleThemeColor}`
            : ''
            ">
            <div class="title">

              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, #ffffff, ${item.floor.titleThemeColor});`
                : ''
                " class="leftbg"></span>
              <span>{{ item.floor.title }}</span>
              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, ${item.floor.titleThemeColor}, #ffffff);`
                : ''
                " class="rightbg"></span>
            </div>

            <div v-if="item.floor.timeWarnFlag &&
              item.floor.timeWarnFlag === 1 &&
              item.floor.djsShow
              " :style="`color: ${item.floor.titleThemeColor}`" class="xsqg-content fl">
              {{ item.floor.djsText }}
              <i :style="`background-color: ${item.floor.titleThemeColor}`" v-if="item.floor.suT !== '00'">{{
                item.floor.suT ? item.floor.suT : "00"
              }}</i>
              <span v-if="item.floor.suT !== '00'">天</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suH ? item.floor.suH : "00"
              }}</i>
              <span>时</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suF ? item.floor.suF :
                "00"
              }}</i>
              <span>分</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suM ?
                item.floor.suM : "00"
              }}</i>
              <span>秒</span>
            </div>

            <a v-if="item.floor.url && item.floor.url !== ''" :href="item.floor.url" target="_blank" class="activeMore">
              查看更多<i class="el-icon el-icon-arrow-right"></i>
            </a>
          </div>

          <div class="mainFloorContent">
            <div class="mainItem">
              <div class="imgleft">
                <el-carousel trigger="click" width="250px" height="400px">
                  <el-carousel-item v-for="(itemC, indexC) of detailAreaData(
                    item.floorDetailList,
                    1
                  )" :key="'floorCarousel' + indexC">
                    <a href="javascript:;" @click="gotoUrl(itemC)">
                      <img style="width: 100%" v-lazy="$imgPath + itemC.picUrl" />
                    </a>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="proRight">
                <a v-for="(itemC, indexC) of detailAreaData(
                  item.floorDetailList,
                  2
                )" @click="gotoUrl(itemC)" :key="'floorBottom' + indexC" href="javascript:;">
                  <img v-lazy="floorDetailImg(itemC)" alt="产品图片" />
                  <p v-if="itemC.title && itemC.title !== ''" :title="itemC.title" class="productData">
                    {{ itemC.title }}
                  </p>
                  <p v-else-if="itemC.product &&
                    itemC.product.name &&
                    itemC.product.name !== ''
                    " :title="itemC.product.name" class="productData">{{ itemC.product.name }}</p>
                  <p class="productPrice">
                    {{ paroductPriceShow(itemC.product) }}
                  </p>
                </a>
              </div>
            </div>
            <div class="mainItem">
              <div class="imgleft">
                <el-carousel trigger="click" width="250px" height="400px">
                  <el-carousel-item v-for="(itemC, indexC) of detailAreaData(
                    item.floorDetailList,
                    3
                  )" :key="'floorCarousel' + indexC">
                    <a href="javascript:;" @click="gotoUrl(itemC)">
                      <img style="width: 100%" v-lazy="$imgPath + itemC.picUrl" />
                    </a>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="proRight">
                <a v-for="(itemC, indexC) of detailAreaData(
                  item.floorDetailList,
                  4
                )" @click="gotoUrl(itemC)" :key="'floorBottom' + indexC" href="javascript:;">
                  <img v-lazy="floorDetailImg(itemC)" alt="产品图片" />
                  <p v-if="itemC.title && itemC.title !== ''" :title="itemC.title" class="productData">
                    {{ itemC.title }}
                  </p>
                  <p v-else-if="itemC.product &&
                    itemC.product.name &&
                    itemC.product.name !== ''
                    " :title="itemC.product.name" class="productData">{{ itemC.product.name }}</p>
                  <p class="productPrice">
                    {{ paroductPriceShow(itemC.product) }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 秒杀楼层 -->
      <div v-if="flashSaleList.length > 0 && flashSaleDetail.nowStatus && false" class="xsqgFloor floorNavClass">
        <div class="djsLeft">
          <p class="tishi">距离本场结束还剩</p>
          <div class="sfm">
            <div class="sfmbox">
              <span>{{ flashSaleDetail.suH ? flashSaleDetail.suH : '00' }}</span>
              <span>{{ flashSaleDetail.suF ? flashSaleDetail.suF : '00' }}</span>
              <span>{{ flashSaleDetail.suM ? flashSaleDetail.suM : '00' }}</span>
            </div>
          </div>
          <router-link :to="{ name: 'secshaZone', query: { id: flashSaleDetail.id } }" target="_blank" class="linkMore">
            查看更多
          </router-link>
        </div>
        <el-carousel :interval="8000" indicator-position="none" trigger="click" width="990px" height="270px">
          <el-carousel-item v-for="(item, index) of sliceArrs(flashSaleList, 5)" :key="'xsqgItem' + index">
            <router-link class="productMain" v-for="(itemAC, indexAC) of item" :key="'xsqgProduct' + itemAC.id + indexAC"
              target="_blank" :to="{ name: 'productDetail', query: { productId: itemAC.productId } }">
              <img v-lazy="productImg(itemAC)" alt="产品图片" />
              <p class="productData">
                {{ itemAC.productName }}{{ itemAC.productFormat }}
              </p>
              <div class="productPrice">
                <p class="msjg">￥{{ itemAC.price }}</p>
                <p class="spyj">￥{{ itemAC.originalPrice }}</p>
              </div>
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- END 秒杀楼层 -->
      <!-- 新-秒杀楼层 -->
      <flash-sale-floor :flashSaleDetail="flashSaleDetail" :flashSaleList="flashSaleList"></flash-sale-floor>
      <!-- END 新-秒杀楼层 -->
      <div v-for="(item, index) of floorListData" :key="'floorIndex' + index" class="floorItem"
        :class="{ floorNavClass: (item.floor.title && item.floor.title !== '') }">
        <!-- 产品楼层 -->
        <div v-if="item.floor.typeId === 2 && item.sort > 0" class="productFloor">
          <div v-if="item.floor.title && item.floor.title !== ''" class="floorTitle" :style="item.floor.titleThemeColor
            ? `color:${item.floor.titleThemeColor}`
            : ''
            ">
            <div class="title">
              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, #ffffff, ${item.floor.titleThemeColor});`
                : ''
                " class="leftbg"></span>
              <span>{{ item.floor.title }}</span>
              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, ${item.floor.titleThemeColor}, #ffffff);`
                : ''
                " class="rightbg"></span>
            </div>

            <div v-if="item.floor.timeWarnFlag &&
              item.floor.timeWarnFlag === 1 &&
              item.floor.djsShow
              " :style="`color: ${item.floor.titleThemeColor}`" class="xsqg-content fl">
              {{ item.floor.djsText }}
              <i :style="`background-color: ${item.floor.titleThemeColor}`" v-if="item.floor.suT !== '00'">{{
                item.floor.suT ? item.floor.suT : "00"
              }}</i>
              <span v-if="item.floor.suT !== '00'">天</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suH ? item.floor.suH : "00"
              }}</i>
              <span>时</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suF ? item.floor.suF :
                "00"
              }}</i>
              <span>分</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suM ?
                item.floor.suM : "00"
              }}</i>
              <span>秒</span>
            </div>
            <div v-if="item.floorDetailList &&
                luoboProductData(item.floorDetailList).length > 1
                " class="tagIcon fr">
              <i @click="prevLunBo(item)" class="prevIcon el-icon-caret-left"></i>
              <i @click="nextLunBo(item)" class="nextIcon el-icon-caret-right"></i>
            </div>
          </div>
          <div class="productListMain">
            <ul :id="'productFloor' + item.floor.id" v-if="item.floorDetailList" :ref="'productFloor' + item.floor.id"
              :style="floorStyle(item)">
              <li class="floorItemLi" v-for="(itemC, indexC) of luoboProductData(item.floorDetailList)"
                :key="'floorItem1' + indexC">
                <router-link target="_blank" v-for="(itemAC, indexAC) of itemC" :key="'floorItemC1' + indexAC"
                  :to="{ name: 'productDetail', query: { productId: itemAC.productId } }">
                  <img v-lazy="floorDetailImg(itemAC)" alt="产品图片" />
                  <p v-if="itemAC.title && itemAC.title !== ''" :title="itemAC.title" class="productData">
                    {{ itemAC.title }}
                  </p>
                  <p v-else-if="itemAC.product &&
                    itemAC.product.name &&
                    itemAC.product.name !== ''
                    " :title="itemAC.product.name" class="productData">
                    {{ itemAC.product.name }} {{ itemAC.product.format }}
                  </p>
                  <p class="productPrice">
                    {{ paroductPriceShow(itemAC.product) }}
                  </p>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- END 产品楼层 -->
        <!-- 图片楼层 -->
        <div v-else-if="item.floor.typeId === 3 && item.sort > 0" class="imgFloor">
          <div v-if="item.floor.title && item.floor.title !== ''" class="floorTitle" :style="item.floor.titleThemeColor
            ? `color:${item.floor.titleThemeColor}`
            : ''
            ">
            <div class="title">

              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, #ffffff, ${item.floor.titleThemeColor});`
                : ''
                " class="leftbg"></span>
              <span>{{ item.floor.title }}</span>
              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, ${item.floor.titleThemeColor}, #ffffff);`
                : ''
                " class="rightbg"></span>
            </div>

            <div v-if="item.floor.timeWarnFlag &&
              item.floor.timeWarnFlag === 1 &&
              item.floor.djsShow
              " :style="`color: ${item.floor.titleThemeColor}`" class="xsqg-content fl">
              {{ item.floor.djsText }}
              <i :style="`background-color: ${item.floor.titleThemeColor}`" v-if="item.floor.suT !== '00'">{{
                item.floor.suT ? item.floor.suT : "00"
              }}</i>
              <span v-if="item.floor.suT !== '00'">天</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suH ? item.floor.suH : "00"
              }}</i>
              <span>时</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suF ? item.floor.suF :
                "00"
              }}</i>
              <span>分</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suM ?
                item.floor.suM : "00"
              }}</i>
              <span>秒</span>
            </div>

            <a v-if="item.floor.url && item.floor.url !== ''" :href="item.floor.url" target="_blank" class="activeMore">
              查看更多<i class="el-icon el-icon-arrow-right"></i>
            </a>
          </div>
          <div class="imgListMain">
            <a href="javascript:;" v-for="(itemC, indexC) of item.floorDetailList" :key="'imageFloorItem' + indexC"
              @click="gotoUrl(itemC)">
              <img v-lazy="floorDetailImg(itemC)" alt="" />
            </a>
          </div>
        </div>
        <!-- END 图片楼层 -->
        <div v-else-if="item.floor.typeId === 4 && item.sort > 0" class="mainFloor">
          <div v-if="item.floor.title && item.floor.title !== ''" class="floorTitle" :style="item.floor.titleThemeColor
            ? `color:${item.floor.titleThemeColor}`
            : ''
            ">
            <div class="title">

              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, #ffffff, ${item.floor.titleThemeColor});`
                : ''
                " class="leftbg"></span>
              <span>{{ item.floor.title }}</span>
              <span :style="item.floor.titleThemeColor
                ? `background-image: linear-gradient(to right, ${item.floor.titleThemeColor}, #ffffff);`
                : ''
                " class="rightbg"></span>
            </div>

            <div v-if="item.floor.timeWarnFlag &&
              item.floor.timeWarnFlag === 1 &&
              item.floor.djsShow
              " :style="`color: ${item.floor.titleThemeColor}`" class="xsqg-content fl">
              {{ item.floor.djsText }}
              <i :style="`background-color: ${item.floor.titleThemeColor}`" v-if="item.floor.suT !== '00'">{{
                item.floor.suT ? item.floor.suT : "00"
              }}</i>
              <span v-if="item.floor.suT !== '00'">天</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suH ? item.floor.suH : "00"
              }}</i>
              <span>时</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suF ? item.floor.suF :
                "00"
              }}</i>
              <span>分</span>
              <i :style="`background-color: ${item.floor.titleThemeColor}`">{{
                item.floor.suM ?
                item.floor.suM : "00"
              }}</i>
              <span>秒</span>
            </div>

            <a v-if="item.floor.url && item.floor.url !== ''" :href="item.floor.url" target="_blank" class="activeMore">
              查看更多<i class="el-icon el-icon-arrow-right"></i>
            </a>
          </div>

          <div class="mainFloorContent">
            <div class="mainItem">
              <div class="imgleft">
                <el-carousel trigger="click" width="250px" height="400px">
                  <el-carousel-item v-for="(itemC, indexC) of detailAreaData(
                    item.floorDetailList,
                    1
                  )" :key="'floorCarousel' + indexC">
                    <a href="javascript:;" @click="gotoUrl(itemC)">
                      <img style="width: 100%" v-lazy="$imgPath + itemC.picUrl" />
                    </a>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="proRight">
                <a v-for="(itemC, indexC) of detailAreaData(
                  item.floorDetailList,
                  2
                )" @click="gotoUrl(itemC)" :key="'floorBottom' + indexC" href="javascript:;">
                  <img v-lazy="floorDetailImg(itemC)" alt="产品图片" />
                  <p v-if="itemC.title && itemC.title !== ''" :title="itemC.title" class="productData">
                    {{ itemC.title }}
                  </p>
                  <p v-else-if="itemC.product &&
                    itemC.product.name &&
                    itemC.product.name !== ''
                    " :title="itemC.product.name" class="productData">{{ itemC.product.name }}</p>
                  <p class="productPrice">
                    {{ paroductPriceShow(itemC.product) }}
                  </p>
                </a>
              </div>
            </div>
            <div class="mainItem">
              <div class="imgleft">
                <el-carousel trigger="click" width="250px" height="400px">
                  <el-carousel-item v-for="(itemC, indexC) of detailAreaData(
                    item.floorDetailList,
                    3
                  )" :key="'floorCarousel' + indexC">
                    <a href="javascript:;" @click="gotoUrl(itemC)">
                      <img style="width: 100%" v-lazy="$imgPath + itemC.picUrl" />
                    </a>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="proRight">
                <a v-for="(itemC, indexC) of detailAreaData(
                  item.floorDetailList,
                  4
                )" @click="gotoUrl(itemC)" :key="'floorBottom' + indexC" href="javascript:;">
                  <img v-lazy="floorDetailImg(itemC)" alt="产品图片" />
                  <p v-if="itemC.title && itemC.title !== ''" :title="itemC.title" class="productData">
                    {{ itemC.title }}
                  </p>
                  <p v-else-if="itemC.product &&
                    itemC.product.name &&
                    itemC.product.name !== ''
                    " :title="itemC.product.name" class="productData">{{ itemC.product.name }}</p>
                  <p class="productPrice">
                    {{ paroductPriceShow(itemC.product) }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--电梯导航-->
    <div id="fixedNavBar" v-show="floorLeftNavShow">
      <div class="fixedLeftNavBar">
        <div @click="changeShowLeftNavStatus = !changeShowLeftNavStatus" class="fixedNavBar_title">
          {{ changeShowLeftNavStatus ? '收起导航' : '展开导航' }}
        </div>
        <ul v-show="changeShowLeftNavStatus">
          <li v-for="(item, index) of floorNavList" :key="'leftNav' + index" class="leftLiNav"
            :class="{ active: leftLiActive === index }">
            <a @click="setFloorNavMountClick(index)">{{ item }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getAction, postAction } from "../../api/manage";
import { getUserInfo } from '../../assets/js/common/common';
import FlashSaleFloor from './FlashSaleFloor.vue';
export default {
  components: { FlashSaleFloor },
  name: "IndexFloor",
  data() {
    return {
      floorListData: [], // 楼层列表的数据
      floorTimeWarnList: {}, // 楼层倒计时数组
      floorTimeNum: {}, // 轮播产品数据
      flashSaleDetail: {}, // 显示抢购数据
      flashSaleList: [],
      limitedTime: null,
      loginState: false,
      leftLiActive: -1,
      changeShowLeftNavStatus: true,
      floorLeftNavShow: false, // 电梯导航的显示和隐藏
      scrollTop: 0, //网页的top值
      userInfo: null,
    };
  },
  created() {
    let userInfo = getUserInfo()
    if (userInfo) {
      this.userInfo = userInfo;
      this.loginState = true
    } else {
      this.loginState = false
    }
    this.getFlashSaleList();
    this.getIndexFloor();
  },
  computed: {
    // 在秒杀楼层上的首页楼层
    floorListBeforeData() {
      let floorListData = this.floorListData;
      let floorListBeforeData = floorListData.filter(item => item.sort == 0)
      return floorListBeforeData
    },
    // 电梯导航栏数组
    floorNavList() {
      let floorListData = this.floorListData;
      let flashSaleDetail = this.flashSaleDetail;
      let flashSaleList = this.flashSaleList;
      let titleArr = []
      // 判断当前是否有秒杀楼层
      if (flashSaleList.length > 0 && flashSaleDetail.nowStatus) {
        titleArr = ['秒杀专区'];
        for (let key of floorListData) {
          if (key.floor.title && key.floor.title !== '') {
            if (key.sort == 0) {
              titleArr.shift(key.floor.title)
            } else if (key.sort > 0) {
              titleArr.push(key.floor.title)
            }
          }
        }
      } else {
        for (let key of floorListData) {
          if (key.floor.title && key.floor.title !== '') {
            titleArr.push(key.floor.title)
          }
        }
      }
      return titleArr
    },
  },
  methods: {
    // 点击楼层事件
    setFloorNavMountClick(index) { // 480是楼层的总体定位值和搜索框定位值和
      this.leftLiActive = index
      var _this = this
      let floor_item = document.getElementsByClassName('floorNavClass');
      let floor_offsetTop = floor_item[index].offsetTop + 350; // 指定跳转的楼层定位
      let window_scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let timer = {
        step: 50,
        times: 8,
        FLOOR_OFFSETTOP: floor_offsetTop
      }
      if (window_scrollTop > floor_offsetTop) {
        _this.setFloorScrollArrowUp(timer)
      } else if (window_scrollTop == floor_offsetTop) {
        return false
      } else {
        _this.setFloorScrollArrowDown(timer)
      }
    },
    /**
     * 设置楼层向上滚动
     * @params Object timer 定时器配置
     */
    setFloorScrollArrowUp(timer) {
      clearInterval(TIMER)
      let TIMER = setInterval(() => {
        let window_scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (window_scrollTop <= timer.FLOOR_OFFSETTOP) {
          document.documentElement.scrollTop = timer.FLOOR_OFFSETTOP
          document.body.scrollTop = timer.FLOOR_OFFSETTOP
          clearInterval(TIMER)
        } else {
          document.documentElement.scrollTop = window_scrollTop - timer.step
          document.body.scrollTop = window_scrollTop - timer.step
        }
      }, timer.times)
    },
    /**
     * 设置楼层向下滚动
     * @params Object timer 定时器配置
     */
    setFloorScrollArrowDown(timer) {
      clearInterval(TIMER)
      let TIMER = setInterval(() => {
        let window_scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (window_scrollTop >= timer.FLOOR_OFFSETTOP) {
          document.documentElement.scrollTop = timer.FLOOR_OFFSETTOP
          document.body.scrollTop = timer.FLOOR_OFFSETTOP
          clearInterval(TIMER)
        } else {
          document.documentElement.scrollTop = window_scrollTop + timer.step
          document.body.scrollTop = window_scrollTop - timer.step
        }
      }, timer.times)
    },
    /**
     * 监听窗口滚动楼层导航动态定位
     */
    floorSrcollAddEventListener() {
      var _this = this;
      let floor_item = document.getElementsByClassName('floorNavClass');
      window.onscroll = function () {
        let scrollTop = window.pageYOffset || document.body.scrollTop
        let window_scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        // 滚动条滚动到指定位置是显示电梯导航
        if (scrollTop > 250) {
          _this.floorLeftNavShow = true
        } else {
          _this.floorLeftNavShow = false
          _this.changeShowLeftNavStatus = true
        }
        for (let i = 0, len = floor_item.length; i < len; i++) {
          let floor_offsetTop = floor_item[i].offsetTop
          if (window_scrollTop <= 350) {
            _this.leftLiActive = 0
          } else if (window_scrollTop >= floor_offsetTop + 350) {
            _this.leftLiActive = i
          }
        }
      }
    },

    // 限时抢购倒计时
    xsqgCountDown(item) {
      let _this = this;
      let nowStatus = _this.limitedStatus(item);
      // 2. 判断不同场次的限时抢购的活动是否开始
      item.nowStatus = nowStatus;
      // 3. 当开始时间小于当前时间，结束时间又大于当前时间，证明活动已经开始，进行结束倒计时显示
      if (nowStatus) {
        let nowTimeArr = item.nowTime.split(" ");
        let ItemStartTime = `${nowTimeArr[0]} ${item.startTime}`;
        let ItemEndTime = `${nowTimeArr[0]} ${item.endTime}`;
        let ItemNowTime = `${item.nowTime}`;
        let endTime = Date.parse(new Date(ItemEndTime)) / 1000;
        let startTime = Date.parse(new Date(ItemStartTime)) / 1000;
        let nowTime = Date.parse(new Date(ItemNowTime)) / 1000;
        // 4. 计算出总的倒计时秒数
        let allTimeS = 0;
        allTimeS = endTime - nowTime;
        // 5. 开始执行定时器倒计时
        _this.limitedTime = setInterval(function (e) {
          allTimeS--;
          _this.countBackTime2(item, allTimeS);
        }, 1000);
      }
    },
    // 判断活动时间是否开始
    limitedStatus(item) {
      let nowTimeArr = item.nowTime.split(" ");
      let ItemStartTime = `${nowTimeArr[0]} ${item.startTime}`;
      let ItemEndTime = `${nowTimeArr[0]} ${item.endTime}`;
      let ItemNowTime = `${item.nowTime}`;
      let endTime = Date.parse(new Date(ItemEndTime)) / 1000;
      let startTime = Date.parse(new Date(ItemStartTime)) / 1000;
      let nowTime = Date.parse(new Date(ItemNowTime)) / 1000;
      if (endTime > nowTime && nowTime > startTime) {
        return true;
      } else {
        return false;
      }
    },
    // 倒计时返回值
    countBackTime2(item, allTimeS) {
      let _this = this;
      let suM = allTimeS % 60; //求出所于的秒数
      suM = suM < 10 ? "0" + suM : suM;
      let suF = ((allTimeS - suM) / 60) % 60; //求出所于分钟数
      suF = suF < 10 ? "0" + suF : suF;
      let fenS = suF * 60;
      let suH = ((allTimeS - fenS - suM) / (60 * 60)) % 24; //求出所于小时数
      suH = suH < 10 ? "0" + suH : suH;
      let shiS = suH * 60 * 60;
      let suT = (allTimeS - shiS - fenS - suM) / (24 * 60 * 60); //求出所于小时数
      suT = suT < 10 ? "0" + suT : suT;
      if (suM <= 0 && suF <= 0 && suH <= 0 && suT <= 0) {
        // 移除倒计时
        clearInterval(_this.limitedTime);
      } else {
        _this.$set(item, "suT", suT);
        _this.$set(item, "suH", suH);
        _this.$set(item, "suF", suF);
        _this.$set(item, "suM", suM);
      }
    },
    // 显示产品图片
    productImg(item) {
      if (item.productPicUrl && item.productPicUrl !== "") {
        let imgsArr = item.productPicUrl.split(",");
        let imgSrc = imgsArr[0];
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    // 获取限时抢购专区数据
    getFlashSaleList() {
      let _this = this;
      let url =
        "/productActivityPriceStrategy/productActivityPriceStrategy/queryDays";
      getAction(url).then((res) => {
        if (res.code == 200 && res.success && res.result && res.result.length > 0) {
          let resData = res.result[0];
          _this.flashSaleDetail = resData
          if (resData.productActivityPriceStrategyDetailList && resData.productActivityPriceStrategyDetailList.length > 0) {
            _this.flashSaleList = resData.productActivityPriceStrategyDetailList;
            _this.xsqgCountDown(resData)
          } else {
            _this.flashSaleList = []
          }
        }
      });
    },
    // 获取楼层的数据
    getIndexFloor() {
      let _this = this;
      let url = "/homeShow/floor/getFloorList";
      // type为1是获取pc楼层的数据
      let data = {
        type: 1,
        area: 0,
      };
      postAction(url, data).then((res) => {
        if (res.code === 200) {
          let resData = res.result;
          _this.floorListData = resData;
          // 判断楼层产品定时器
          for (let key of resData) {
            // 开启定时器的楼层操作
            let floorTimeName = "floorTimeName" + key.floor.id;
            if (key.floor.timeWarnFlag) {
              let itemStartTime = key.floor.activityStartTime;
              let itemEndTime = key.floor.activityEndTime;
              let startTime = Date.parse(new Date(itemStartTime)) / 1000;
              let endTime = Date.parse(new Date(itemEndTime)) / 1000;
              let nowTime = Date.parse(new Date()) / 1000;
              // 给每个楼层定义一个倒计时定时器
              // 计算出当前到截止时间的秒数
              let allTimeS = 0;
              if (startTime > nowTime) {
                key.floor.djsText = "距开始：";
                allTimeS = startTime - nowTime;
                key.floor.djsShow = true;
              } else if (nowTime > startTime && nowTime < endTime) {
                key.floor.djsText = "距结束：";
                allTimeS = endTime - nowTime;
                key.floor.djsShow = true;
              } else {
                key.floor.djsShow = false;
              }
              _this.floorTimeWarnList[floorTimeName] = setInterval(function () {
                allTimeS--;
                _this.countBackTime(key.floor, allTimeS);
              }, 1000);
            }
            // 轮播产品楼层
            if (
              key.floor.typeId === 1 &&
              key.floorDetailList &&
              key.floorDetailList.length > 5
            ) {
              // 启动轮播产品定时器
              _this.floorTimeNum[floorTimeName] = setInterval(function (e) {
                _this.nextLunBo(key);
              }, 8000);
            }
          }
        }
      });
    },
    // 倒计时返回值
    countBackTime(item, allTimeS) {
      let _this = this;
      let suM = allTimeS % 60; // 求出所于的秒数
      suM = suM < 10 ? "0" + suM : suM;
      let suF = ((allTimeS - suM) / 60) % 60; // 求出所于分钟数
      suF = suF < 10 ? "0" + suF : suF;
      let fenS = suF * 60;
      let suH = ((allTimeS - fenS - suM) / (60 * 60)) % 24; // 求出所于小时数
      suH = suH < 10 ? "0" + suH : suH;
      let shiS = suH * 60 * 60;
      let suT = (allTimeS - shiS - fenS - suM) / (24 * 60 * 60); // 求出所于小时数
      suT = suT < 10 ? "0" + suT : suT;
      if (allTimeS <= 0) {
        // 移除倒计时
        let floorTimeName = "floorTimeName" + item.id;
        item.djsShow = false;
        clearInterval(_this.floorTimeWarnList[floorTimeName]);
      } else {
        item.suT = suT;
        item.suH = suH;
        item.suF = suF;
        item.suM = suM;
      }
      this.$forceUpdate();
    },
    // 楼层详情的图片数据
    floorDetailImg(item) {
      let imgPath = this.$imgPath;
      // 如果用户上传了产品的图片 则用客户上传的图片
      if (item.picUrl && item.picUrl !== "" && item.picUrl !== null) {
        return `${imgPath}${item.picUrl}`;
      } else {
        if (
          item.product &&
          item.product.picUrl &&
          item.product.picUrl !== ""
        ) {
          let picUrl = item.product.picUrl.split(",");
          return this.$util.resizeImg(`${imgPath}${picUrl[0]}`, 175);
        } else {
          return false;
        }
      }
    },
    // 楼层产品的价格展示
    paroductPriceShow(itemC) {
      let loginState = this.loginState;
      if (loginState) {
        if (itemC && itemC.offlineSaleFlag && itemC.offlineSaleFlag == '1') {
          return '请联系开票员'
        } else {
          if (itemC && itemC.inventoryList) {
            let itemInventoryList = itemC.inventoryList;
            let productActivityPriceStrategyFlag =
              itemC.productActivityPriceStrategyFlag;
            let productActivityPriceStrategyDetail =
              itemC.productActivityPriceStrategyDetail;
            if (itemC && itemInventoryList && itemInventoryList.length > 0) {
              if (
                productActivityPriceStrategyFlag &&
                productActivityPriceStrategyDetail
              ) {
                return `￥${productActivityPriceStrategyDetail.price}`;
              } else {
                return `￥${itemInventoryList[0].price}`;
              }
            } else {
              return '￥--/--';
            }
          } else {
            return '￥--/--';
          }
        }
      } else {
        return '会员可见'
      }
    },
    // 轮播产品楼层样式
    floorStyle(item) {
      let data = item.floorDetailList;
      if (data.length > 5) {
        let oldArrs = this.sliceArrs(data, 5);
        let oldL = oldArrs.length;
        let Fwidth = (oldL + 2) * 1190;
        let initialL = -1190;
        let style = {
          width: Fwidth + "px",
          left: initialL + "px",
        };
        return style;
      }
    },
    // 均分数组
    sliceArrs(array, size) {
      var result = [];
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
        var start = x * size;
        var end = start + size;
        result.push(array.slice(start, end));
      }
      return result;
    },
    // 轮播产品楼层数据拆分
    luoboProductData(productDataList) {
      let data = productDataList;
      if (productDataList.length >= 6) {
        let oldArrs = this.sliceArrs(data, 6);
        let oldL = oldArrs.length;
        let firstArr = oldArrs[0];
        let lastArr = oldArrs[oldL - 1];
        // 克隆数组的第一个和最后一个  插入到 最后和最前
        oldArrs.unshift(lastArr);
        oldArrs.push(firstArr);
        return oldArrs;
      } else {
        let oldArrs = [];
        oldArrs.push(productDataList);
        return oldArrs;
      }
    },
    prevLunBo(item) {
      let hasClick = item.floor.hasClick;
      let itemId = item.floor.id;
      let thisUl = this.$refs["productFloor" + itemId][0];
      console.log("hasClick+++++++++", hasClick);
      if (hasClick || typeof hasClick === "undefined") {
        item.floor.hasClick = false;
        // 计算当前的下标为
        // eslint-disable-next-line no-undef
        let nowLeft = parseInt(thisUl.style.left);
        let index = -nowLeft / 1190;
        index--;
        this.moveUl(index, item);
      }
    },
    nextLunBo(item) {
      let hasClick = item.floor.hasClick;
      let itemId = item.floor.id;
      if (hasClick || typeof hasClick === "undefined") {
        item.floor.hasClick = false;
        let thisUl = this.$refs["productFloor" + itemId][0];
        // 计算当前的下标为
        let nowLeft = parseInt(thisUl.style.left);
        let index = -nowLeft / 1190;
        index++;
        this.moveUl(index, item);
      }
    },
    // 产品轮播楼层的滚动方法
    moveUl(ind, item) {
      let _this = this;
      let itemId = item.floor.id;
      let index = 0;
      let thisUl = this.$refs["productFloor" + itemId][0];
      let liLength = thisUl.children.length;
      let oldliLength = liLength - 2;
      thisUl.style.left = -(ind * 1190) + "px";
      thisUl.style.transition = "1s";
      // thisUl.animate({
      //   left: -(ind * 1190) + 'px'
      // }, 1000)
      setTimeout(function () {
        if (ind === 0) {
          index = oldliLength;
          thisUl.style.left = `${-(index * 1190)}px`;
          thisUl.style.transition = "0s";
        } else if (ind === oldliLength + 1) {
          index = 1;
          thisUl.style.left = `${-(index * 1190)}px`;
          thisUl.style.transition = "0s";
        }
        item.floor.hasClick = true;
        _this.$forceUpdate();
      }, 1000);
    },
    detailAreaData(data, type) {
      if (data) {
        let detailArea = parseInt(type);
        let returnData = [];
        // 区域一的数据
        for (let key of data) {
          if (key.detailArea === detailArea) {
            returnData.push(key);
          }
        }
        return returnData;
      }
    },
    // 跳转链接的数据为
    gotoUrl(item) {
      let userInfo = this.userInfo;
      console.log("当前点击操作为++++++", item)
      let gotoUrlType = item.gotoUrlType || '1'
      let gotoUrl = item.gotoUrl;
      if (gotoUrl && gotoUrl !== '') {
        if (gotoUrlType == '3') {
          window.open(gotoUrl + `?Shopping-Access-Token=${userInfo.token}`);
        } else {
          window.open(gotoUrl);
        }
      } else {
        return false
      }
    },
  },
  // 组件销毁清除定时器
  unmounted() {
    let floorTimeWarnList = this.floorTimeWarnList;
    let floorTimeNum = this.floorTimeNum;
    for (let key in floorTimeWarnList) {
      clearInterval(floorTimeWarnList[key]);
    }
    for (let key in floorTimeNum) {
      clearInterval(floorTimeNum[key]);
    }
    clearInterval(this.limitedTime)
    // 清除监听滚动条事件
    window.removeEventListener("scroll", this.floorSrcollAddEventListener);
  },
  mounted() {
    this.$nextTick(function () {
      // 修改事件监听
      window.addEventListener("scroll", this.floorSrcollAddEventListener);
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/index/indexFloor";
</style>