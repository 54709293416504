<template>
  <div class="loginContent container">
    <div class="loginHeader container">
      <div class="container_box">
        <div class="logo">
          <router-link to="/index">
            <img src="../assets/images/common/logo.png" alt="" />
          </router-link>
        </div>
        <div
          v-if="companyConfigData && companyConfigData.customerServiceTel && companyConfigData.customerServiceTel !== ''"
          class="telBox">
          <img src="../assets/images/login/tel.png" alt="" />
          <span>客服电话：{{ companyConfigData.customerServiceTel }}</span>
        </div>
      </div>
    </div>
    <div class="loginMain container"
      :style="(loginBgImgUrl && loginBgImgUrl !== '') ? `background-image: url(${loginBgImgUrl})` : ''">
      <div class="container_box">
        <transition-group name="el-zoom-in-center">
          <!-- 选择登录身份 -->
          <div :key="1" v-show="selectUserInfo == 2" class="selectLoginList">
            <div class="selectTitle">
              <i class="el-icon-arrow-left" @click="selectUserInfo = 3"></i>
              {{ salemanLoginStatus ? "请选择客户身份" : "请选择登录身份" }}
              {{ salemanName !== "" ? "-" + salemanName : "" }}
            </div>
            <el-scrollbar class="userList">
              <p v-for="(item, index) of selectCustomerList" @click="selectedUserInfo(item)" :title="item.name"
                :key="'userinfo' + index">
                <span class="error" v-if="item.errorInfo && item.errorInfo !== ''">[账户异常]</span>
                {{ `${item.companyName}--${item.name}` }}
              </p>
              <p v-if="salemanLoginStatus && pages > pageNo" class="add-more-btn" @click="addMoreCutomerList">
                查看更多
              </p>
            </el-scrollbar>
            <div v-if="salemanLoginStatus" class="searchUserInput">
              <el-input placeholder="请输入客户名称" prefix-icon="el-icon-search" v-model="searchUserName"
                @change="changeSearchInput">
              </el-input>
            </div>
          </div>
          <!-- 用户登录操作 -->
          <div :key="2" v-show="selectUserInfo == 1" class="loginBox">
            <!-- 切换登录方式按钮 -->
            <div class="changeLoginBtn">
              <img @click="changeLoginType(1)" v-if="loginType == 3" src="../assets/images/login/pcLoginIcon.png"
                alt="账号登录" />
              <img v-else @click="changeLoginType(3)" src="../assets/images/login/ecode.png" alt="扫码登录" />
            </div>
            <!-- 切换登录方式按钮提示 -->
            <div class="changeLoginTip">
              <img @click="loginType = 1" v-if="loginType == 3" src="../assets/images/login/userTips.png" alt="账号登录" />
              <img v-else @click="loginType = 3" src="../assets/images/login/userTips2.png" alt="扫码登录" />
            </div>
            <!-- 账号密码或者手机号登录 -->
            <div v-if="loginType !== 3" class="infoLogin">
              <div class="selectTypeText">
                <span @click="changeLoginType(1)" :class="{ selected: loginType == 1 }">
                  账号登录
                </span>
                <span v-if="false" @click="changeLoginType(2)" :class="{ selected: loginType == 2 }">
                  手机号登录
                </span>
                <div title="切换业务员身份登录" @click="changeYwyLogin" class="ywyLoginBtn">
                  <img src="../assets/images/login/changeLoginUser.png" alt="" />
                </div>
              </div>
              <div v-if="errorMsg !== ''" class="errorTipBox">
                <span>
                  <i class="el-icon-warning"></i>
                  {{ errorMsg }}
                </span>
              </div>
              <div v-if="loginType == 1" class="accountNumberBox">
                <div class="accountNumberInput">
                  <img class="accountIcon" src="../assets/images/login/userIcon.png" alt="" />
                  <input @keyup="removeOverStr(username)" @blur="verifyAccout" @keyup.enter="changeInputFocus"
                    v-model="username" ref="username" class="accountNumber" type="text" />
                </div>
                <div class="passwordInput">
                  <img class="passwordIcon" src="../assets/images/login/passwordIcon.png" alt="" />
                  <input class="password" ref="password" v-model="password" @blur="verifyPWd"
                    @keyup.enter="changeInputFocus" :type="changeShowPwd ? 'text' : 'password'" />
                  <div class="changeShowPwd" v-if="!hasIeBrowser">
                    <img @click="changeShowPwd = true" title="显示密码" v-if="!changeShowPwd"
                      src="../assets/images/login/closePwd.png" alt="显示密码" />
                    <img @click="changeShowPwd = false" title="隐藏密码" v-else src="../assets/images/login/openPwd.png"
                      alt="隐藏密码" />
                  </div>
                </div>

                <div v-if="verifyShowType" class="verifyCodeBox">
                  <div class="verifyCode">
                    <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
                    <input ref="verifyCode" @keyup.enter="changeInputFocus" v-model="verifyCode" type="text" />
                  </div>
                  <div @click="taggleVerfyImg" title="看不清？换一张" class="getVerfiyCodeBtn">
                    <img v-if="verifyImg" :src="verifyImg" alt="" />
                    <img v-else src="../assets/images/login/verfiyCodeImg.png" alt="" />
                  </div>
                </div>
                <div class="rememberAccount">
                  <label>
                    <input v-model="rememberState" type="checkbox" id="remeberCheck" />
                    记住账号
                  </label>
                </div>
              </div>
              <div v-else-if="loginType == 2" class="mobilePhone">
                <div class="phoneNumberInput">
                  <img class="phoneIcon" src="../assets/images/login/mobliePhone.png" alt="" />
                  <input @blur="verifyPhone" v-model="phoneNumber" class="phoneNumber"
                    oninput="value=value.replace(/[^0-9.]/g,'')" type="text" />
                </div>
                <div class="verifyCodeBox">
                  <div class="verifyCode">
                    <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
                    <input type="text" />
                  </div>
                  <button :disabled="verfiyState ? true : false" @click="phoneVerify" class="getVerfiyCodeBtn">
                    {{ verfiyState ? `${timeNumber}S再获取` : "发送验证码" }}
                  </button>
                </div>
              </div>
              <div @click="loginSubmit" :class="{ loginLoading: loginLoading === true }" class="loginBtn">
                <i v-if="loginLoading" class="el-icon-loading"></i>
                登录
              </div>
              <div class="loginFooterAct">
                <router-link to="/register">
                  立即注册
                  <i style="margin-left: -3px" class="el-icon-d-arrow-right"></i>
                </router-link>
                <router-link to="/forgetPwd"> 忘记密码? </router-link>
              </div>
            </div>
            <!-- 扫码登录 -->
            <div v-else class="scanCode">
              <div class="ecodeMain">
                <div class="ecodeBox">
                  <img class="ecodeImg" :src="encodeImg" alt="二维码图片" />
                  <div v-if="encodeStatus !== 0 && encodeStatus !== 2" @click="reloadEncode" class="ecodeStatusBox">
                    <span :class="'status' + encodeStatus" class="imgStatus"></span>
                    <p>
                      {{
                        encodeStatus === 1
                        ? "扫码成功"
                        : "二维码已失效，请点击刷新"
                      }}
                    </p>
                  </div>
                </div>
                <p style="margin-top: 10px">打开商城APP</p>
                <p class="saoma">
                  <img src="../assets/images/login/saomaIcon.png" alt="扫码图标" />
                  扫一扫登录
                </p>
              </div>
            </div>
          </div>
          <!-- 业务员登录操作 -->
          <div :key="3" v-show="selectUserInfo == 3" class="loginBox">
            <div class="infoLogin">
              <div class="selectTypeText">
                <span class="selected ywy">业务员身份登录</span>
                <div title="切换用户身份登录" @click="changeYwyLogin" class="ywyLoginBtn">
                  <img src="../assets/images/login/changeLoginUser.png" alt="" />
                </div>
              </div>
              <div v-if="errorMsg !== ''" class="errorTipBox">
                <span>
                  <i class="el-icon-warning"></i>
                  {{ errorMsg }}
                </span>
              </div>
              <div class="accountNumberBox">
                <div class="accountNumberInput">
                  <img class="accountIcon" src="../assets/images/login/userIcon.png" alt="" />
                  <input @keyup="removeOverStr(username)" @blur="verifyAccout" @keyup.enter="changeInputFocus2"
                    v-model="username" ref="username2" class="accountNumber" type="text" />
                </div>
                <div class="passwordInput">
                  <img class="passwordIcon" src="../assets/images/login/passwordIcon.png" alt="" />
                  <input class="password" ref="password2" v-model="password" @blur="verifyPWd"
                    @keyup.enter="changeInputFocus2" :type="changeShowPwd ? 'text' : 'password'" />
                  <div class="changeShowPwd" v-if="!hasIeBrowser">
                    <img @click="changeShowPwd = true" title="显示密码" v-if="!changeShowPwd"
                      src="../assets/images/login/closePwd.png" alt="显示密码" />
                    <img @click="changeShowPwd = false" title="隐藏密码" v-else src="../assets/images/login/openPwd.png"
                      alt="隐藏密码" />
                  </div>
                </div>

                <div v-if="verifyShowType" class="verifyCodeBox">
                  <div class="verifyCode">
                    <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
                    <input ref="verifyCode2" @keyup.enter="changeInputFocus2" v-model="verifyCode" type="text" />
                  </div>
                  <div @click="taggleVerfyImg" title="看不清？换一张" class="getVerfiyCodeBtn">
                    <img v-if="verifyImg" :src="verifyImg" alt="" />
                    <img v-else src="../assets/images/login/verfiyCodeImg.png" alt="" />
                  </div>
                </div>
                <div class="rememberAccount">
                  <label>
                    <input v-model="rememberState" type="checkbox" id="remeberCheck" />
                    记住账号
                  </label>
                </div>
              </div>
              <div @click="loginSubmit2" :class="{ loginLoading: loginLoading === true }" class="loginBtn">
                <i v-if="loginLoading" class="el-icon-loading"></i>
                登录
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/common/Footer.vue";
import { mapActions, mapGetters } from "vuex";
import { IEVersion } from "../assets/js/common/common";
import { getAction } from "../api/manage";
import Vue from "vue";
import {
  SHOPPING_ACCESS_TOKEN,
  USER_INFO,
  SALEMAN_INFO,
} from "../store/mutation-types";
export default {
  components: { Footer },
  name: "Login",
  data() {
    return {
      username: "", //登录账号
      password: "", //用户密码
      verifyShowType: false, // 验证码显示状态
      verifyCode: "", // 用户输入验证码
      verifyKey: "", // 验证key
      verifyImg: "", // 验证码图片
      rememberState: false, // 记住账号checkbox的状态
      loginLoading: false, // 登录加载动画
      loginType: 1, // 登录方式 1.账号密码登录 2.手机验证码登录 3.app扫码登录
      selectUserInfo: 1, // 用户选择身份列表的状态
      userInfoList: [], // 用户身份列表
      hasIeBrowser: false, // 是否是ie浏览器内核，如果是ie内核关闭显示隐藏密码图标
      changeShowPwd: false, // 密码框的显示隐藏状态
      errorMsg: "", // 登录错误提示
      timeNumber: 60,
      phoneNumber: "", // 手机号码登录
      phoneCode: "", // 手机登录验证码
      verfiyState: false, // 手机发送验证码状态
      encodeImgTimer: null, // 二维码登录状态定时器
      encodeImg: "", // 二维码图片
      encodeStatus: 0, // 二维码状态
      encodeToken: "", // 二维码包含的token
      salemanLoginStatus: false, // 业务员登录状态
      searchUserName: "",
      selectCustomerList: [], // 选择客户身份数据
      salemanName: "",
      loginBgImgUrl: "",
      pageNo: 1,
      pages: 1,
      pageSize: 10,
      sourceType: '',
      sourceId: '',
    };
  },
  created() {
    this.sourceType = this.$route.query.sourceType || '';
    this.sourceId = this.$route.query.sourceId || '';
    // 判断客户是否记住密码
    let lsUsername = this.$ls.get("username");
    if (lsUsername && lsUsername !== "") {
      this.rememberState = true;
      this.username = lsUsername;
    }
    // 判断其他接口跳转登录页是否需要展示提示信息
    // let errorData = this.errorData;
    // if (errorData && errorData !== "") {
    //   this.$alert(errorData, "提示信息：", {
    //     confirmButtonText: "确定",
    //     buttonSize: "medium",
    //     callback: (action) => {},
    //   });
    // }
    // 判断是否为ie浏览器
    this.hasIeBrowser = IEVersion();
    // 获取背景图数据
    this.getCompanyConfigData()
  },
  computed: {
    ...mapGetters(["errorData", "companyConfigData", "salemanToken"]),
  },
  methods: {
    ...mapActions(["Login", "YwyLogin"]),
    changeSearchInput() {
      this.pageNo = 1;
      this.pages = 1
      this.selectCustomerList = [];
      this.getSalemanMapCustomerList()
    },
    // 搜索客户列表数据
    changeSearchInput2() {
      let _this = this;
      let searchUserName = this.searchUserName;
      let customerList = [];
      let userInfoList = this.userInfoList;
      let arr = searchUserName.split("");
      //有一个关键字都没匹配到，则没有匹配到数据
      for (let key of userInfoList) {
        let customerName = key.name;
        if (searchUserName == "") {
          customerList.push(key);
        } else if (_this.fuzzyMatch(customerName, searchUserName)) {
          customerList.push(key);
        }
      }
      this.selectCustomerList = customerList;
    },
    // 模糊匹配字符串
    fuzzyMatch(str, key) {
      let index = -1,
        flag = false;
      for (var i = 0, arr = key.split(""); i < arr.length; i++) {
        //有一个关键字都没匹配到，则没有匹配到数据
        if (str.indexOf(arr[i]) < 0) {
          break;
        } else {
          let match = str.matchAll(arr[i]);
          let next = match.next();
          while (!next.done) {
            if (next.value.index > index) {
              index = next.value.index;
              if (i === arr.length - 1) {
                flag = true;
              }
              break;
            }
            next = match.next();
          }
        }
      }
      return flag;
    },
    // 切换业务员或者客户登录操作
    changeYwyLogin() {
      let selectUserInfo = this.selectUserInfo;
      this.loginLoading = false;
      // 切换登录方式后，初始化登录输入框
      let lsUsername = "";
      this.initLoginInput();
      if (selectUserInfo == 1) {
        this.selectUserInfo = 3;
        lsUsername = this.$ls.get("salemanName");
        if (lsUsername && lsUsername !== "") {
          this.rememberState = true;
          this.username = lsUsername;
        } else {
          this.rememberState = false;
        }
      } else if (selectUserInfo == 3) {
        this.selectUserInfo = 1;
        lsUsername = this.$ls.get("username");
        if (lsUsername && lsUsername !== "") {
          this.rememberState = true;
          this.username = lsUsername;
        } else {
          this.rememberState = false;
        }
      }
    },
    // 初始化登录输入框
    initLoginInput() {
      this.username = "";
      this.password = "";
      this.errorMsg = "";
      this.verifyShowType = false; // 验证码显示状态
      this.verifyCode = ""; // 用户输入验证码
      this.verifyKey = ""; // 验证key
      this.verifyImg = ""; // 验证码图片
      this.phoneNumber = ""; // 手机号码登录
      this.phoneCode = ""; // 手机登录验证码
      this.verfiyState = false; // 手机发送验证码状态
    },
    // 用户按下账号密码自动删除空格和换行
    removeOverStr(username) {
      username = username.replace(/\s+/g, "");
      username = username.replace(/<\/?.+?>/g, "");
      username = username.replace(/[\r\n]/g, "");
      this.username = username;
    },
    // 修改登录方式
    changeLoginType(num) {
      this.loginType = num;
      if (num === 3) {
        // 选择app扫码登录
        console.log("切换成扫码登录");
        this.getEcodeData();
      } else {
        let encodeImgTimer = this.encodeImgTimer;
        // 清空app登录码轮询操作，和二维码图片
        clearInterval(encodeImgTimer);
      }
    },
    // 获取二维码数据
    getEcodeData() {
      let _this = this;
      let url = "/customer/getAuthLoginCode";
      getAction(url).then((res) => {
        console.log("获取二维码的数据为++++++", res);
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.encodeImg = resData.img;
          _this.encodeToken = resData.key;
          _this.encodeStatus = 0;
          // 开始轮询二维码授权状态
          _this.getCodeStatusAuth();
        }
      });
    },
    // 轮询二维码授权状态
    getCodeStatusAuth() {
      let _this = this;
      let encodeStatus = this.encodeStatus;
      let encodeToken = this.encodeToken;
      let url = "/customer/getAuthLoginStatus";
      let data = {
        aToken: encodeToken,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success && res.result) {
          let resData = res.result;
          _this.encodeStatus = resData.status;
          if (resData.status === 2) {
            clearTimeout(_this.encodeImgTimer);
            let userInfo = resData.customer;
            let customerToken = resData.token;
            let loginToken = _this.$base64.encode(customerToken);
            _this.$ls.set(
              SHOPPING_ACCESS_TOKEN,
              loginToken,
              24 * 60 * 60 * 1000
            );
            let encodeUserInfo = this.$base64.encode(JSON.stringify(userInfo));
            _this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
            _this.$store.commit("SET_TOKEN", loginToken);
            _this.$store.commit("SET_INFO", userInfo);
            _this.$router.push("/index");
          }
        } else if (resData.status === 4) {
          clearTimeout(_this.encodeImgTimer);
        }
      });
      this.encodeImgTimer = setTimeout(function () {
        _this.getCodeStatusAuth();
      }, 2000);
    },
    // 重新加载二维码
    reloadEncode() {
      let encodeStatus = this.encodeStatus;
      if (encodeStatus == 2) {
        this.getEcodeData();
      }
    },
    // 验证用户输入的账号
    verifyAccout() {
      let username = this.username;
      if (!username || username == "") {
        this.errorMsg = "账号不能为空！";
      } else {
        let re = /[a-zA-Z0-9\u4E00-\u9FA5]/;
        if (re.test(username)) {
          this.errorMsg = "";
        } else {
          this.errorMsg = "账号只能包含数字或字母！";
        }
      }
    },
    // 验证客户输入的密码规则
    verifyPWd() {
      let password = this.password;
      if (!password || password == "") {
        this.errorMsg = "密码不能为空！";
      } else {
        let re = /^\w+$/;
        if (re.test(password)) {
          this.errorMsg = "";
        } else {
          this.errorMsg = "密码只能包含字母、数字和下划线！";
        }
      }
    },
    // 回车判断调试件
    changeInputFocus() {
      let username = this.username;
      let password = this.password;
      let verifyShowType = this.verifyShowType;
      let verifyCode = this.verifyCode;
      if (username !== "" && password === "") {
        this.$refs.password.focus();
      } else if (username === "" && password !== "") {
        this.$refs.username.focus();
      } else if (verifyShowType && verifyCode == '') {
        this.$refs.verifyCode.focus();
      } else if ((!verifyShowType && username !== "" && password !== "") || (verifyShowType && username !== "" && password !== "" && verifyCode !== "")) {
        let selectUserInfo = this.selectUserInfo;
        if (selectUserInfo === 1) {
          this.loginSubmit();
        } else if (selectUserInfo === 3) {
          this.loginSubmit2();
        }
      }
    },
    // 回车判断调试件
    changeInputFocus2() {
      let username = this.username;
      let password = this.password;
      let verifyShowType = this.verifyShowType;
      let verifyCode = this.verifyCode;
      if (username !== "" && password === "") {
        this.$refs.password2.focus();
      } else if (username === "" && password !== "") {
        this.$refs.username2.focus();
      } else if (verifyShowType && verifyCode == '') {
        this.$refs.verifyCode2.focus();
      } else if ((!verifyShowType && username !== "" && password !== "") || (verifyShowType && username !== "" && password !== "" && verifyCode !== "")) {
        let selectUserInfo = this.selectUserInfo;
        if (selectUserInfo === 1) {
          this.loginSubmit();
        } else if (selectUserInfo === 3) {
          this.loginSubmit2();
        }
      }
    },
    // 验证客户登录数据录入操作
    verifyLoginData() {
      let username = this.username;
      let password = this.password;
      let verifyCode = this.verifyCode;
      let verifyKey = this.verifyKey;
      if (username == "") {
        this.errorMsg = "账号不能为空！";
        this.$refs.username.focus();
        return false;
      } else if (!/[a-zA-Z0-9]/.test(username)) {
        this.errorMsg = "账号只能包含数字或字母！";
        this.$refs.username.focus();
        return false;
      } else if (password == "") {
        this.errorMsg = "密码不能为空！";
        this.$refs.password.focus();
        return false;
      } else if (!/^\w+$/.test(password)) {
        this.errorMsg = "密码只能包含字母、数字和下划线！";
        this.$refs.password.focus();
        return false;
      } else if (verifyKey !== "" && verifyCode === "") {
        this.verifyShowType = true;
        this.errorMsg = "请填写验证码";
        this.$refs.verifyCode.focus();
        return false;
      } else {
        this.errorMsg = "";
        return true;
      }
    },
    // 点击登录事件
    loginSubmit() {
      let _this = this;
      let loginType = this.loginType;
      if (!this.loginLoading) {
        if (loginType === 1 && this.verifyLoginData()) {
          // 对账号密码登录进行验证
          this.loginLoading = true;
          // 获取用户输入的信息数据
          let username = this.username;
          let passsword = this.password;
          let verifyCode = this.verifyCode;
          let verifyKey = this.verifyKey;
          let sourceType = this.sourceType;
          let sourceId = this.sourceId;
          let jmpassword = this.$md5(passsword).substring(8, 24);
          let loginData = {
            username: username,
            password: jmpassword,
          };
          if (verifyCode) {
            loginData.verifyCode = verifyCode;
            loginData.verifyKey = verifyKey;
          }
          if (sourceType && sourceType !== '') {
            loginData.sourceType = sourceType;
          }
          if (sourceId && sourceId !== '') {
            loginData.sourceId = sourceId;
          }

          console.log("点击登录操作");
          this.Login(loginData)
            .then((res) => {
              // 登录前先清除掉之前的存储的客商和业务员的token/userInfo
              _this.cleanLoginData()
              _this.loginLoading = false;
              _this.salemanLoginStatus = false;
              if (res.code === 200) {
                _this.$ls.remove("companyConfigData");
                _this.verifyShowType = false;
                // 1.判断用户是否点击了记住密码功能
                let rememberState = _this.rememberState;
                if (rememberState) {
                  _this.$ls.set("username", username);
                } else {
                  _this.$ls.remove("username");
                }
                // 2.判断用户登录身份
                let resData = res.result;
                let customerList = resData.customerList;
                if (customerList.length === 1) {
                  let userInfo = customerList[0];
                  let customerToken = customerList[0].token;
                  let loginToken = _this.$base64.encode(customerToken);
                  _this.$ls.set(
                    SHOPPING_ACCESS_TOKEN,
                    loginToken,
                    24 * 60 * 60 * 1000
                  );
                  let encodeUserInfo = this.$base64.encode(
                    JSON.stringify(userInfo)
                  );
                  _this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
                  _this.$store.commit("SET_TOKEN", loginToken);
                  _this.$store.commit("SET_INFO", userInfo);

                  let b2bAuditStatus = userInfo.b2bAuditStatus;
                  if (b2bAuditStatus && (b2bAuditStatus == '0' || b2bAuditStatus == '2')) {
                    _this.$router.push({
                      name: "register",
                      query: { status: 2 },
                    });
                  } else {
                    // _this.$alert('系统维护中，请联系开票员下单！', "提示信息", {
                    //   type: "warning",
                    // });
                    // 判断是否有指定跳转的地址
                    if (userInfo.pcGotoUrl && userInfo.pcGotoUrl !== '') {
                      location.href = userInfo.pcGotoUrl
                    } else {
                      _this.$router.push("/index");
                    }
                  }
                } else if (customerList.length > 1) {
                  _this.initCustomerList(customerList);
                  _this.selectUserInfo = 2;
                }
              } else {
                _this.errorMsg = res.message;
                if (res.code === 402) {
                  let resData = res.result;
                  let verifyCode = resData.verifyCode;
                  if (verifyCode) {
                    _this.verifyShowType = true;
                    _this.verifyKey = verifyCode.key;
                    _this.verifyImg = `data:image/jpg;base64,${verifyCode.img}`;
                  }
                }
              }
            })
            .catch((err) => {
              _this.loginLoading = false;
            });
        } else if (loginType === 2) {
          // 手机号登录
          let phoneCode = this.phoneCode;
          let phoneNumber = this.phoneNumber;
          if (phoneNumber == "") {
            this.errorMsg = "请输入手机号码";
          } else if (phoneCode == "") {
            this.errorMsg = "请填写短信验证码";
          } else if (phoneNumber !== "" && phoneCode !== "") {
            let data = {
              phone: phoneNumber,
              code: phoneCode,
            };
            let url = "/loginByAuthCode";
            getAction(url, data).then((res) => {
              if (res.code == 200 && res.success) {
                let resData = res.result;
                let customerList = resData.customerList;
                if (customerList.length === 1) {
                  let userInfo = customerList[0];
                  let customerToken = customerList[0].token;
                  let loginToken = _this.$base64.encode(customerToken);
                  _this.$ls.set(
                    SHOPPING_ACCESS_TOKEN,
                    loginToken,
                    24 * 60 * 60 * 1000
                  );
                  let encodeUserInfo = this.$base64.encode(
                    JSON.stringify(userInfo)
                  );
                  _this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
                  _this.$store.commit("SET_TOKEN", loginToken);
                  _this.$store.commit("SET_INFO", userInfo);
                  _this.$router.push("/index");
                  // _this.$alert('系统维护中，请联系开票员下单！', "提示信息", {
                  //   type: "warning",
                  // });
                } else if (customerList.length > 1) {
                  _this.initCustomerList(customerList);
                  _this._this.selectUserInfo = 2;
                }
              }
            });
          }
        }
      }
    },
    cleanLoginData() {
      this.$ls.remove(SHOPPING_ACCESS_TOKEN);
      this.$ls.remove(USER_INFO);
      this.$ls.remove(SALEMAN_INFO);
      this.$store.commit("SET_TOKEN", "");
      this.$store.commit("SET_INFO", {});
      this.$store.commit("SET_SALEMAN_TOKEN", "");
      this.$store.commit("SET_SALEMAN_INFO", {});
    },
    // 登录成功给客户列表赋值
    initCustomerList(list) {
      this.userInfoList = list;
      let customerList = [];
      for (let key of list) {
        customerList.push(key);
      }
      this.selectCustomerList = customerList;
    },
    // 业务员登录事件
    loginSubmit2() {
      let _this = this;
      if (!this.loginLoading) {
        // 对账号密码登录进行验证
        this.loginLoading = true;
        // 获取用户输入的信息数据
        let username = this.username.trim();
        let passsword = this.password;
        let verifyCode = this.verifyCode;
        let verifyKey = this.verifyKey;
        let loginData = {
          username: username,
          password: passsword,
        };
        if (verifyCode) {
          loginData.verifyCode = verifyCode;
          loginData.verifyKey = verifyKey;
        }
        console.log("业务员登录传输的数据为++++++", loginData);
        this.YwyLogin(loginData)
          .then((res) => {
            _this.cleanLoginData()
            console.log("业务员登录返回的数据++++", res);
            _this.loginLoading = false;
            _this.salemanLoginStatus = true;
            if (res.code === 200) {
              _this.verifyShowType = false;
              // 2.判断用户登录身份
              let resData = res.result;
              // 1.判断用户是否点击了记住密码功能
              let rememberState = _this.rememberState;
              if (rememberState) {
                _this.$ls.set("salemanName", username);
              } else {
                _this.$ls.remove("salemanName");
              }
              // 3.存储业务员的信息和token
              let salemanToken = resData.token;
              let salemanInfo = resData.sysUser;
              let encodeSalemanInfo = this.$base64.encode(
                JSON.stringify(salemanInfo)
              );
              _this.$ls.set(SALEMAN_INFO, encodeSalemanInfo, 24 * 60 * 60 * 1000);
              _this.$store.commit("SET_SALEMAN_TOKEN", salemanToken);
              _this.$store.commit("SET_SALEMAN_INFO", salemanInfo);
              _this.salemanName = salemanInfo.username;
              // 获取当前业务员对应的客户列表数据
              _this.getSalemanMapCustomerList()
              //TODO 下面代码是之前判断登录成功后业务员对应的客户处理操作，现改成请求接口让业务员选择
              // let customerList = resData.customerList;
              // // 判断当前业务员是否绑定了客户
              // if (customerList && customerList.length > 0) {
              //   // 1.判断用户是否点击了记住密码功能
              //   let rememberState = _this.rememberState;
              //   if (rememberState) {
              //     _this.$ls.set("salemanName", username);
              //   } else {
              //     _this.$ls.remove("salemanName");
              //   }
              //   // 3.存储业务员的信息和token
              //   let salemanToken = resData.token;
              //   let salemanInfo = resData.sysUser;
              //   let encodeSalemanInfo = this.$base64.encode(
              //     JSON.stringify(salemanInfo)
              //   );
              //   _this.$ls.set(SALEMAN_INFO, encodeSalemanInfo, 24 * 60 * 60 * 1000);
              //   _this.$store.commit("SET_SALEMAN_TOKEN", salemanToken);
              //   _this.$store.commit("SET_SALEMAN_INFO", salemanInfo);
              // if (customerList.length === 1) {
              //   console.log("当前的业务员对应的客户为1", customerList);
              //   let userInfo = customerList[0];
              //   let customerToken = customerList[0].token;
              //   let loginToken = _this.$base64.encode(customerToken);
              //   _this.$ls.set(
              //     SHOPPING_ACCESS_TOKEN,
              //     loginToken,
              //     24 * 60 * 60 * 1000
              //   );
              //   let encodeUserInfo = this.$base64.encode(
              //     JSON.stringify(userInfo)
              //   );
              //   _this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
              //   _this.$store.commit("SET_TOKEN", loginToken);
              //   _this.$store.commit("SET_INFO", userInfo);
              //   let erpId = userInfo.erpId;
              //   if (!erpId || erpId == "") {
              //     _this.$router.push({
              //       name: "register",
              //       query: { status: 2 },
              //     });
              //   } else {
              //     _this.$router.push("/index");
              //   }
              // } else if (customerList.length > 1) {
              //   console.log("当前的业务员对应的客户为多个", customerList);
              //   _this.initCustomerList(customerList);
              //   _this.selectUserInfo = 2;

              //   _this.salemanName = salemanInfo.username;
              // }
              // } else {
              //   this.$alert('当前业务员没有绑定客户，请先绑定客户后在进行操作！', "提示信息", {
              //     type: "error",
              //   });
              //   return false;
              // }
            } else {
              _this.errorMsg = res.message;
              if (res.code === 402) {
                let resData = res.result;
                let verifyCode = resData.verifyCode;
                if (verifyCode) {
                  _this.verifyShowType = true;
                  _this.verifyKey = verifyCode.key;
                  _this.verifyImg = `data:image/jpg;base64,${verifyCode.img}`;
                }
              }
            }
          })
          .catch((err) => {
            _this.loginLoading = false;
          });
      }
    },
    // 获取当前业务员对应的客户列表数据
    async getSalemanMapCustomerList() {
      let url = "/customer/userCustomerMap/list";
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let searchUserName = this.searchUserName.trim();
      let salemanToken = this.salemanToken
      let data = {
        userToken: salemanToken,
        pageNo: pageNo,
        pageSize: pageSize,
        customerName: searchUserName
      };
      let res = await getAction(url, data);
      console.log("业务员关联的客商列表为+++++++", res);
      if (res.code == 200 && res.success) {
        let resData = res.result;
        let selectCustomerList = resData.records;
        let pageNo = resData.current;
        let pages = resData.pages;
        if (resData.current === 1) {
          this.selectCustomerList = selectCustomerList;
        } else if (pageNo <= pages) {
          this.selectCustomerList = this.selectCustomerList.concat(selectCustomerList)
        }
        this.pageNo = resData.current;
        this.pages = resData.pages;
        this.initCustomerList(this.selectCustomerList);
        this.selectUserInfo = 2;
      } else {
        this.pageNo = 1;
        this.pages = 1;
        this.selectCustomerList = []
        this.$alert(res.message);
      }
    },
    // 加载下一页操作
    addMoreCutomerList() {
      this.pageNo++;
      this.getSalemanMapCustomerList()
    },
    // 切换验证码图片
    taggleVerfyImg() {
      let _this = this;
      let url = "customer/getVerifyCode";
      getAction(url).then((res) => {
        let data = res.result;
        if (res.code === 200 && res.success === true) {
          _this.verifyKey = data.key;
          _this.verifyImg = `data:image/jpg;base64,${data.img}`;
        }
      });
    },
    // 选择用户登录身份
    selectedUserInfo(item) {
      // 判断用户的状态
      if (item.erpStatus == '0' || item.status == '0') {
        this.$alert('该账户已停用,请联系客服人员查看停用原因！', "提示信息", {
          type: "error",
        });
        return false;
      } else if (item.errorInfo && item.errorInfo !== '') {
        this.$alert(item.errorInfo, "提示信息", {
          type: "error",
        });
      } else {
        let customerToken = item.token;
        let loginToken = this.$base64.encode(customerToken);
        this.$ls.set(SHOPPING_ACCESS_TOKEN, loginToken, 24 * 60 * 60 * 1000);
        let encodeUserInfo = this.$base64.encode(JSON.stringify(item));
        this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
        this.$store.commit("SET_TOKEN", loginToken);
        this.$store.commit("SET_INFO", item);
        let erpId = item.erpId;
        if (!erpId || erpId == "") {
          this.$router.push({
            name: "register",
            query: { status: 2 },
          });
        } else {
          if (item.pcGotoUrl && item.pcGotoUrl !== '') {
            location.href = item.pcGotoUrl
          } else {
            this.$router.push("/index");
          }
        }
      }
    },
    // 验证手机号码
    verifyPhone() {
      let phoneNumber = this.phoneNumber;
      if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(phoneNumber)) {
        this.errorMsg = "请填写正确的手机号码";
      } else {
        this.errorMsg = "";
      }
    },
    // 手机登录发送验证码操作
    phoneVerify() {
      let _this = this;
      let phoneNumber = this.phoneNumber;
      let data = {
        username: phoneNumber,
      };
      let url = "/customer/sendPhoneCode";
      let verfiyState = this.verfiyState;
      if (!verfiyState) {
        getAction(url, data).then((res) => {
          if (res.code === 200 && res.success) {
            let timeNumber = _this.timeNumber;
            const phoneInterVal = setInterval(function () {
              timeNumber--;
              _this.timeNumber = timeNumber;
              if (timeNumber == 0) {
                clearInterval(phoneInterVal);
                _this.timeNumber = 120;
                _this.verfiyState = false;
              } else {
                _this.verfiyState = true;
              }
            }, 1000);
          } else {
            _this.verfiyState = false;
            _this.$alert(res.message);
          }
        });
      }
    },
    // 获取游客看到的登录背景图
    async getCompanyConfigData() {
      let url = "company/companyConfig/queryById"
      let res = await getAction(url);
      console.log("获取的公司配置信息为++++++", res)
      if (res.code == 200 && res.success) {
        let resData = res.result;
        this.loginBgImgUrl = resData.loginBgImgUrl
        this.$store.commit("COMPANY_CONFIG_DATA_LOGIN", resData);
      }
    },
  },
  destroyed() {
    clearTimeout(this.encodeImgTimer);
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/login/login";
</style>