<template>
  <div class="error-page">
    <div class="main-bg"></div>
    <h3 class="error-title">系统升级维护中...</h3>
    <p class="error-main-tip">{{ companyConfigData.maintainTips ? companyConfigData.maintainTips : '系统正在维护，请联系客服下单。' }}
    </p>
    <p v-if="companyConfigData.customerServiceTel && companyConfigData.customerServiceTel !== ''" class="customer-tel">
      客服电话：{{ companyConfigData.customerServiceTel }}</p>
    <a v-if="companyConfigData.customerServiceQq && companyConfigData.customerServiceQq !== ''" target="_blank"
      class="link-service"
      :href="`http://wpa.qq.com/msgrd?v=3&uin=${companyConfigData.customerServiceQq}&site=qq&menu=yes`">联系在线客服</a>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data() {
    return {
      companyConfigData: {},
    }
  },
  created() {
    let companyConfigData = this.$ls.get("companyConfigData");
    // 系统维护功能开启
    if (companyConfigData.maintainFlag && companyConfigData.maintainFlag == '1') {
      // 判断系统维护功能提示信息
      this.companyConfigData = companyConfigData
    }
  }
}
</script>

<style lang="scss" scoped>
.error-page {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 310px;

  .main-bg {
    width: 310px;
    height: 230px;
    background: url("~@/assets/images/common/maintain-bg.png") no-repeat center;
  }

  .error-title {
    font-size: 34px;
    margin-top: 30px;
  }

  .error-main-tip {
    text-align: center;
    margin-top: 30px;
    line-height: 35px;
    font-size: 18px;
    font-weight: 400;
    color: #585858;
  }

  .customer-tel {
    margin-top: 30px;
    font-size: 17px;
    font-weight: bold;
    color: $act-color;
  }

  .link-service {
    margin-top: 30px;
    display: inline-block;
    width: 120px;
    height: 35px;
    border-radius: 5px;
    text-align: center;
    line-height: 35px;
    font-size: 15px;
    background-color: $base-color;
    color: #ffffff;
  }
}
</style>