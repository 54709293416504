<template>
  <div class="areaContainer" style="background-color: #f5f5f5">
    <!-- banner区域 -->
    <div
      v-if="brandAreaBanner && brandAreaBanner !== ''"
      class="areaBanner container"
    >
      <img :src="brandAreaBanner" alt="" />
    </div>
    <!-- end banner区域 -->
    <!-- 头部标题 -->
    <div class="brandHeader container_box">
      <div class="brandBox">
        <img src="../assets/images/brandArea/title.png" alt="" />
      </div>
    </div>
    <!-- end 头部标题 -->
    <!-- 厂家名称列表 -->
    <div class="brandNameListBox screenMain">
      <div class="screenContent">
        <div class="screenTop clearfix">
          <div class="screenTitle fl">全部品牌</div>
          <div class="screenbox fl">
            <!--默认显示-->
            <div :class="!proTypeSide ? 'autoList' : ''" class="screenListBox">
              <!--点击锁定类目后的样式-->
              <span
                v-for="(item, index) of factoryProductList"
                :key="'proType' + index"
                @click="jump(index)"
              >
                {{ item.manufacturerName }}
              </span>
            </div>
          </div>
          <div class="rightbox fr">
            <a
              @click="tabSideType(false)"
              v-show="proTypeSide"
              href="javascript:void(0);"
              class="zhan-er"
            >
              展开<i class="el-icon-arrow-down"></i>
            </a>
            <a
              @click="tabSideType(true)"
              v-show="!proTypeSide"
              href="javascript:void(0);"
              class="shou-er"
            >
              收起<i class="el-icon-arrow-up"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- end 厂家名称列表 -->
    <div class="brandListContent">
      <div class="brandList">
        <div
          class="brandItem fl"
          v-for="(item, index) of factoryProductList"
          :key="'brand' + index"
        >
          <div class="brandBox">
            <div class="brandTop">
              <div class="logo">
                <img :src="item.iconUrl" alt="" />
              </div>
              <div class="brandData">
                <div class="name">{{ item.manufacturerName }}</div>
                <div class="number">
                  共有<span>{{ item.productCount }}</span
                  >个品种
                </div>
                <div class="linkBtn">
                  <a :href="item.goToUrl" target="_blank">进入专场</a>
                </div>
              </div>
            </div>
            <div class="proList">
              <div
                class="proItem"
                :key="'product' + itemC.id"
                v-for="(itemC, indexC) of item.brandAreaManufacturerProductList"
              >
                <div v-if="itemC.product" class="proBox">
                  <router-link
                    :to="{
                      path: '/productDetail',
                      query: { productId: itemC.productId },
                    }"
                    :title="item.name"
                    target="_blank"
                  >
                    <div class="proImg">
                      <img v-lazy="productImg(itemC.product)" alt="" />
                    </div>
                    <div class="proName">{{ itemC.product.name }}</div>
                    <div v-if="itemC.product.inventoryList && itemC.product.inventoryList.length > 0" class="price">
                      ￥{{ itemC.product.inventoryList[0].price }}
                    </div></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAction } from "../api/manage";
export default {
  name: "BrandZone",
  data() {
    return {
      brandAreaBanner: "",
      factoryProductList: [],
      toTopShow: false,
      scrollTop: 0,
      proTypeSide: false,
    };
  },
  created() {
    this.getBrandAreaList();
  },
  methods: {
    // 显示产品图片
    productImg(item) {
      if (item.picUrl && item.picUrl !== "") {
        let imgsArr = item.picUrl.split(",");
        let imgSrc = imgsArr[0];
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    tabSideType() {
      this.proTypeSide = !this.proTypeSide;
    },
    // 获取品牌专区列表的数据为
    getBrandAreaList() {
      let _this = this;
      let url = "/area/brandAreavo/list";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let imgPath = _this.$imgPath;
          if (resData.brandArea.picUrl && resData.brandArea.picUrl !== "") {
            _this.brandAreaBanner = `${imgPath}${resData.brandArea.picUrl}`;
          }
          if (
            resData.brandAreaManufacturerList &&
            resData.brandAreaManufacturerList.length > 0
          ) {
            _this.factoryProductList = resData.brandAreaManufacturerList;
          }
        }
      });
    },
    handleScroll() {
      //首先修改相对滚动位置
      this.scrollTop = this.scrollTop =
        window.pageYOffset || document.body.scrollTop;
      if (this.scrollTop > 300) {
        this.toTopShow = true;
      } else {
        this.toTopShow = false;
      }
    },
    scrollToTop() {
      let timer = null,
        _that = this;
      //动画，使用requestAnimationFrame代替setInterval
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 500;
          //然后修改这里实现动画滚动效果
          document.body.scrollTop = document.documentElement.scrollTop =
            _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.toTopShow = false;
        }
      });
    },
    /**
     * 锚点连接平滑滚动
     **/
    jump(index) {
      // 用 class="instance_title" 添加锚点
      let jump = document.querySelectorAll(".brandItem");
      let total = jump[index].offsetTop;
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }

      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 2);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }

      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 2);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      //修改事件监听
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/product/brandZone";
</style>