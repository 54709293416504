<template>
  <div class="navListContent container">
    <div class="navListBox container_box">
      <div class="productClassify">
        <div @mousemove="showThisTime" @mouseout="hideThisTime">
          <img class="classifyIcon" src="@/assets/images/common/classifyIcon.png" alt="" />
          全部商品分类
        </div>
        <div @mousemove="showThis = true" @mouseout="showThis = false" v-show="showThis || routerPath == '/index'"
          class="classify">
          <div class="classifyLiBox"
            :style="productCategoryList.length > 5 ? 'justify-content: space-between;' : 'justify-content: flex-start;'">
            <div :class="{ active: index == 0 }" class="classifyLi" v-for="(item, index) of productCategoryList"
              :key="'classifyFirst' + index">
              <p :style="`height: ${420 / productCategoryList.length}px;`" @click="gotoSearchProduct(item)">
                <img v-lazy="item.iconUrl" alt="" />
                {{ item.name }}
              </p>
              <div class="category_list">
                <div class="category_list_li" v-for="(itemC, indexC) of item.children" :key="'category_list' + indexC">
                  <div @click="gotoSearchProduct(itemC)" class="category_list_title">
                    {{ itemC.name }}
                  </div>
                  <div class="ct">
                    <div class="category_list_link">
                      <a v-for="(itemCc, indexCc) of itemC.children" :key="'category_list_link' + indexCc"
                        @click="gotoSearchProduct(itemCc)" href="javascript:;">
                        {{ itemCc.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navlist">
        <ul>
          <li v-for="(item, index) of navMenuList" :key="'navLi' + index">
            <a :class="{ active: item.linkAddress === '/productList' ? (routerPath.indexOf('/productList?productLabelList=') < 0 && routerPath.indexOf('/productList') > -1) : routerPath.indexOf(item.linkAddress) > -1 }"
              :href="item.linkAddress + `${(loginState && item.gotoAddTokenFlag && item.gotoAddTokenFlag == '1') ? `?Shopping-Access-Token=${userInfo.token}` : ''}`"
              :target="item.isShowNewPage == 1 ? '_blank' : ''" @click="searchPro(item)">
              {{ item.name }}
            </a>
            <img v-if="item.isShowIcon == '1' && item.iconAddress" :src="item.iconAddress" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getAction } from "@/api/manage";
import { mapGetters } from "vuex";
import { getUserInfo } from "@/assets/js/common/common";
export default {
  name: "NavContent",
  data() {
    return {
      showThis: false,
      routerPath: "/index",
      timerStatus: false,
      userInfo: {},
      loginState: false,
    };
  },
  created() {
    let path = this.$route.fullPath;
    console.log("this.$route+++++++++", this.$route)
    this.routerPath = path;
    if (path === "/index" || path === "/") {
      this.showThis = true;
      this.routerPath = "/index";
    } else {
      this.showThis = false;
      this.routerPath = path;
    }

    let userInfo = getUserInfo();
    if (userInfo) {
      this.userInfo = userInfo;
      this.loginState = true;
    } else {
      this.loginState = false;
    }
    this.routerChange();
  },
  computed: {
    ...mapGetters(["productCategoryList", "navMenuList"]),
  },
  methods: {
    showThisTime() {
      let _this = this;
      _this.timerStatus = true;
      setTimeout(function () {
        let timerStatus = _this.timerStatus;
        if (timerStatus) {
          _this.showThis = true;
          _this.timerStatus = false;
        }
      }, 1000);
    },
    hideThisTime() {
      this.timerStatus = false;
      this.showThis = false;
    },
    handleSwitch() {
      let path = this.$route.path;
      this.routerPath = path;
      if (path === "/index" || path === "/") {
        this.showThis = true;
      } else {
        this.showThis = !this.showThis;
      }
    },
    // 监听获取产品分类和导航菜单
    routerChange() {
      let path = this.routerPath;
      let pathArr = path.split("?");
      let pathUrl = pathArr[0];
      if (
        pathUrl === "/index" ||
        pathUrl === "/" ||
        pathUrl === "/productDetail" ||
        // pathUrl === "/productList" ||
        // pathUrl === "/activity" ||
        pathUrl === "/specialArea"
      ) {
        this.getProductCategory();
        this.getNavlist();
      }
    },
    // 获取产品分类数据
    getProductCategory() {
      const _this = this;
      const url = "product/getProductCategory";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          let productCategoryList = res.result;
          _this.$store.commit("PRODUCT_CATEGORY_LIST", productCategoryList);
        }
      });
    },
    // 菜单导航栏数据
    getNavlist() {
      let _this = this;
      let url = "/homeShow/homePageNavigation/list";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          let navMenuList = res.result;
          _this.$store.commit("NAV_MENU_LIST", navMenuList);
        }
      });
    },
    // 点击全部商品重新搜索
    searchPro(item) {
      if (item.linkAddress == "/#/productList") {
        this.$emit("searchProduct", "");
      }
    },
    gotoSearchProduct(item) {
      const routerName = this.$route.name;
      const productCategory = item.name;
      if (productCategory && productCategory !== "") {
        if (routerName === "productList") {
          this.$emit("searchProductCategory", productCategory);
        } else {
          this.$router.push({
            name: "productList",
            query: { productCategory: productCategory },
          });
        }
        this.showThis = false;
      }
    },
    searchProductCategory(item) {
      this.$router.push({
        name: "productList",
        query: { productCategory: item.name },
      });
    },
  },
  watch: {
    $route(to, from) {
      let routerPath = to.fullPath;
      if (routerPath == "/index" || routerPath == "/") {
        this.showThis = true;
      } else {
        this.showThis = false;
      }
      this.routerPath = routerPath;
      this.routerChange();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/navContent";
</style>