<template>
  <div class="list-container">
    <div class="search-top">
      <el-form :model="orderForm" ref="orderForm" class="order-search-form">
        <el-form-item class="search-input" label="">
          <el-input class="search-input" v-model="orderForm.searchVal" placeholder="输入商品名称/订单编号搜索"></el-input>
        </el-form-item>
        <el-form-item class="search-date-box" label="下单日期">
          <el-date-picker class="range-box" v-model="orderForm.createDate" type="daterange" align="right" unlink-panels
            range-separator="~" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-button class="search-btn" type="primary" @click="searchOrder('orderForm')">搜索</el-button>
      </el-form>
    </div>
    <div class="list-content">
      <div v-loading="orderLoading" class="list-table">
        <div>
          <ul class="table-header">
            <li class="ddxx">订单信息</li>
            <li class="ddje">订单金额</li>
            <li class="ddzt">订单状态</li>
            <li class="cz">操作</li>
          </ul>
        </div>
        <div class="list-box" v-if="orderList.length > 0">
          <ul class="list-content-item" v-for="(item, index) of orderList" :key="item.id + '_' + index">
            <li>
              <div class="ddxx-content">
                <!-- 订单单号 -->
                <p class="order-code">订单单号：
                  <a @click="navToOrderAct(item)" href="JavaScript:;">
                    {{ item.id }}
                  </a>
                </p>
                <!-- 订单标识 -->
                <p v-if="item.title && item.title !== ''" class="title-list">
                  <span v-for="(tipItem, tipIndex) of (item.title).split(',')" :key="`${tipItem}-${tipIndex}`"
                    class="order-title">{{ tipItem }}</span>
                </p>
                <!-- 订单时间 -->
                <p class="create-time">{{ item.createTime }}</p>
                <!-- 订单明细数量 -->
                <p class="product-num">{{ item.time }}</p>
              </div>
            </li>
            <li>
              <div class="ddje-content">
                <!-- 订单金额 -->
                <p class="order-money">￥{{ item.confirmMoney }}</p>
                <!-- 支付方式 -->
                <p class="pay-type">{{ item.feeType == 1 ? "线下支付" : "线上支付" }}</p>
              </div>
            </li>
            <li>
              <div class="ddzt-content">
                <!-- 状态 -->
                <p class="order-status">{{ item.orderStatusName }}</p>
              </div>
            </li>
            <li>
              <div class="cz-content">
                <a href="javascript:;" v-if="item.nextFlow !== 'confirm' && item.nextFlow !== 'pay'"
                  @click.stop="navToOrderAct(item)">订单详情</a>
                <el-button v-if="item.nextFlow === 'confirm'" class="action-btn"
                  @click.stop="confirmOrderClick(item)">确认订单</el-button>
                <el-button v-if="item.nextFlow === 'pay'" class="action-btn"
                  @click.stop="payOrderClick(item)">去付款</el-button>
                <el-button v-if="item.optCancelFlag === '1'" class="cancel-btn"
                  @click.stop="cencalOrder(item)">取消订单</el-button>
                <!-- 发票下载 -->
                <!-- <a href="javascript:;">发票下载</a> -->
                <!-- 资质下载 -->
                <!-- <a href="javascript:;">商品资质下载</a> -->
                <!-- 操作按钮 -->
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <div class="dataNone">
            <div class="dataImg orderNoneImg"></div>
          </div>
        </div>
      </div>
    </div>


    <div class="pageFooter">
      <el-pagination background :hide-on-single-page="true" v-if="totalCount > 10" layout="prev, pager, next, jumper"
        :current-page="pageNo" @current-change="currentChange" :page-size="pageSize" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAction, postAction } from "../../api/manage";

export default {
  name: "OrderRight",
  data() {
    return {
      orderForm: {
        searchVal: '', // 商品名称和订单编号
        createDate: '', // 
      },
      pageSize: 5, // 每页显示的条数
      pageNo: 1, // 请求多少页
      totalCount: 1, // 请求多少页
      pickerOptions: { // 日期框快捷键操作参数
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      orderStatus: 0, // 订单状态：0：全部订单 1：待配货 2：待处理 3：已完成 4：已取消
      orderList: [],
      orderLoading: false,
    };
  },
  computed: {
    ...mapGetters(["pendingOrderCount"]),
  },
  created() {
    let query = this.$route.query;
    if (query && query !== "{}") {
      this.orderStatus = parseInt(query.state);
    }
    this.getOrderListData();
  },
  methods: {
    /**
     * @author: CaoXiaoChuan
     * @description: 订单表格信息跳转
     * @param {*} item 订单信息
     */
    navToOrderAct(item) {
      let text = this.$router.resolve({ name: 'orderDetail', query: { saleOrderId: item.id } });
      window.open(text.href, '_blank')
    },
    /**
     * @author: CaoXiaoChuan
     * @description: 订单列表确认订单操作
     * @param {*} item 订单信息
     */
    confirmOrderClick(item) {
      const that = this;
      const saleOrderId = item.id;
      this.$confirm("是否确认该笔订单?", "提示", {
        confirmButtonText: "确认订单",
        cancelButtonText: "取消操作",
        type: "info",
      }).then(() => {
        const url = "/saleOrder/saleOrder/confirmSaleOrder";
        let data = {
          saleOrderId: saleOrderId
        }
        postAction(url, data, true).then((res) => {
          if (res.code === 200 && res.success) {
            that.$message({
              message: "订单确认成功!",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            that.getOrderListData();
          }
        });
      });
    },
    /**
     * @author: CaoXiaoChuan
     * @description: 跳转支付订单页面
     * @param {*} item
     */
    payOrderClick(item) {
      this.$router.push({
        name: 'orderPay',
        query: { payOrderId: item.payOrderId, saleOrderId: item.id },
      })
    },
    auditStatus(auditStatus) {
      if (auditStatus === 1) {
        return "待审批";
      } else if (auditStatus === 2) {
        return "已通过";
      } else if (auditStatus === 3) {
        return "已驳回";
      }
    },
    cencalOrder(item) {
      const that = this;
      const saleOrderId = item.id;
      this.$confirm("是否取消该笔订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const url =
          "/saleOrder/saleOrder/cancelSaleOrder?saleOrderId=" + saleOrderId;
        postAction(url, null, true).then((res) => {
          if (res.code === 200 && res.success) {
            that.$message({
              message: "订单取消成功",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            this.getOrderListData();
          }
        });
      });
    },
    processingOrders(item) {
      const feeType = item.feeType;
      const saleOrderId = item.id;
      const payOrderId = item.payOrderId;
      const status = item.status;
      if (feeType === 2 && status === 2) {
        this.$router.push({
          name: "pay",
          query: { saleOrderId: saleOrderId, payOrderId: payOrderId },
        });
      } else {
        this.$router.push({
          name: "createOrder",
          query: { saleOrderId: saleOrderId },
        });
      }
    },
    orderStatusText() {
      let orderStatus = this.orderStatus;
      if (orderStatus == 1) {
        return "待配货";
      } else if (orderStatus == 2) {
        return "待处理";
      } else if (orderStatus == 3) {
        return "已完成";
      } else if (orderStatus == 4) {
        return "已取消";
      }
    },
    changeOrderStatus(num) {
      this.$emit("changeOrderStatus", num);
    },
    ceshiClass(index) {
      switch (index) {
        case 0:
          return "ytk";
        case 1:
          return "ybh";
        case 2:
          return "dhp";
      }
    },
    searchOrder(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let orderForm = this.orderForm;
          console.log("orderForm+++++++++++", orderForm)
          // 处理客户查询日期格式
          if (orderForm.createDate && orderForm.createDate.length > 0) {
            this.orderForm.dateStart = `${orderForm.createDate[0]} 00:00:00`
            this.orderForm.dateEnd = `${orderForm.createDate[1]} 23:59:59`
          } else {
            delete this.orderForm.dateStart
            delete this.orderForm.dateEnd
          }
          // 处理客户搜索信息
          orderForm.searchVal = orderForm.searchVal.replace(/(^\s*)|(\s*$)/g, "");
          _this.getOrderListData()
        } else {
          return false;
        }
      });
    },
    getOrderListData() {
      let _this = this;
      this.orderLoading = true;
      let state = this.orderStatus;
      // 订单编号首尾去空格(防止用户复制粘贴多复制了空白);
      let orderForm = this.orderForm;
      // 分页数据，订单状态
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let orderStatus = parseInt(state) - 1;
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
        orderStatus: orderStatus,
        ...orderForm
      };
      // 删除订单日期查询数据
      delete data.createDate
      if (orderStatus == -1) {
        delete data.orderStatus
      }
      console.log("订单搜索的查询条件：", data)
      let url = "/saleOrder/saleOrder/list";
      getAction(url, data).then((res) => {
        _this.orderLoading = false;
        if (res.code == 200 && res.success) {
          let resData = res.result;
          _this.orderList = resData.records;
          _this.pageNo = resData.current;
          _this.totalCount = resData.total;
        }
      });
    },
    // 切换页数
    currentChange(val) {
      this.pageNo = val;
      this.getOrderListData();
    },
  },
  watch: {
    $route(to, form) {
      let routerName = to.name;
      let query = to.query;
      if (routerName === "orderList") {
        let state = parseInt(query.state);
        this.orderStatus = state;
        this.pageNo = 1;
        this.getOrderListData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/order/list";

.pageFooter {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dataNone {
  position: absolute;
  width: 930px;
}

.title-list {
  span {
    padding: 2px 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
    font-size: 12px;
    margin-right: 5px;
    background: #ea5506;
  }
}
</style>
