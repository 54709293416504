<template>
  <div>
    <carousel></carousel>
    <index-floor></index-floor>
    <advert-mask></advert-mask>
  </div>
</template>
<script>
import { getAction } from "../api/manage";
import Vue from "vue";
import AdvertMask from "../components/index/AdvertMask.vue";
import Carousel from "../components/index/Carousel.vue";
import IndexFloor from "../components/index/IndexFloor.vue";
import { SHOPPING_ACCESS_TOKEN, USER_INFO } from '../store/mutation-types';
export default {
  components: { Carousel, IndexFloor, AdvertMask },
  name: "Index",
  props: [],
  created() {
    let that = this;
    // 查看当前用户的token配置
    let baihuoToken = this.$route.query.baihuoToken;
    let yaopinToken = this.$route.query.yaopinToken;
    if (baihuoToken && baihuoToken !== '') {
      console.log("跳转过来当前百货的token", baihuoToken)
      let jmbhToken = this.$base64.encode(baihuoToken)
      Vue.ls.set(SHOPPING_ACCESS_TOKEN, jmbhToken);
      // 根据客户token查询客户信息
      that.getCustomerData(baihuoToken)
    } else if (yaopinToken && yaopinToken !== '') {
      console.log("跳转过来当前的药品的token", yaopinToken)
      let jmbhToken = this.$base64.encode(yaopinToken)
      Vue.ls.set(SHOPPING_ACCESS_TOKEN, jmbhToken);
      // 根据客户token查询客户信息
      that.getCustomerData(yaopinToken)
    } else {
      that.backTop();
      that.getCustomerAnnouncement();
      that.getNoticeList();
      that.getCompanyCredentials();
      let companyConfigData = Vue.ls.get('companyConfigData');
      if (!companyConfigData || companyConfigData == '') {
        that.getCompanyConfigData()
      }
    }
  },
  data() {
    return {
      scratchStatus: false,
    };
  },
  methods: {
    closeScratchCard (data) {
      let _this = this
      this.scratchStatus = 0
    },
    // 获取用户当前数据
    async getCustomerData(userTokenStr) {
      let baihuoToken = this.$route.query.baihuoToken;
      let yaopinToken = this.$route.query.yaopinToken;
      let _this = this;
      let url = "customer/customerConfig/get";
      let res = await getAction(url);
      console.log("获取当前的用户数据为", res);
      if (res.code == 200 && res.success) {
        let resData = res.result;
        let userInfo = resData.customer;
        if (!userInfo.token || userInfo.token == '') {
          if (baihuoToken && baihuoToken !== '') {
            userInfo.token = baihuoToken
          } else if (yaopinToken && yaopinToken !== '') {
            userInfo.token = yaopinToken
          }
        }
        let customerToken = userTokenStr;
        let loginToken = _this.$base64.encode(customerToken);
        let encodeUserInfo = this.$base64.encode(
          JSON.stringify(userInfo)
        );
        Vue.ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
        this.$store.commit("SET_TOKEN", loginToken);
        this.$store.commit("SET_INFO", userInfo);
        this.$router.push("/");
      }
    },
    // 获取公司资质信息
    getNoticeList() {
      let _this = this;
      let url = "/notice/notice/list";
      getAction(url).then((res) => {
        if ((res.code === 200) & res.success) {
          let resData = res.result;
          let companyNoticeList = resData.records;
          _this.$store.commit('COMPANY_NOTICE_LIST', companyNoticeList)
        }
      });
    },
    // 获取后台上传的公司资质
    getCompanyCredentials() {
      let _this = this
      let url = '/company/companyCredentials/list'
      getAction(url).then(res => {
        if (res.code === 200 && res.success) {
          let companyCredentialsList = res.result.records
          _this.$store.commit('COMPANY_CREDENTIALS_LIST', companyCredentialsList)
        }
      })
    },
    // 获取客户系统通知消息
    async getCustomerAnnouncement() {
      let url = "customer/customerAnnouncement/count"
      let res = await getAction(url);
      if (res.code == 200 && res.success) {
        this.$store.commit("UNREAD_MSG_NUM_ALL", res.result);
      }
    },
    // 获取所在的公司配置
    async getCompanyConfigData() {
      let url = "company/companyConfig/queryById"
      let res = await getAction(url);
      let productShowType = '1';
      console.log("获取的公司配置信息为++++++", res)
      if (res.code == 200 && res.success) {
        let resData = res.result;
        // 产品列表默认展示方式
        let localProductShowType = this.$ls.get('productShowType');
        if (!localProductShowType || localProductShowType == '') {
          if (resData.defaultProductListShowType) {
            productShowType = resData.defaultProductListShowType;
          } else {
            productShowType = '2'
          }
          // 设置state参数状态
          this.$store.commit("PRODUCT_SHOW_TYPE", productShowType);
        }

        // 系统维护功能开启
        if (resData.maintainFlag && resData.maintainFlag == '1') {
          // 判断系统维护功能提示类型
          let maintainType = resData.maintainType;
          if (maintainType == '1') {
            this.$alert(resData.maintainTips, '系统维护提示', {
              type: 'error'
            })
          } else if (maintainType == '2') {
            this.$router.push("/maintain")
          }
        }
        this.$store.commit("COMPANY_CONFIG_DATA", resData);
      }
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped></style>