<template>
  <div v-loading="shopLoading">
    <div v-if="!orderSuccess" class="orderDetailBox container_box clear">
      <div class="leftBox">
        <div class="detail centerBox">
          <h3 class="backOrderaBtn">
            <a href="javascript:;" @click="$router.back(-1)">
              <i class="detailIcon el-icon-arrow-left"></i>
              返回订单列表
            </a>
          </h3>
          <div class="orderHeaderBox">
            <p>
              客商编码：{{ saleOrder.customerId }}
            </p>
            <p>
              客商名称：{{ saleOrder.customerName }}
            </p>
            <p>订单编号：{{ saleOrder.id }}</p>
            <p>订单时间：{{ saleOrder.createTime }}</p>
            <p>订单状态：待确认</p>
            <p>
              付款类型：{{ saleOrder.feeType === 1 ? "线下支付" : "线上支付" }}
            </p>
          </div>
          <div class="orderMoney">
            <p>订单金额：{{ saleOrder.originalSaleMoney }}元</p>
            <p v-if="saleOrder.confirmDiscountMoney > 0">
              优惠金额：{{ saleOrder.confirmDiscountMoney }}元
            </p>
            <p v-if="saleOrder.oosMoney > 0">
              缺货金额：{{ saleOrder.oosMoney }}元
            </p>
            <p v-if="saleOrder.logisticsMoney > 0">
              运费金额：{{ saleOrder.logisticsMoney }}元
            </p>
            <p class="payMoney">付款金额：{{ accmulPayorderMoney }}元</p>
          </div>
          <div class="addressBox">
            <p v-if="discountTicketList.length > 0">
              <i class="ticketIcon"></i>
              优惠券：{{ discountTicketList[0].name }}
            </p>
            <p v-if="customerAddress && JSON.stringify(customerAddress) !== '{}'" class="address">
              <i class="addressIcon"></i>
              收货地址：{{ customerAddress.contactsName }} ({{
                customerAddress.contactsTel
              }}) {{ customerAddress.address }}
            </p>
          </div>
        </div>
      </div>

      <div class="OrderRightBox">
        <div class="listNone" v-if="false"></div>
        <div class="orderList">
          <div class="orderListTitle">
            <div>
              <ul class="tableHeader">
                <li class="spmc">商品名称</li>
                <li class="gg">规格</li>
                <li class="sccj">厂家</li>
                <li class="pzwh">批准文号</li>
                <li class="dj">单价</li>
                <li class="zkj">缺货数量</li>
                <li class="sl">数量</li>
                <li class="je">金额</li>
              </ul>
            </div>
            <div v-if="saleOrderItemList.length > 0">
              <div class="itemList" v-for="(item, index) of saleOrderItemList" :key="'orderItem' + index">
                <ul class="tableList">
                  <li class="spmc">
                    <a href="javascript:;" @click="linkProDetail(item)">
                      <span v-if="item.returnAmount && item.returnAmount > 0" class="tips tipzp">[退货]</span>
                      <span v-else-if="item.cxInfo && item.cxInfo !== ''" @click.stop="showCxInfo(item.cxInfo)"
                        class="tips tipzp">[促销]</span>
                      {{ item.productName }}
                    </a>
                  </li>
                  <li>
                    <p class="gg">{{ item.productFormat }}</p>
                  </li>
                  <li>
                    <p class="sccj">{{ item.productManufacturer }}</p>
                  </li>
                  <li>
                    <p class="pzwh">{{ item.productLicense ? item.productLicense : '暂无数据' }}</p>
                  </li>
                  <li>
                    <p class="dj">￥{{ item.salePrice }}</p>
                  </li>
                  <li>
                    <p class="zdj">{{ item.oosAmount ? item.oosAmount : 0 }}</p>
                  </li>
                  <li>
                    <p class="sl">{{ item.saleAmount }}</p>
                  </li>
                  <li>
                    <p class="je">￥{{ item.confirmMoney }}</p>
                  </li>
                </ul>
                <ul v-if="item.policyId && item.policyId !== '' && item.policyItem.giftType == '1'"
                  class="tableList tableList2">
                  <li class="spmc">
                    <router-link :to="{
                      path: '/productDetail',
                      query: { productId: item.policyItem.giftId },
                    }"><span class="tipzp">赠品</span>
                      {{ item.policyItem.giftProductName }}</router-link>
                  </li>
                  <li>
                    <p class="gg">{{ item.policyItem.giftProductFormat ? item.policyItem.giftProductFormat : '暂无数据' }}
                    </p>
                  </li>
                  <li>
                    <p class="sccj">{{ item.policyItem.giftProductManufacturer ? item.policyItem.giftProductManufacturer
                      :
                      '暂无数据'
                    }}</p>
                  </li>
                  <li>
                    <p class="pzwh">暂无数据</p>
                  </li>
                  <li>
                    <p class="dj">￥{{ item.policyItem.sendNeedPrice }}</p>
                  </li>
                  <li>
                    <p class="zdj">暂无数据</p>
                  </li>
                  <li>
                    <p class="sl">{{ item.policyItem.sendNumber ? item.policyItem.sendNumber : "暂无数据" }}</p>
                  </li>
                  <li>
                    <p class="je">
                      {{
                        item.policyItem.sendNeedMoney ? "￥" + item.policyItem.sendNeedMoney : "暂无数据"
                      }}
                    </p>
                  </li>
                </ul>
                <ul v-else-if="item.policyId && item.policyId !== '' && item.policyItem.giftType == '2'"
                  class="tableList tableList2">
                  <li class="spmc">
                    <a href="javascript:;"><span class="tipzp">赠品</span>
                      {{ item.policyItem.giftName }}</a>
                  </li>
                  <li>
                    <p class="gg">{{ item.policyItem.giftFormat ? item.policyItem.giftFormat : '暂无数据' }}</p>
                  </li>
                  <li>
                    <p class="sccj">{{ item.policyItem.giftManufacturer ? item.policyItem.giftManufacturer : '暂无数据' }}
                    </p>
                  </li>
                  <li>
                    <p class="pzwh">暂无数据</p>
                  </li>
                  <li>
                    <p class="dj">￥{{ item.policyItem.sendNeedPrice }}</p>
                  </li>
                  <li>
                    <p class="zdj">暂无数据</p>
                  </li>
                  <li>
                    <p class="sl">{{ item.policyItem.sendNumber ? item.policyItem.sendNumber : "暂无数据" }}</p>
                  </li>
                  <li>
                    <p class="je">
                      {{
                        item.policyItem.sendNeedMoney ? "￥" + item.policyItem.sendNeedMoney : "暂无数据"
                      }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 缺货列表 -->
          <h3 v-if="saleOrderItemOosList.length > 0" class="quehuoTitle">
            缺货列表
          </h3>

          <div class="orderListTitle">
            <div class="itemList" v-for="(item, index) of saleOrderItemOosList" :key="'itemOos' + index">
              <ul class="tableList">
                <li class="spmc">
                  <a href="javascript:;" @click="linkProDetail(item)">
                    <span v-if="item.returnAmount && item.returnAmount > 0" class="tips tipzp">[退货]</span>
                    <span v-else-if="item.cxInfo && item.cxInfo !== ''" @click.stop="showCxInfo(item.cxInfo)"
                      class="tips tipzp">[促销]</span>
                    {{ item.productName }}
                  </a>
                </li>
                <li>
                  <p class="gg">{{ item.productFormat }}</p>
                </li>
                <li>
                  <p class="sccj">{{ item.productManufacturer }}</p>
                </li>
                <li>
                  <p class="pzwh">{{ item.productLicense ? item.productLicense : '暂无数据' }}</p>
                </li>
                <li>
                  <p class="dj">￥{{ item.salePrice }}</p>
                </li>
                <li>
                  <p class="zdj">{{ item.oosAmount ? item.oosAmount : 0 }}</p>
                </li>
                <li>
                  <p class="sl">{{ item.saleAmount }}</p>
                </li>
                <li>

                  <p @click="showErrorInfo(item)" :title="item.errorInfo ? item.errorInfo : '缺货原因不明，请联系开票员！'" class="je">
                    <a href="javascript:;">缺货原因</a>
                  </p>
                </li>
              </ul>
              <ul v-if="item.policyId && item.policyId !== '' && item.policyItem.giftType == '1'"
                class="tableList tableList2">
                <li class="spmc">
                  <router-link :to="{
                    path: '/productDetail',
                    query: { productId: item.policyItem.gifttId },
                  }"><span class="tipzp">赠品</span>
                    {{ item.policyItem.giftProductName }}</router-link>
                </li>
                <li>
                  <p class="gg">{{ item.policyItem.giftProductFormat ? item.policyItem.giftProductFormat : '暂无数据' }}</p>
                </li>
                <li>
                  <p class="sccj">{{ item.policyItem.giftProductManufacturer ? item.policyItem.giftProductManufacturer :
                    '暂无数据'
                  }}</p>
                </li>
                <li>
                  <p class="pzwh">暂无数据</p>
                </li>
                <li>
                  <p class="dj">￥{{ item.policyItem.sendNeedPrice }}</p>
                </li>
                <li>
                  <p class="zdj">暂无数据</p>
                </li>
                <li>
                  <p class="sl">{{ item.policyItem.sendNumber ? item.policyItem.sendNumber : "暂无数据" }}</p>
                </li>
                <li>
                  <p class="je">
                    {{
                      item.policyItem.sendNeedMoney ? "￥" + item.policyItem.sendNeedMoney : "暂无数据"
                    }}
                  </p>
                </li>
              </ul>
              <ul v-else-if="item.policyId && item.policyId !== '' && item.policyItem.giftType == '2'"
                class="tableList tableList2">
                <li class="spmc">
                  <a href="javascript:;"><span class="tipzp">赠品</span>
                    {{ item.policyItem.giftName }}</a>
                </li>
                <li>
                  <p class="gg">{{ item.policyItem.giftFormat ? item.policyItem.giftFormat : '暂无数据' }}</p>
                </li>
                <li>
                  <p class="sccj">{{ item.policyItem.giftManufacturer ? item.policyItem.giftManufacturer : '暂无数据' }}
                  </p>
                </li>
                <li>
                  <p class="pzwh">暂无数据</p>
                </li>
                <li>
                  <p class="dj">￥{{ item.policyItem.sendNeedPrice }}</p>
                </li>
                <li>
                  <p class="zdj">暂无数据</p>
                </li>
                <li>
                  <p class="sl">{{ item.policyItem.sendNumber ? item.policyItem.sendNumber : "暂无数据" }}</p>
                </li>
                <li>
                  <p class="je">
                    {{
                      item.policyItem.sendNeedMoney ? "￥" + item.policyItem.sendNeedMoney : "暂无数据"
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>

        </div>

        <div class="cartBottom">
          <div class="cartData">
            <div class="dataLeft">
              <div v-if="shoppingCartType == 2 && saleOrder.optCancelFlag === '1'" @click="gobackShoppingCart"
                class="yunfeiText">
                <img src="../assets/images/order/backShopping.png" alt="" />
                返回清单列表修改
              </div>
              <div v-else-if="saleOrder.optCancelFlag === '1'" @click="gobackShoppingCart" class="yunfeiText">
                <img src="../assets/images/order/backShopping.png" alt="" />
                返回购物车修改
              </div>
              <div class="shopData">
                <span v-if="saleOrderItemList.length > 0">可提交商品<i>{{ saleOrderItemList.length }}</i>件</span>
                <span v-if="saleOrderItemOosList.length > 0">缺货商品<i>{{ saleOrderItemOosList.length }}</i>件</span>
              </div>
            </div>
            <div v-loading="confirmOrderStatus" disabled="confirmOrderStatus" @click="confirmOrderAction"
              class="createOrderBtn">
              确认订单
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="createOrderContent container_box">
      <div class="liucheng"></div>
      <div class="orderTip">
        <i class="successIcon"></i>
        <span>您的订单提交成功，请等待配货</span>
      </div>
      <div class="linkUrlBox">
        <router-link to="/" class="backIndex">
          返回首页
        </router-link>
        <router-link to="/orderList?state=3" class="linkOrderList">
          查看订单
        </router-link>
      </div>
    </div>
    <!-- <ticket-modal ref="ticketModal"></ticket-modal> -->
  </div>
</template>

<script>
import { getAction, postAction } from "../api/manage";
import { accAdd } from "../assets/js/common/common";
export default {
  name: "ConfirmOrder",
  data() {
    return {
      saleOrderId: "",
      interestMoney: 0, // 优惠的金额
      saleOrder: {}, // 订单头部数据
      customerAddress: {}, // 订单头部数据
      saleOrderItemList: [], //创建的产品列表数据
      saleOrderItemOosList: [], // 缺货列表的数据
      ticketMoneyFlag: false,
      ticketMoney: 0,
      confirmOrderStatus: false,
      orderSuccess: false,
      discountTicketList: [],
      shopLoading: false,
      shoppingCartType: 0, // 添加购物车的类型
    };
  },
  created() {
    let saleOrderId = this.$route.query.saleOrderId;
    let shoppingCartType = this.$route.query.shoppingCartType;
    if (shoppingCartType && shoppingCartType !== "") {
      this.shoppingCartType = parseInt(shoppingCartType);
    }
    this.saleOrderId = saleOrderId;
    if (saleOrderId) {
      this.getCustomerBalance();
      this.getConfirmOrderData();
    }
  },
  computed: {
    accmulPayorderMoney() {
      let originalSaleMoney = this.saleOrder.originalSaleMoney
        ? this.saleOrder.originalSaleMoney
        : 0;
      let confirmDiscountMoney = this.saleOrder.confirmDiscountMoney
        ? this.saleOrder.confirmDiscountMoney
        : 0;
      let oosMoney = this.saleOrder.oosMoney ? this.saleOrder.oosMoney : 0;
      let ticketMoney =
        this.ticketMoneyFlag && this.ticketMoney ? this.ticketMoney : 0;
      let logisticsMoney = this.saleOrder.logisticsMoney
        ? this.saleOrder.logisticsMoney
        : 0;
      let plusMoney = accAdd(originalSaleMoney, logisticsMoney);
      let lessMoney = accAdd(confirmDiscountMoney, oosMoney);
      let lessMoney2 = accAdd(lessMoney, ticketMoney);
      let payOrderMoney = accAdd(plusMoney, -lessMoney2);
      if (payOrderMoney > 0) {
        return payOrderMoney;
      } else {
        return "0.00";
      }
    },
    renderTicketMoney() {
      let originalSaleMoney = this.saleOrder.originalSaleMoney
        ? this.saleOrder.originalSaleMoney
        : 0;
      let confirmDiscountMoney = this.saleOrder.confirmDiscountMoney
        ? this.saleOrder.confirmDiscountMoney
        : 0;
      let oosMoney = this.saleOrder.oosMoney ? this.saleOrder.oosMoney : 0;
      let ticketMoney =
        this.ticketMoneyFlag && this.ticketMoney ? this.ticketMoney : 0;
      let logisticsMoney = this.saleOrder.logisticsMoney
        ? this.saleOrder.logisticsMoney
        : 0;
      let plusMoney = accAdd(originalSaleMoney, logisticsMoney);
      let lessMoney = accAdd(confirmDiscountMoney, oosMoney);
      let lessMoney2 = accAdd(lessMoney, ticketMoney);
      let payOrderMoney = accAdd(plusMoney, -lessMoney2); // 查看药券
      let ticketShowMoney = accAdd(plusMoney, -lessMoney); // 查看药券
      if (payOrderMoney > 0) {
        return ticketMoney;
      } else {
        return ticketShowMoney;
      }
    },
    originalOosMoney() {
      let originalSaleMoney = this.saleOrder.originalSaleMoney
        ? this.saleOrder.originalSaleMoney
        : 0;
      let originalConfirmMoney = this.saleOrder.originalConfirmMoney
        ? this.saleOrder.originalConfirmMoney
        : 0;
      let originalOosMoney = accAdd(originalSaleMoney, -originalConfirmMoney);
      return originalOosMoney;
    },
  },
  methods: {
    linkProDetail(item) {
      this.$router.push({ path: 'productDetail', query: { productId: item.productId } })
    },
    showCxInfo(cxInfo) {
      this.$alert(cxInfo ? cxInfo : '', "促销信息", {
        type: "info",
      });
    },
    // 展示商品缺货原因
    showErrorInfo(item) {
      this.$alert(item.errorInfo ? item.errorInfo : '缺货原因不明，请联系开票员！', "缺货原因", {
        type: "error",
      });
    },
    // 获取产品批次效期的数据
    lookProductBatchData(item) {
      let url = "/saleOrder/saleOrderItemBatch/listBySaleOrderItemId";
      let data = {
        saleOrderItemId: item.id,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          if (resData && resData.length > 0) {
            let batchData = res.result[0];
            let productBatchData = `批次：${batchData.batchId}<br>批号：${batchData.batchNumber}<br>生产日期：${batchData.productDate}<br>有效期：${batchData.expireDate}`;
            this.$alert(productBatchData, "产品批次信息：", {
              dangerouslyUseHTMLString: true,
            });
          } else {
            this.$alert("暂无批次效期数据！", "产品批次信息：", {
              type: "error",
            });
          }
        }
      });
    },
    // 获取用户的余额和药券数据
    getCustomerBalance() {
      let _this = this;
      let url = "/balance/customerBalance/query";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          console.log("res", res);
          let resData = res.result;
          _this.ticketMoney = resData ? resData.ticketMoney : 0;
        }
      });
    },
    // 获取确认订单数据
    getConfirmOrderData() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      let url = "/saleOrder/saleOrder/querySaleOrder";
      let data = {
        saleOrderId: saleOrderId,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          that.saleOrder = resData.saleOrder;
          that.customerAddress = resData.customerAddress;
          that.saleOrderItemList = resData.saleOrderItemList;
          that.saleOrderItemOosList = resData.saleOrderItemOosList;
          that.discountTicketList = resData.discountTicketList;
        }
      });
    },
    // 确认订单操作
    linkConfirmOrder(item) {
      let saleOrderId = item.id;
      this.$router.push({
        path: "/confirmOrder",
        query: { saleOrderId: saleOrderId },
      });
    },
    // 确定订单的操作
    confirmOrderAction() {
      let that = this;
      let saleOrderId = this.saleOrder.id;
      let ticketMoneyFlag = this.ticketMoneyFlag;
      let data = {
        saleOrderId: saleOrderId,
      };
      if (ticketMoneyFlag) {
        data.ticketMoneyFlag = 1;
      } else {
        data.ticketMoneyFlag = 0;
      }
      this.confirmOrderStatus = true;
      let url = "/saleOrder/saleOrder/confirmSaleOrder";
      postAction(url, data, true).then((res) => {
        that.confirmOrderStatus = false;
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let saleOrder = resData.saleOrder;
          that.orderNextFlowRouter(saleOrder)
        } else {
          that.$alert(res.message, "确认订单失败：");
        }
      });
    },

    orderNextFlowRouter(saleOrder) {
      let _this = this;
      // 获取订单id，轮询请求订单配货状态
      // 判断订单下一节点状态
      let nextFlow = saleOrder.nextFlow;
      let saleOrderId = saleOrder.id;
      let payOrderId = saleOrder.payOrderId;
      // 判断订单审核状态
      if (nextFlow && nextFlow !== '') {
        if (nextFlow === 'audit') { // 去审核
          _this.$router.push({
            path: '/orderList',
            query: { state: 2 }
          })
        } else if (nextFlow === 'allot') { // 获取配货状态
          _this.$router.push({
            path: "/createOrder",
            query: { saleOrderId: saleOrderId },
          });
        } else if (nextFlow === 'confirm') { // 去确认订单
          // 创建订单成功后跳转确认订单页面
          _this.$router.push({
            name: "confirmOrder",
            query: {
              saleOrderId: saleOrderId,
            },
          });
        } else if (nextFlow === 'pay') { // 去支付订单
          // 线上支付
          _this.$router.push({
            path: "/orderPay",
            query: { payOrderId: payOrderId, saleOrderId: saleOrderId },
          });
        }
      } else {
        _this.$router.push({
          path: '/orderList',
          query: { state: 3 }
        })
      }
    },
    // 跳转支付操作
    linkPayOrder(item) { },
    // 返回购物车修改
    gobackShoppingCart() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      this.shopLoading = true;
      // 1.先取消订单
      let url =
        "/saleOrder/saleOrder/cancelSaleOrder?saleOrderId=" + saleOrderId;
      postAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          // 2.返回购物车修改
          that.jionShoppingCart();
        } else {
          this.shopLoading = false;
          that.$message({
            message: res.message,
            offset: 400,
            duration: 3000,
            type: "error",
          });
        }
      });
    },
    // 批量加入购物车操作
    jionShoppingCart() {
      let _this = this;
      let saleOrderItemList = this.saleOrderItemList;
      let shoppingCartType = _this.shoppingCartType;
      let jionProList = [];
      for (let key of saleOrderItemList) {
        let data = {
          uid: key.inventoryUid,
          addPrice: key.confirmMoney,
          amount: key.confirmAmount,
        };
        if (shoppingCartType == 2) {
          data.shoppingCartType = 2;
        }
        jionProList.push(data);
      }
      let url = "/shopping/cart/addList";
      postAction(url, jionProList).then((res) => {
        this.shopLoading = false;
        if (res.code === 200 && res.success) {
          if (shoppingCartType == 2) {
            _this.$router.push("/fastShopping");
          } else {
            _this.$router.push("/shoppingCart");
          }
        }
      });
    },
  },
  // mounted() {
  //   this.$refs.ticketModal.open()
  // }
};
</script>

<style lang="scss" scoped>
.orderDetailBox {
  @include clear;
  margin-top: 10px;
  margin-bottom: 25px;
}

@import "../assets/styles/order/left";
@import "../assets/styles/order/right";

.cartBottom {
  width: 930px;
  height: 55px;
  background: #f3f3f3;
  @include clear;
  padding: 0 20px;

  .actLeft,
  .payTypeBox {
    float: left;
    line-height: 55px;
  }

  .actLeft {
    a {
      &:first-child {
        &::after {
          content: "|";
          margin: 0 15px;
          color: $font-color;
        }
      }
    }
  }

  .payTypeBox {
    margin-left: 15px;

    label {
      margin-right: 15px;
    }
  }

  .cartData {
    width: 475px !important;
    float: right;
    height: 55px;
    position: relative;

    .dataLeft,
    .createOrderBtn {
      float: left;
    }

    .dataLeft {
      width: 350px;
      font-size: 13px;
      color: $font-color;
      position: relative;
      height: 55px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 5px;

      .yunfeiText,
      .shopData {
        text-align: right;
        width: 100%;
        float: left;
      }

      .shopData {
        line-height: 20px;

        i {
          font-style: normal;
          font-size: 16px;
          color: $act-color;
          margin: 0 5px;
        }
      }

      .yunfeiText {
        line-height: 20px;
        margin-top: 7px;
        cursor: pointer;

        &:hover {
          color: $act-color;
          text-decoration: underline;
        }

        img {
          margin-top: -5px;
          vertical-align: middle;
        }

        span {
          font-style: normal;
          font-size: 16px;
          color: $act-color;
          margin-left: 5px;
        }
      }
    }

    .createOrderBtn {
      width: 100px;
      height: 40px;
      font-size: 16px;
      text-align: center;
      line-height: 40px;
      color: #ffffff;
      cursor: pointer;
      margin: 8px 0px;
      margin-left: 20px;
      background: url("~@/assets/images/shoppingCart/jiesuanBtnBg.png") no-repeat 100%;
    }
  }
}

.createOrderContent {
  width: 1223px;
  height: 358px;
  position: relative;
  background: url("~@/assets/images/order/create/createBg.png") no-repeat center;
  background-size: 100% 100%;

  .liucheng {
    position: absolute;
    left: 50%;
    top: 65px;
    transform: translate(-50%);
    width: 462px;
    height: 48px;
    margin: 39px auto;
    background: url("~@/assets/images/order/create/liucheng.png") no-repeat center;
  }

  .orderTip {
    font-size: 16px;
    color: $base-color;
    text-align: center;
    position: absolute;
    top: 225px;
    width: 100%;

    i {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: 12px;
      background: url("~@/assets/images/order/create/successIcon.png") no-repeat center;
    }

    i,
    span {
      vertical-align: middle;
    }

    span {
      letter-spacing: 1px;
      text-indent: 1px;
    }
  }

  .linkUrlBox {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 13px;
    bottom: 70px;

    a {
      color: $font-color;
      padding-left: 20px;

      &:hover {
        color: $base-color;
        text-decoration: underline;
      }
    }

    .backIndex {
      margin-right: 10px;
      background: url("~@/assets/images/order/create/backIndexIcon.png") no-repeat left center;
    }

    .linkOrderList {
      background: url("~@/assets/images/order/create/linkListIcon.png") no-repeat left center;
    }
  }
}
</style>