<template>
  <div class="product-content">
    <div class="left-nav">
      <img src="../../assets/images/activity/yabang/brand-title-bg.png" alt="" class="box-header">

      <div class="recommend">
        <h3 class="title">推荐厂家</h3>
        <div class="brand-item" v-for="(item, index) in recommendBrand" :key="index">
          <div @click="searchBrandId(item)" class="brand-box" :class="{ 'selected-brand': item.id === selectedBrandId }">
            <img :src="item.title_url" alt="">
          </div>
          <span class="sort">{{ index + 1 }}</span>
        </div>
      </div>
    </div>
    <div class="product-box">
      <meeting-product @loadingOver="loadingOver" ref="productList" />
    </div>
  </div>
</template>

<script>
import { getAction } from '../../api/manage'
import MeetingProduct from './MeetingProduct.vue'
export default {
  components: { MeetingProduct },
  name: 'BrandProductArea',
  data() {
    return {
      brandId: '', // 品牌编码
      recommendBrand: [],
      productLoading: true,
      productList: [],
      selectedBrandId: '', // 选中的品牌ID为
    }
  },
  created() {
    this.getBrandList();
  },
  methods: {
    loadingOver(loadingStatus) {
      this.productLoading = loadingStatus
    },
    async getBrandList() {
      let url = '/yushou/yushou/getFb'
      let res = await getAction(url);
      console.log("获取当前副厂品牌数据", res);
      if (res.code == 200 && res.success) {
        this.recommendBrand = res.result;
      }
    },
    searchBrandId(item) {
      let _this = this;
      this.$util.debounce(() => {
        let productLoading = _this.productLoading;
        if (!productLoading) {
          if (_this.selectedBrandId == item.id) {
            _this.selectedBrandId = ''
          } else {
            _this.selectedBrandId = item.id
          }
          _this.$emit('clearSearchInput')
          _this.$refs.productList.searchBrandId(_this.selectedBrandId)
        }
      }, 500)
    },
    searchProduct(searchVal) {
      console.log("副厂产品搜索++++++", searchVal)
      this.$refs.productList.searchNotBrandProduct(searchVal);
      this.getBrandList()
    }
  }
}
</script>

<style lang="scss" scoped>
.product-content {
  min-width: 1188px;
  @include clear;

  .left-nav {
    width: 200px;
    min-height: 1145px;
    float: left;
    position: relative;

    .box-header {
      width: 200px;
      height: 86px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .recommend {
      margin: 65px auto;
      width: 165px;
      min-height: 1145px;
      background: #CB2027;
      border: 3px solid #FFD69B;
      box-shadow: 0px 0px 24px 0px rgba(163, 6, 6, 0.82);
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .title {
        font-size: 28px;
        font-weight: 500;
        color: #FFE9BA;
        letter-spacing: 2px;
        text-indent: 2px;
        margin-top: 35px;
      }

      .brand-item {
        text-align: center;
        margin-top: 10px;
        cursor: pointer;

        .brand-box {
          width: 129px;
          height: 129px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;

          &.selected-brand {
            border: 4px solid #FFAB31;
            border-radius: 5px;
          }

          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }

        .sort {
          display: inline-block;
          width: 21px;
          height: 21px;
          background: #FFE9BB;
          border-radius: 50%;
          font-size: 16px;
          color: #A90505;
          text-align: center;
          line-height: 21px;
          margin: 10px auto;
        }
      }
    }
  }

  .product-box {
    width: 980px;
    float: right;
    overflow: hidden;
  }
}
</style>