<template>
  <div class="loginContent container">
    <div class="loginHeader container">
      <div class="container_box">
        <div class="logo">
          <router-link to="/index">
            <img v-if="companyConfigData && companyConfigData.pcDarkLogoUrl && companyConfigData.pcDarkLogoUrl !== ''"
              :src="companyConfigData.pcDarkLogoUrl" alt="江药商城" />
            <img v-else src="@/assets/images/common/logo.png" alt="江药商城" />
          </router-link>
        </div>
        <div class="telBox">
          <img src="../assets/images/login/tel.png" alt="" />
        </div>
      </div>
    </div>
    <div class="loginMain container">
      <div class="container_box">
        <div class="liuchengBox">
          <div class="liucheng liucheng1">
            <p>验证身份</p>
            <p :class="{ flowTrue: changeFlow >= 1 }" class="circle">1</p>
          </div>
          <div class="liucheng liucheng2">
            <p>重置密码</p>
            <p :class="{ flowTrue: changeFlow >= 2 }" class="circle">2</p>
          </div>
          <div class="liucheng liucheng3">
            <p>重置成功</p>
            <p :class="{ flowTrue: changeFlow >= 3 }" class="circle">3</p>
          </div>
        </div>
        <!-- 验证身份 -->
        <div v-if="changeFlow == 1" class="changeFlowPwdBox">
          <div class="phoneNumberInput">
            <img class="phoneIcon" src="../assets/images/login/mobliePhone.png" alt="" />
            <input placeholder="请填写绑定的手机号" class="phoneNumber" oninput="value=value.replace(/[^0-9.]/g,'')" type="text" />
          </div>
          <div class="verifyCodeBox">
            <div class="verifyCode">
              <img class="phoneIcon" src="../assets/images/login/msgIcon.png" alt="" />
              <input placeholder="短信验证码" type="text" />
            </div>
            <button :disabled="verfiyState ? true : false" @click="phoneVerify" class="getVerfiyCodeBtn">
              {{ verfiyState ? `${timeNumber}S再获取` : "发送验证码" }}
            </button>
          </div>

          <div @click="changeFlowClick(2)" :class="{ changeLoading: changeLoading === true }" class="loginBtn">
            <i v-if="changeLoading" class="el-icon-loading"></i>
            下一步
          </div>
        </div>
        <!-- 重置密码 -->
        <div v-else-if="changeFlow == 2" class="changeFlowPwdBox">
          <div class="passwordInput">
            <img class="passwordIcon" src="../assets/images/login/passwordIcon.png" alt="" />
            <input class="password" ref="password" placeholder="请输入新密码" :type="changeShowPwd ? 'text' : 'password'" />
            <div class="changeShowPwd" v-if="!hasIeBrowser">
              <img @click="changeShowPwd = true" title="显示密码" v-if="!changeShowPwd"
                src="../assets/images/login/closePwd.png" alt="显示密码" />
              <img @click="changeShowPwd = false" title="隐藏密码" v-else src="../assets/images/login/openPwd.png"
                alt="隐藏密码" />
            </div>
          </div>

          <div class="passwordInput">
            <img class="passwordIcon" src="../assets/images/login/confirmPwd.png" alt="" />
            <input class="password" ref="password" placeholder="请再次输入新密码" :type="changeShowPwd ? 'text' : 'password'" />
            <div class="changeShowPwd" v-if="!hasIeBrowser">
              <img @click="changeShowPwd = true" title="显示密码" v-if="!changeShowPwd"
                src="../assets/images/login/closePwd.png" alt="显示密码" />
              <img @click="changeShowPwd = false" title="隐藏密码" v-else src="../assets/images/login/openPwd.png"
                alt="隐藏密码" />
            </div>
          </div>

          <div @click="changeFlowClick(3)" :class="{ changeLoading: changeLoading === true }" class="loginBtn">
            <i v-if="changeLoading" class="el-icon-loading"></i>
            提交
          </div>
        </div>
        <div v-else-if="changeFlow == 3" class="changeFlowPwdBox success">
          <img class="successImg" src="../assets/images/login/pwdSuccess.png" alt="" />
          <p class="successP">已成功重置密码，马上为您登录</p>
          <p class="successP">若没有跳转，请点击<router-link to="/login">登录</router-link></p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../components/common/Footer.vue";
export default {
  components: { Footer },
  name: "ForgetPwd",
  data() {
    return {
      verfiyState: false,
      timeNumber: 60,
      changeLoading: false,
      changeFlow: 1,
      changeShowPwd: true,
      changeShowPwd2: true,
      hasIeBrowser: false,
    };
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
  },
  methods: {
    // 手机登录发送验证码操作
    phoneVerify() {
      let _this = this;
      this.verfiyState = true;
      let timeNumber = this.timeNumber;
      let interVal = setInterval(function () {
        timeNumber--;
        _this.timeNumber = timeNumber;
        if (timeNumber == 0) {
          clearInterval(interVal);
          _this.timeNumber = 60;
          _this.verfiyState = false;
        }
      }, 1000);
    },
    // 忘记密码下一步操作
    changeFlowClick(index) {
      let _this = this;
      this.changeLoading = true;
      setTimeout(function () {
        _this.changeFlow = index;
        _this.changeLoading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/login/forgetPwd";
</style>