<template>
  <div class="productListApp container_box">
    <classify v-if="false"></classify>
    <div v-if="
      newCustomerPriceFlag
      && newCustomerPriceFlag !== ''
      && productList.length > 0
      && productList[0].inventoryList
      && productList[0].inventoryList.length > 0
      && productList[0].inventoryList[0].newCustomerPriceFlag !== '1'" class="errorTip">
      <p class="tipText">您不符合活动条件，不能参与该活动哦！</p>
      <span class="backIndexBtn">
        <router-link to="/">返回首页</router-link>
      </span>
    </div>
    <div v-else>
      <div class="proTable">
        <div v-if="productList.length === 0 && !productLoading" class="dataNone container_box">
          <div class="dataImg productNoneImg"></div>
        </div>
        <div v-else v-loading="productLoading" class="cartMain">
          <div class="productListBox">
            <activity-img-pro-list :productList="productList"> </activity-img-pro-list>
          </div>
        </div>
      </div>
      <div class="pageFooter">
        <el-pagination background v-if="totalCount > 0" :hide-on-single-page="true"
          layout="prev,sizes, pager, next, jumper" :current-page="pageNo" @current-change="currentChange"
          :page-sizes="[20, 30, 40, 50]" @size-change="handleSizeChange" :page-size="pageSize" :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getAction } from "../../api/manage";
import merge from 'webpack-merge'
import Vue from 'vue';
import ActivityImgProList from './ActivityImgProList.vue';
export default {
  components: {ActivityImgProList},
  props: ["searchName"],
  name: "ActivityGoods",
  data() {
    return {
      pageNo: 1,
      pages: 1,
      pageSize: 20,
      totalCount: 1,
      searchVal: "",
      collectFlag: "",
      productShowType: '1',
      productLoading: true,
      productList: [],
      manufacturer: "",
      hasStocket: false, // 只展示有库存的
      maxPrice: "",
      minPrice: "",
      showRangeStatus: false,
      screenList: ["默认排序", "销量", "价格", "人气"],
      screenType: 0, // 4:销量 2:价格 3:人气
      sortType: 1, // 1:升序 2:降序
      sortTypeText: "由低到高", // 排序提示
      productCategory: "", // 产品分类
      nearDateFlag: "", // 产品分类
      productLabelList: "", // 产品标签数据
      newCustomerPriceFlag: "", // 搜索指定新人特价产品
      discountTicketId: "", // 搜索指定优惠券的产品数据
    };
  },
  created() {
    let productShowType = Vue.ls.get('productShowType');
    if (productShowType && productShowType !== '') {
      this.productShowType = productShowType
    } else {
      this.productShowType = '1'
    }
    // 检测平台设置的默认产品列表展示模式
    let searchVal = this.$route.query.searchVal;
    let manufacturer = this.$route.query.manufacturer;
    let productCategory = this.$route.query.productCategory;
    let nearDateFlag = this.$route.query.nearDateFlag;
    let collectFlag = this.$route.query.collectFlag;
    let productLabelList = this.$route.query.productLabelList;
    let newCustomerPriceFlag = this.$route.query.newCustomerPriceFlag;
    let discountTicketId = this.$route.query.discountTicketId;
    this.manufacturer = manufacturer;
    this.productCategory = productCategory;
    this.nearDateFlag = nearDateFlag;
    this.newCustomerPriceFlag = newCustomerPriceFlag;
    this.discountTicketId = discountTicketId;
    this.productLabelList = productLabelList;
    this.searchVal = searchVal;
    this.collectFlag = collectFlag;
    this.getProductListData();
  },
  methods: {
    changeProductListType() {
      let productShowType = this.productShowType;
      if (productShowType == '1') {
        this.productShowType = '2'
      } else {
        this.productShowType = '1'
      }
      // 记录用户选择的列表展示模式
      this.$ls.set('productShowType', this.productShowType)
    },
    inputBlur() {
      let minPrice = this.minPrice;
      let maxPrice = this.maxPrice;
      if (minPrice == '' && maxPrice == '') {
        this.showRangeStatus = false
      }
    },
    // 价格区间查询
    priceSearch() {
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 清空价格区间筛选
    clearPriceNum() {
      this.minPrice = "";
      this.maxPrice = "";
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
    // 选择商品筛选排序条件
    selectScreen(item, index) {
      let sortType = this.sortType;
      let screenType = this.screenType;
      // 人气默认由高到低
      if (screenType == index && index > 0) {
        if (sortType == 1) {
          this.sortTypeText = "由高到低";
          this.sortType = 2;
        } else {
          this.sortTypeText = "由低到高";
          this.sortType = 1;
        }
      } else if (screenType !== index && index > 0) {
        // 初始人气、销量默认从高到低
        if (index == 3 || index == 1) {
          this.sortType = 2;
          this.sortTypeText = "由高到低";
        } else {
          this.sortType = 1;
          this.sortTypeText = "由低到高";
        }
      } else {
        this.sortType = 1;
        this.sortTypeText = "";
      }
      this.screenType = index;
      this.getProductListData();
    },
    // 获取产品列表数据
    getProductListData() {
      this.backTop();
      let _this = this;
      this.productLoading = true;
      let productCategory = this.productCategory;
      let productLabelList = this.productLabelList;
      let newCustomerPriceFlag = this.newCustomerPriceFlag;
      let discountTicketId = this.discountTicketId;
      let nearDateFlag = this.nearDateFlag;
      let manufacturer = this.manufacturer;
      let searchVal = this.searchVal;
      let collectFlag = this.collectFlag;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let data = {
        searchVal: searchVal,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (newCustomerPriceFlag && newCustomerPriceFlag !== "" && (!searchVal || searchVal == "")) {
        data.newCustomerPriceFlag = newCustomerPriceFlag;
      }
      if (discountTicketId && discountTicketId !== "" && (!searchVal || searchVal == "")) {
        data.discountTicketId = discountTicketId;
      }
      if (productCategory && productCategory !== "") {
        data.productCategory = productCategory;
      }
      if (nearDateFlag && nearDateFlag !== "" && (!searchVal || searchVal == "")) {
        data.nearDateFlag = nearDateFlag;
      }
      if (manufacturer && manufacturer !== "") {
        data.manufacturer = manufacturer;
      }
      if (productLabelList && productLabelList !== "") {
        data.productLabelList = productLabelList;
      }
      if (collectFlag && collectFlag !== "") {
        data.collectFlag = collectFlag;
      }
      // 判断筛选条件
      // 1. 判断是否只显示有库存的产品数据
      let hasStocket = this.hasStocket;
      if (hasStocket) {
        data["productScreenBO.isInventory"] = 1;
      } else {
        data["productScreenBO.isInventory"] = 0;
      }
      // 2.判断价格区间
      let maxPrice = this.maxPrice;
      let minPrice = this.minPrice;
      if (maxPrice !== "") {
        data["productScreenBO.maxPrice"] = maxPrice;
      }
      if (minPrice !== "") {
        data["productScreenBO.minPrice"] = minPrice;
      }
      // 判断筛选条件和升降序
      let screenType = this.screenType;
      let sortType = this.sortType;
      // screenType: 4: 销量 2:价格 3.人气
      // sortType: 1: 升序  2.降序
      if (screenType !== "" && screenType > 0) {
        if (screenType == 1) {
          data["productScreenBO.screenType"] = 4;
        } else {
          data["productScreenBO.screenType"] = screenType;
        }
        data["productScreenBO.sortType"] = sortType;
      }
      let url = "/product/getlistE";
      console.log("请求列表的数据为+++++++", data)
      getAction(url, data, true)
        .then((res) => {
          _this.productLoading = false;
          if (res.code === 200 && res.success) {
            let pageData = res.result.pageData;
            _this.productList = pageData.records;
            _this.pageNo = pageData.current;
            _this.pages = pageData.pages;
            _this.totalCount = pageData.total;
            let productList = this.productList;
            if (newCustomerPriceFlag
              && newCustomerPriceFlag !== ''
              && productList.length > 0
              && productList[0].inventoryList
              && productList[0].inventoryList.length > 0
              && productList[0].inventoryList[0].newCustomerPriceFlag !== '1') {
              // 5秒后跳转首页
              setTimeout(function () {
                _this.$router.push('/')
              }, 5000)
            }
          } else {
            _this.productList = [];
            _this.productLoading = false;
          }
        })
        .catch((error) => {
          _this.productList = [];
          _this.productLoading = false;
        });
    },
    // 搜索产品的操作
    searchProduct(searchName,labelFlag) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.pageNo = 1;
      let productCategory = this.$route.query.productCategory;
      if (productCategory && productCategory !== '') {
        delete this.$route.query.productCategory
      }
      let productLabelList = this.$route.query.productLabelList;
      if (!labelFlag && productLabelList && productLabelList !== '') {
        delete this.$route.query.productLabelList
      }
      console.log("当前搜索的数据为this.$route.query", this.$route.query)
      console.log("当前搜索的数据为", searchName)
      console.log("this.$router", this.$router)
      this.$router.push({
        query: merge(this.$route.query, { 'searchVal': searchName,t: Date.now() })
      })
      // todo 这里解开会导致搜索会请求两次
      // this.getProductListData();
    },
    // 搜索收藏夹产品的操作
    searchCollectProduct(searchName) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.collectFlag = 1;
      this.pageNo = 1;
      this.getProductListData();
    },
    searchProductCategory(productCategory) {
      this.clearSearchUrlData();
      this.productCategory = productCategory;
      this.getProductListData();
    },
    clearSearchUrlData() {
      this.productCategory = "";
      this.productLabelList = "";
      this.manufacturer = "";
      this.collectFlag = "";
      this.searchVal = "";
      this.newCustomerPriceFlag = "";
      this.discountTicketId = "";
      this.pageNo = 1;
    },
    currentChange(val) {
      this.pageNo = val;
      this.getProductListData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getProductListData();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/product/product";
</style>