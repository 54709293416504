<template>
  <div class="addressListBox">
    <div class="addTitle">
      <h3>关联的客户列表</h3>

      <div class="searchBox clearfix">
        <el-input class="searchInputBox fl" size="mini" @change="getCustomerList" v-model="searchVal"
          placeholder="请输入客户名称">
        </el-input>
        <el-button @click="getCustomerList" class="searchBtn fr">搜索</el-button>
      </div>
    </div>
    <div class="addressListContent">
      <div class="addressItem" v-for="(item, index) of customerList" :key="'address' + index">
        <p class="userListItem">
          {{ item.id }}{{ item.name ? ` / ${item.name}` : ""
          }}{{ item.linkman ? ` / ${item.linkman}` : "" }}
        </p>
        <div v-if="item.errorInfo && item.errorInfo !== ''" class="operation">
          <span class="stopA"> 账户异常 </span>
          <a @click="showErrorInfo(item.errorInfo)" class="changeUser" href="javascript:;"> 异常原因 </a>
        </div>
        <div v-else class="operation">
          <span v-if="item.erpStatus == '1'" class="successA"> 启用中 </span>
          <span v-else class="stopA"> 已停用 </span>
          <a v-if="item.erpStatus == '1' && item.id !== userInfo.id" @click="changeNowUser(item)" class="changeUser" href="javascript:;">
            切换用户
          </a>
        </div>
      </div>
    </div>


    <div class="pageFooter">
      <el-pagination background :hide-on-single-page="true" v-if="totalCount > 10" layout="prev, pager, next, jumper"
        :current-page="pageNo" @current-change="currentChange" :page-size="pageSize" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";
import { mapGetters } from "vuex";
import { getUserInfo } from "@/assets/js/common/common";
import { SHOPPING_ACCESS_TOKEN, USER_INFO } from "../store/mutation-types";
export default {
  data() {
    return {
      customerList: [],
      nowPageShowList: [],
      searchVal: "",
      pageNo: 1,
      pageSize: 15,
      pages: 1,
      totalCount: 0,
      userInfo: null,
    };
  },
  created() {
    let userInfo = getUserInfo();
    if (userInfo) {
      this.userInfo = userInfo;
    }
    this.getCustomerList();
  },
  computed: {
    ...mapGetters(["salemanToken"]),
  },
  methods: {
    // 点击切换页面操作
    currentChange(val) {
      this.pageNo = val;
      this.getCustomerList()
    },
    // 展示客户异常原因
    showErrorInfo(errorInfo) {
      this.$alert(errorInfo);
    },
    // 切换至改用户操作
    changeNowUser(item) {
      // 1.改变当前所有用户信息
      let loginToken = item.token;
      let userInfo = item;
      let encodeUserInfo = this.$base64.encode(JSON.stringify(userInfo));
      this.$ls.remove(USER_INFO);
      this.$ls.remove(SHOPPING_ACCESS_TOKEN);
      this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
      let loginTokenStr = this.$base64.encode(loginToken);
      this.$ls.set(SHOPPING_ACCESS_TOKEN, loginTokenStr);
      this.$store.commit("SET_TOKEN", loginToken);
      this.$store.commit("SET_INFO", userInfo);
      this.$router.push("/index");
    },
    async getCustomerList() {
      let url = "/customer/userCustomerMap/list";
      let salemanToken = this.salemanToken;
      let searchVal = this.searchVal.trim();
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      console.log("当前业务员的token为++++++", salemanToken);
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
        userToken: salemanToken,
        customerName: searchVal,
      };
      let res = await getAction(url, data);
      console.log("业务员关联的客商列表为+++++++", res);
      if (res.code == 200 && res.success) {
        let resData = res.result;
        this.customerList = resData.records;
        this.pageNo = resData.current;
        this.pages = resData.pages;
        this.totalCount = resData.total;
        this.selectUserInfo = 2;
      } else {
        this.$alert(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/center/address";

.addTitle {
  position: relative;
}

.searchBox {
  width: 235px;
  position: absolute;
  right: 12px;
  top: 5px;

  .searchVal {
    margin-left: 20px;
  }

  .searchInputBox {
    position: relative;
    width: 195px;
    height: 25px;
  }

  ::v-deep .el-input__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 195px;
    line-height: 25px !important;
    height: 25px;
    border: none;
    border-radius: 0;
  }

  .searchBtn {
    width: 40px;
    height: 25px;
    background: $base-color;
    color: #ffffff;
    line-height: 25px;
    text-align: center;
    border-radius: 0px;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.changeUser {
  font-weight: bold;
}

.operation {
  text-align: center;
}

.successA {
  color: $base-color;
}

.stopA {
  color: $act-color;
}

.addressListBox {
  position: relative;
  padding-bottom: 50px;
}

.pageFooter {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>