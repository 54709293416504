<template>
  <div class="headerTopContent container">
    <transition v-if="advertTopData && advertTopData.picUrl" name="el-zoom-in-top">
      <div v-show="sliderStatus" class="headerAdvert">
        <a :href="advertTopData.url" :style="`background-image: url(${advertTopData.picUrl})`" target="_blank"></a>
      </div>
    </transition>
    <div class="headerTopMain container">
      <div class="container_box">
        <div class="userinfoContent">
          <div class="topLeft">
            <div v-if="loginState" class="userinfoMain leftMain">
              <p class="username">{{ userInfo.name }}</p>
              <p class="compantname">
                {{
                    userInfo.companyName && userInfo.companyName
                      ? userInfo.companyName
                      : "暂无所在公司数据"
                }}
              </p>
              <p v-if="salemanToken && salemanToken !== ''" class="compantname">
                {{
                    userInfo.userName && userInfo.userName
                      ? userInfo.userName
                      : "暂无业务员信息"
                }}
              </p>
            </div>
            <div v-else class="loginTipMain leftMain">
              <p class="loginLink">
                <router-link to="/login"> 登录 </router-link>
              </p>
              <p class="register">
                <router-link to="/register">注册</router-link>
              </p>
            </div>
            <div v-if="loginState" class="collectionSite Logout">
              <a @click="Logout" title="退出登录" href="javascript:;">退出</a>
            </div>
            <div class="collectionSite">
              <a href="/resources/jzysc.url" download="江药商城.url">收藏至桌面</a>
            </div>
          </div>
          <div class="topRight">
            <div class="userActContent">
              <div class="userCeneter">
                <router-link to="/myAccount">个人中心</router-link>
              </div>
              <div class="oderLink">
                <router-link to="/articleInfo"> 帮助中心 </router-link>
              </div>
              <div
                v-if="companyConfigData && companyConfigData.appDownloadImgUrl && companyConfigData.appDownloadImgUrl !== ''"
                class="appShowContent">
                <a class="phoneLink" href="javascript:;">
                  <img src="@/assets/images/headerTop/erweimaIcon.png" alt="二维码" />
                  移动端下载
                  <div class="phoneEcode">
                    <img src="../../assets/images/headerTop/appDownload.png" alt="手机端二维码" />
                    <img class="ercode" :src="companyConfigData.appDownloadImgUrl" alt="手机端二维码" />
                  </div>
                </a>
              </div>
              <div
                v-if="companyConfigData && companyConfigData.wechatDownloadImgUrl && companyConfigData.wechatDownloadImgUrl !== ''"
                class="appShowContent">
                <a class="phoneLink" href="javascript:;">
                  <img src="@/assets/images/headerTop/erweimaIcon.png" alt="二维码" />
                  关注我们
                  <div class="phoneEcode">
                    <img src="../../assets/images/headerTop/wechatImg.png" alt="关注我们" />
                    <img class="ercode ercode2" :src="companyConfigData.wechatDownloadImgUrl" alt="关注我们" />
                  </div>
                </a>
              </div>
              <div v-if="companyConfigData.customerServiceTel && companyConfigData.customerServiceTel !== ''"
                class="linkTel">
                <a href="javascript:;">
                  <img src="@/assets/images/headerTop/telIcon.png" alt="客服电话" />
                  {{ companyConfigData.customerServiceTel }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store/";
import { mapActions, mapGetters } from "vuex";
import { getUserInfo } from "../../assets/js/common/common";
import { getAction } from "../../api/manage";
export default {
  name: "HeaderTop",
  data() {
    return {
      ecodeShow: false,
      userName: "",
      companyName: "",
      sliderStatus: true,
      userInfo: null,
      loginState: false,
      advertImg: "",
      advertUrl: "",
    };
  },
  created() {
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
      this.userInfo = userInfo;
    } else {
      this.loginState = false;
    }
    let router = this.$route.path;
    if (router === "/" || router === "/index" || router === "/productList" || router === "/productDetail" || router === "/specialArea") {
      this.getAdertList();
    }
  },
  computed: {
    ...mapGetters(["advertTopData", "salemanToken", "companyConfigData"]),
  },
  methods: {
    ...mapActions(["Logout"]),
    getAdertList() {
      let _this = this;
      let data = {
        type: 1,
      };
      let url = "/homeShow/ad/list";
      getAction(url, data).then((res) => {
        if (
          res.code === 200 &&
          res.success &&
          res.result &&
          res.result.length > 0
        ) {
          let result = res.result;
          _this.$store.commit("ADVERT_TOP_DATA", result[0]);
        } else {
          _this.$store.commit("ADVERT_TOP_DATA", null);
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      let userInfo = getUserInfo();
      if (userInfo) {
        this.loginState = true;
        this.userInfo = userInfo;
      } else {
        this.loginState = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/headerTop";
</style>