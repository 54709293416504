<template>
  <div class="activityBox container_box">
    <!-- 活动当行栏 -->
    <div class="activityContent">
      <a v-for="(item, index) of activityNav" :class="{ active: activeIndex == index }" :key="'navIndex' + index"
        @click="jump(index)" href="javascript:;">
        {{ item.text }}
      </a>
    </div>
    <!-- 奖券列表 -->
    <div id="yhqNav" v-if="ticketDataList.length > 0" class="ticketListContent activityItem">
      <div class="activityTitle">优惠券</div>
      <div class="ticketListMain">
        <div class="ticketDataBox" v-for="(item, index) of ticketDataList" :key="'Rightcart' + index">
          <div class="ticketContent" :class="{
            productTicket: item.astrictProductFlag ==
              1
          }">
            <div class="ticketTop">
              <!-- 满减券名称 -->
              <div v-if="item.ticketType == 1" class="price">
                <p class="typeText">￥</p>
                <p class="money">{{ item.ticketDiscountMoney }}</p>
              </div>
              <!-- 打折券名称 -->
              <div v-else-if="item.ticketType == 2" class="price">
                <p class="money">{{parseFloat((item.ticketDiscountMoney * 10).toPrecision(12)) }}</p>
                <p class="typeText">折</p>
              </div>
              <!-- 优惠券的名称 -->
              <div class="ticketName">
                <p class="name">{{ item.ticketName }}</p>
              </div>
              <div class="ticketTime">
                <p>有效期限：{{ timeClear(item.startTime) }}-{{
                  timeClear(item.endTime)
                }}</p>
              </div>
            </div>
            <div class="ticketBottom">
              <div class="ticketDetail">
                <p class="detail"><span>适用范围:</span>{{ item.ticketDetail }}</p>
              </div>
              <div class="ticketBtnBox">
                <a @click="useTicket(item)" v-if="item.ticketReceiveStatus == 1" class="goUseBtn"
                  :class="{ goUseBtn2: item.astrictProductFlag !== '0' }" href="javascript:;">去使用</a>
                <a v-else-if="item.ticketReceiveStatus == 0" class="ticketOverBtn" href="javascript:;">已领完</a>

                <a v-else class="lingquBtn" :class="{ lingquBtn2: item.astrictProductFlag !== '0' }"
                  @click="lingquTicket(item)" href="javascript:;">领取</a>
              </div>
            </div>
          </div>
          <!-- <div class="ticketLeft">
            <div v-if="item.ticketType == 1" class="price">
              <p class="money">{{ item.ticketDiscountMoney }}</p>
              <p class="typeText">元</p>
            </div>
            <div v-else-if="item.ticketType == 2" class="price">
              <p class="money">{{ (item.ticketDiscountMoney * 10).toFixed(1) }}</p>
              <p class="typeText">折</p>
            </div>
            <div class="ticketData">
              <p class="name">{{ item.ticketName }}</p>
              <p class="date">
                仅{{ timeClear(item.startTime) }}-{{
                  timeClear(item.endTime)
                }}使用
              </p>
            </div>
            <div class="detaill">{{ item.ticketDetail }}</div>
          </div>
          <div class="ticketRight">
            <a
              v-if="item.ticketReceiveStatus == 1"
              class="sanzi"
              href="javascript:;"
              >已领取</a
            >
            <a
              v-if="item.ticketReceiveStatus == 0"
              class="sanzi"
              href="javascript:;"
              >已领完</a
            >
            <a v-else @click="lingquTicket(item)" href="javascript:;"
              >立即领取</a
            >
          </div> -->
        </div>
      </div>
    </div>
    <!-- 限时抢购 -->
    <div id="xsqgNav" v-if="flashSale.list.length > 0" class="xsqgContent activityItem">
      <div v-if="flashSaleDetail.endDate && flashSaleDetail.endTime" class="activityTitle">
        <p>限时抢购</p>
        <CountDown :target="countDownText(flashSaleDetail)" :on-end="onEndHandle">
        </CountDown>
      </div>
      <ul v-loading="flashSale.loading" class="imglistbox">
        <li v-for="(item, index) of flashSale.list"
          :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }"
          v-bind:key="'imgProduct' + index">
          <div>
            <div class="productImgBox">
              <router-link :to="{
                path: '/productDetail',
                query: { productId: item.productId, productActivityPriceStrategyId: item.productActivityPriceStrategyId },
              }" style="display: inline-block" :title="item.productName" target="_blank">
                <img class="productImg" v-lazy="productDataImg(item)" />
              </router-link>
            </div>
          </div>
          <!--产品名称-->
          <div class="img-mingzi">
            {{ item.productName }}
          </div>
          <!-- 秒杀产品显示的价格 -->
          <div v-if="item.price" class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.price }}</p>
            <p v-if="item.originalPrice" class="yjPriceBox fl">
              <i class="priceName">原价:</i>
              ￥{{ item.originalPrice }}
            </p>
          </div>
          <div v-if="loginState && item.inventory > 0" class="tipBox">
            <span class="tip1">秒杀</span>
          </div>
          <div class="tipBox" v-else></div>
          <div style="cursor: pointer" @click="searchFatory(item.productManufacturer)" class="img-compamy"
            :title="item.productManufacturer">
            {{
              item.productManufacturer &&
                item.productManufacturer.replace(/\s*/g, "") !== ""
                ? item.productManufacturer
                : "暂无厂家信息！"
            }}
          </div>
          <div class="img-guige">
            规格：<span :title="item.productFormat">{{
              item.productFormat
            }}</span>
          </div>
          <div class="img-guige">
            有效期:
            {{
              item.productionDate && item.productionDate !== ""
                ? item.productionDate
                : "暂无效期"
            }}
          </div>
          <div class="img-xiaoqi">
            <span v-if="item.inventory" class="kucun">
              限购数量：
              {{
                item.canSaleAmount === null || item.canSaleAmount === 99999999
                  ? item.customerBuyAmountAstrict == -1
                    ? "不限购"
                    : item.customerBuyAmountAstrict
                  : item.canSaleAmount
              }}
            </span>
            <span v-else class="kucun">库存：暂无库存</span>
          </div>
          <div v-if="!loginState" class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText">会员可见</p>
            </div>
            <div @click="gotoLogin" class="jionBtnBox">
              <img src="../assets/images/product/img/jionShop.png" />
              加入购物车
            </div>
          </div>
          <div v-else-if="item.inventory && item.inventory > 0" class="cz_content">
            
            <div class="amoutBox">
              <span @click="lessNumber(item)">-</span>
              <input :class="{
                shoppingHas:
                  item.shoppingCartAmount && item.shoppingCartAmount > 0,
              }" :value="
item.addCartQuantity" @focus="selectShopAmount($event)" type="text" @change="changeActInput(item, $event)"
                @keyup.enter="actJionShopCart(item)" />
              <span @click="addNumber(item)">+</span>
            </div>
            <div @click="actJionShopCart(item)" class="jionBtnBox">
              <img src="../assets/images/product/img/jionShop.png" />
              {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '1') ? '开票员订货' : '加入购物车' }}
            </div>
          </div>
          <div v-else class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText">暂无库存</p>
            </div>
            <div class="jionBtnBox spqgBtnBox">
              <router-link :to="{ path: '/wantToBuyProduct', query: qiugouDenglu(item) }">
                <img src="../assets/images/product/img/search.png" />
                商品求购
              </router-link>
            </div>
          </div>
        </li>
      </ul>

      <div class="pageFooter">
        <el-pagination background v-if="flashSale.totalCount > 0" :hide-on-single-page="true"
          layout="prev, pager, next, jumper" :current-page="flashSale.pageNo" @current-change="currentChange1"
          :page-size="flashSale.pageSize" :total="flashSale.totalCount">
        </el-pagination>
      </div>
    </div>
    <!-- 厂家促销 -->
    <div id="cjmzNav" v-if="promotion.list.length > 0" class="cjmzContent activityItem">
      <div class="activityTitle">厂家促销</div>
      <ul v-loading="promotion.loading" class="imglistbox">
        <li v-for="(item, index) of promotion.list"
          :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }"
          v-bind:key="'imgProduct' + index">
          <div>
            <div class="productImgBox">
              <router-link :to="{
                path: (item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '1') ? '/linkToSale' : '/productDetail',
                query: { productId: item.productId },
              }" style="display: inline-block" :title="item.name" target="_blank">
                <img class="productImg" v-lazy="productImg(item)" />
              </router-link>
            </div>
          </div>
          <!--产品名称-->
          <div class="img-mingzi">
            {{ item.name }}
          </div>
          <!--判断产品是否有库存-->
          <div v-if="!item.inventoryList || item.inventoryList.length === 0" class="proPriceContent clearfix">
            <p class="priceBox fl" style="font-size: 17px">
              {{ loginState? "￥--/--": "会员可见" }}
            </p>
          </div>

          <div v-else-if="item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1'" class="proPriceContent clearfix">
            <p class="priceBox fl" style="font-size: 17px">
              请联系开票员
            </p>
          </div>
          <!-- 秒杀产品显示的价格 -->
          <div v-else-if="
            item.productActivityPriceStrategyFlag === 1 &&
            item.productActivityPriceStrategyDetail &&
            item.productActivityPriceStrategyDetail.canSaleAmount
          " class="proPriceContent clearfix">
            <p class="priceBox fl">
              ￥{{ item.productActivityPriceStrategyDetail.price }}
            </p>
            <p class="yjPriceBox fl">
              <i class="priceName">原价:</i>
              ￥{{ item.productActivityPriceStrategyDetail.originalPrice }}
            </p>
          </div>
          <!-- 2.再判断产品是否参与折扣活动-->
          <div v-else-if="
            item.inventoryList[0].discountPrice &&
            item.inventoryList[0].discountPrice !== ''
          " class="proPriceContent clearfix">
            <p class="priceBox fl">
              ￥{{ item.inventoryList[0].discountPrice }}
            </p>
            <p v-if="false" class="yjPriceBox fl">
              <i class="priceName">原价:</i>
              ￥{{ item.inventoryList[0].price }}
            </p>
          </div>
          <!-- 3.再判断产品策略里面是否定义了原价-->
          <div v-else-if="
            item.inventoryList[0].originalPrice &&
            item.inventoryList[0].originalPrice !== ''
          " class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
            <p class="yjPriceBox fl">
              <i class="priceName">原价:</i>
              ￥{{ item.inventoryList[0].originalPrice }}
            </p>
          </div>
          <!-- 4.显示原价 -->
          <div v-else class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
          </div>
          <div v-if="
            loginState && item.inventoryList && item.inventoryList.length > 0
          " class="tipBox">

            <el-tooltip v-if="
              item.productActivityPriceStrategyFlag === 1 &&
              item.productActivityPriceStrategyDetail &&
              item.productActivityPriceStrategyDetail.canSaleAmount
            " class="item" effect="dark" content="该产品为秒杀产品" placement="top">
              <span class="tip1">秒杀</span>
            </el-tooltip>

            <el-tooltip v-if="
            item.inventoryList[0].newCustomerPriceFlag &&
            item.inventoryList[0].newCustomerPriceFlag == '1'" class="item" effect="dark" content="新用户特价产品"
              placement="top">
              <span class="tip3">特价</span>
            </el-tooltip>
            <span class="tip1" :style="
              item.inventoryList[0].deliveryTime === '24'
                ? 'background-color:#009944'
                : 'background-color:#0f4c81'
            ">{{
  item.inventoryList[0].deliveryTime
    ? item.inventoryList[0].deliveryTime
    : "24H"
}}</span>
            <span class="tip2" v-if="
              item.inventoryList[0].priceTitle &&
              item.inventoryList[0].priceTitle !== ''
            ">{{ item.inventoryList[0].priceTitle }}</span>

            <el-tooltip v-if="item.policyFlag && item.productPolicyList && item.productPolicyList.length > 0"
              class="item" effect="dark" :content="item.productPolicyList[0].remark" placement="top">
              <span class="tip3">促销<i class="el-icon-d-arrow-right"></i></span>
            </el-tooltip>
            <el-tooltip v-if="item.inventoryList[0].nearDateFlag" class="item" effect="dark" content="该产品为近效期产品"
              placement="top">
              <span class="tip4">近效期</span>
            </el-tooltip>
            <span class="tip5" v-if="
              (item.productActivityPriceStrategyFlag !== 1 ||
                item.productActivityPriceStrategyDetail.canSaleAmount ===
                0) &&
              item.minDiscountTicket &&
              item.minDiscountTicket.name !== ''
            ">
              {{ item.minDiscountTicket.name }}
            </span>
          </div>
          <div class="tipBox" v-else></div>
          <div style="cursor: pointer" @click="searchFatory(item.manufacturer)" class="img-compamy"
            :title="item.manufacturer">
            {{
              item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== ""
                ? item.manufacturer
                : "暂无厂家信息！"
            }}
          </div>
          <div class="img-guige">
            规格：<span :title="item.format">{{ item.format }}</span>
          </div>
          <div class="img-guige">
            有效期:
            {{
              item.inventoryList &&
                item.inventoryList.length > 0 &&
                item.inventoryList[0].endDate &&
                item.inventoryList[0].endDate !== ""
                ? item.inventoryList[0].endDate
                : "暂无效期"
            }}
          </div>
          <div class="img-xiaoqi">
            <span v-if="item.inventoryList && item.inventoryList.length > 0" class="kucun">
              库存：{{ showInventoryText(item.inventoryNum, item) }}
            </span>
            <span v-else class="kucun">库存：暂无库存</span>
            <span class="baozhuang">中/大包装：{{ item.mediumPkgSize }}/{{ item.bigPkgSize }}</span>
          </div>
          <div v-if="!loginState" class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText">会员可见</p>
            </div>
            <div @click="gotoLogin" class="jionBtnBox">
              <img src="../assets/images/product/img/jionShop.png" />
              加入购物车
            </div>
          </div>
          <div v-else-if="defaultInventory(item.inventoryList).length > 0" class="cz_content">
            <div v-if="item.inventoryList && item.inventoryList.length > 0" class="amoutBox">
              <span @click="lessNum(item.inventoryList[0], item)">-</span>
              <input :class="{
                shoppingHas:
                  item.inventoryList[0].shoppingCartAmount &&
                  item.inventoryList[0].shoppingCartAmount > 0,
              }" :value="item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)"
                @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
                @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
              <span @click="addNum(item.inventoryList[0], item)">+</span>
            </div>
            <div @click="jionShopCart(item.inventoryList[0], item, $event)" class="jionBtnBox">
              <img src="../assets/images/product/img/jionShop.png" />
              {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1') ? '开票员订货' : '加入购物车' }}
            </div>
          </div>
          <div v-else class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText">暂无库存</p>
            </div>
            <div class="jionBtnBox spqgBtnBox">
              <router-link :to="{ path: '/wantToBuyProduct', query: qiugouDenglu(item) }">
                <img src="../assets/images/product/img/search.png" />
                商品求购
              </router-link>
            </div>
          </div>
        </li>
      </ul>
      <div class="pageFooter">
        <el-pagination background v-if="promotion.totalCount > 0" :hide-on-single-page="true"
          layout="prev, pager, next, jumper" :current-page="promotion.pageNo" @current-change="currentChange2"
          :page-size="promotion.pageSize" :total="promotion.totalCount">
        </el-pagination>
      </div>
    </div>
    <!-- 近效期 -->
    <div id="jxqNav" v-if="nearTerm.list.length > 0" class="jxqContent activityItem">
      <div class="activityTitle">近效期</div>
      <ul v-loading="nearTerm.loading" class="imglistbox">
        <li v-for="(item, index) of nearTerm.list"
          :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }"
          v-bind:key="'imgProduct' + index">
          <div>
            <div class="productImgBox">
              <router-link :to="{
                path: '/productDetail',
                query: { productId: item.productId },
              }" style="display: inline-block" :title="item.name" target="_blank">
                <img class="productImg" v-lazy="productImg(item)" />
              </router-link>
            </div>
          </div>
          <!--产品名称-->
          <div class="img-mingzi">
            {{ item.name }}
          </div>
          <!--判断产品是否有库存-->
          <div v-if="!item.inventoryList || item.inventoryList.length === 0" class="proPriceContent clearfix">
            <p class="priceBox fl" style="font-size: 17px">
              {{ loginState? "￥--/--": "会员可见" }}
            </p>
          </div>
          <div v-else-if="item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1'" class="proPriceContent clearfix">
            <p class="priceBox fl" style="font-size: 17px">
              请联系开票员
            </p>
          </div>
          <!-- 秒杀产品显示的价格 -->
          <div v-else-if="
            item.productActivityPriceStrategyFlag === 1 &&
            item.productActivityPriceStrategyDetail &&
            item.productActivityPriceStrategyDetail.canSaleAmount
          " class="proPriceContent clearfix">
            <p class="priceBox fl">
              ￥{{ item.productActivityPriceStrategyDetail.price }}
            </p>
            <p class="yjPriceBox fl">
              <i class="priceName">原价:</i>
              ￥{{ item.productActivityPriceStrategyDetail.originalPrice }}
            </p>
          </div>
          <!-- 2.再判断产品是否参与折扣活动-->
          <div v-else-if="
            item.inventoryList[0].discountPrice &&
            item.inventoryList[0].discountPrice !== ''
          " class="proPriceContent clearfix">
            <p class="priceBox fl">
              ￥{{ item.inventoryList[0].discountPrice }}
            </p>
            <p v-if="false" class="yjPriceBox fl">
              <i class="priceName">原价:</i>
              ￥{{ item.inventoryList[0].price }}
            </p>
          </div>
          <!-- 3.再判断产品策略里面是否定义了原价-->
          <div v-else-if="
            item.inventoryList[0].originalPrice &&
            item.inventoryList[0].originalPrice !== ''
          " class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
            <p class="yjPriceBox fl">
              <i class="priceName">原价:</i>
              ￥{{ item.inventoryList[0].originalPrice }}
            </p>
          </div>
          <!-- 4.显示原价 -->
          <div v-else class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
          </div>
          <div v-if="
            loginState && item.inventoryList && item.inventoryList.length > 0
          " class="tipBox">

            <el-tooltip v-if="
              item.productActivityPriceStrategyFlag === 1 &&
              item.productActivityPriceStrategyDetail &&
              item.productActivityPriceStrategyDetail.canSaleAmount
            " class="item" effect="dark" content="该产品为秒杀产品" placement="top">
              <span class="tip1">秒杀</span>
            </el-tooltip>

            <el-tooltip v-if="
            item.inventoryList[0].newCustomerPriceFlag &&
            item.inventoryList[0].newCustomerPriceFlag == '1'" class="item" effect="dark" content="新用户特价产品"
              placement="top">
              <span class="tip3">特价</span>
            </el-tooltip>
            <span class="tip1" :style="
              item.inventoryList[0].deliveryTime === '24'
                ? 'background-color:#009944'
                : 'background-color:#0f4c81'
            ">{{
  item.inventoryList[0].deliveryTime
    ? item.inventoryList[0].deliveryTime
    : "24H"
}}</span>
            <span class="tip2" v-if="
              item.inventoryList[0].priceTitle &&
              item.inventoryList[0].priceTitle !== ''
            ">{{ item.inventoryList[0].priceTitle }}</span>

            <el-tooltip v-if="item.policyFlag && item.productPolicyList && item.productPolicyList.length > 0"
              class="item" effect="dark" :content="item.productPolicyList[0].remark" placement="top">
              <span class="tip3">促销</span>
            </el-tooltip>
            <el-tooltip v-if="item.inventoryList[0].nearDateFlag" class="item" effect="dark" content="该产品为近效期产品"
              placement="top">
              <span class="tip4">近效期</span>
            </el-tooltip>
            <span class="tip5" v-if="
              (item.productActivityPriceStrategyFlag !== 1 ||
                item.productActivityPriceStrategyDetail.canSaleAmount ===
                0) &&
              item.minDiscountTicket &&
              item.minDiscountTicket.name !== ''
            ">
              {{ item.minDiscountTicket.name }}
            </span>
          </div>
          <div class="tipBox" v-else></div>
          <div style="cursor: pointer" @click="searchFatory(item.manufacturer)" class="img-compamy"
            :title="item.manufacturer">
            {{
              item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== ""
                ? item.manufacturer
                : "暂无厂家信息！"
            }}
          </div>
          <div class="img-guige">
            规格：<span :title="item.format">{{ item.format }}</span>
          </div>
          <div class="img-guige">
            有效期:
            {{
              item.inventoryList &&
                item.inventoryList.length > 0 &&
                item.inventoryList[0].endDate &&
                item.inventoryList[0].endDate !== ""
                ? item.inventoryList[0].endDate
                : "暂无效期"
            }}
          </div>
          <div class="img-xiaoqi">
            <span v-if="item.inventoryList && item.inventoryList.length > 0" class="kucun">
              库存：{{ showInventoryText(item.inventoryNum, item) }}
            </span>
            <span v-else class="kucun">库存：暂无库存</span>
            <span class="baozhuang">中/大包装：{{ item.mediumPkgSize }}/{{ item.bigPkgSize }}</span>
          </div>
          <div v-if="!loginState" class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText">会员可见</p>
            </div>
            <div @click="gotoLogin" class="jionBtnBox">
              <img src="../assets/images/product/img/jionShop.png" />
              加入购物车
            </div>
          </div>
          <div v-else-if="defaultInventory(item.inventoryList).length > 0" class="cz_content">
            <div v-if="item.inventoryList && item.inventoryList.length > 0" class="amoutBox">
              <span @click="lessNum(item.inventoryList[0], item)">-</span>
              <input :class="{
                shoppingHas:
                  item.inventoryList[0].shoppingCartAmount &&
                  item.inventoryList[0].shoppingCartAmount > 0,
              }" :value="item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)"
                @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
                @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
              <span @click="addNum(item.inventoryList[0], item)">+</span>
            </div>
            <div @click="jionShopCart(item.inventoryList[0], item, $event)" class="jionBtnBox">
              <img src="../assets/images/product/img/jionShop.png" />
              {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1') ? '开票员订货' : '加入购物车' }}
            </div>
          </div>
          <div v-else class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText">暂无库存</p>
            </div>
            <div class="jionBtnBox spqgBtnBox">
              <router-link :to="{ path: '/wantToBuyProduct', query: qiugouDenglu(item) }">
                <img src="../assets/images/product/img/search.png" />
                商品求购
              </router-link>
            </div>
          </div>
        </li>
      </ul>
      <div class="pageFooter">
        <el-pagination background v-if="nearTerm.totalCount > 0" :hide-on-single-page="true"
          layout="prev, pager, next, jumper" :current-page="nearTerm.pageNo" @current-change="currentChange3"
          :page-size="nearTerm.pageSize" :total="nearTerm.totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
import { getAction, postAction } from "../api/manage";
import { accAdd, getUserInfo } from "../assets/js/common/common";

import CountDown from '../components/common/CountDown/CountDown.vue'
export default {
  name: "Activity",
  mixins: [ProductMixin],
  components: {
    CountDown
  },
  data() {
    return {
      activityNav: [
        {
          id: "yhqNav",
          sort: 1,
          text: "优惠券",
        },
        {
          id: "xsqgNav",
          sort: 2,
          text: "限时抢购",
        },
        {
          id: "cjcxNav",
          sort: 3,
          text: "厂家促销",
        },
        {
          id: "jxqNav",
          sort: 4,
          text: "近效期",
        },
      ],
      activeIndex: 0,
      ticketDataList: [],
      loginState: false,
      flashSaleDetail: {},
      limitedTime: null,
      flashSale: {
        list: [],
        pageSize: 20,
        pageNo: 1,
        totalCount: 1,
        loading: false,
      },
      promotion: {
        list: [],
        pageSize: 20,
        pageNo: 1,
        totalCount: 1,
        loading: false,
      },
      nearTerm: {
        list: [],
        pageSize: 20,
        pageNo: 1,
        totalCount: 1,
        loading: false,
      },
    };
  },
  created() {
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
    }
    let actIndex = this.$route.query.actIndex;
    if (actIndex && actIndex !== "") {
      let _this = this;
      setTimeout(function () {
        _this.jump(actIndex);
      }, 1000);
    }
    this.getTicketList();
    this.getFlashSaleList();
    this.getPromotionList();
    this.getNearTermList();
  },
  methods: {
    // 当天倒计时问题
    countDownText(item) {
      let returnText = 0;
      console.log("item.nowTime+++++++", item.nowTime)
      if (item && item.nowTime && item.nowTime !== '' && item.endTime && item.endTime !== '') {
        let nowTimeArr = item.nowTime.split(' ');
        let endTime = item.endTime;
        let nowEndTime = new Date(`${nowTimeArr[0]} ${endTime}`).getTime()
        console.log("nowEndTime++++++++", nowEndTime)
        returnText = nowEndTime
      }
      return returnText
    },
    // 倒计时结束回调事件
    onEndHandle() {
      this.getFlashSaleList()
    },
    // 处理时间格式
    timeClear(time) {
      if (time && typeof time !== "undefined") {
        let timeArr = time.split(" ");
        let dateArr = timeArr[0].split("-");

        // return `${dateArr[1]}.${dateArr[2]}`;
        return timeArr[0];
      }
    },
    // 去使用优惠券
    useTicket(item) {
      let astrictProductFlag = item.astrictProductFlag
      if (astrictProductFlag !== '0') {
        let discountTicketId = item.ticketId;
        this.$router.push({
          name: 'productList',
          query: {
            discountTicketId: discountTicketId
          }
        })
      } else {
        this.$router.push({
          name: 'productList'
        })
      }
    },
    lingquTicket(item) {
      let _this = this;
      let data = {
        id: item.id,
      };
      let url =
        "/discountTicket/discountTicketStrategyReceive/receiveDiscountTicket";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.getTicketList();
        }
      });
    },
    // 获取奖券列表
    getTicketList() {
      let _this = this;
      this.ticketLoaing = true;
      let url =
        "/discountTicket/discountTicketStrategyReceive/getCanReceiveDiscountTicketlist";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          this.ticketLoaing = false;
          let resData = res.result;
          _this.ticketDataList = resData;
          if (!resData || resData.length === 0) {
            _this.deleteNavList(1);
          }
        }
      });
    },
    // 获取秒杀专区
    getFlashSaleList() {
      let _this = this;
      let pageNo = this.flashSale.pageNo;
      let pageSize = this.flashSale.pageSize;
      this.flashSale.loading = true;
      let url =
        "/productActivityPriceStrategy/productActivityPriceStrategy/queryDays";
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.flashSale.loading = false;
        if (res.code == 200 && res.success && res.result) {
          let resData = res.result[0];
          if (
            resData &&
            resData.productActivityPriceStrategyDetailList &&
            resData.productActivityPriceStrategyDetailList.length > 0
          ) {
            _this.flashSaleDetail = resData;
            _this.flashSale.list =
              resData.productActivityPriceStrategyDetailList;
          }
          if (
            !resData ||
            resData.productActivityPriceStrategyDetailList.length === 0
          ) {
            _this.deleteNavList(2);
          }
        } else {
          _this.deleteNavList(2);
        }
      });
    },
    deleteNavList(navIndex) {
      let activityNav = this.activityNav;
      for (let i = 0; i < activityNav.length; i++) {
        if (activityNav[i].sort === navIndex) {
          activityNav.splice(i, 1);
        }
      }
    },
    // 获取促销列表数据
    getPromotionList() {
      let _this = this;
      let pageNo = this.promotion.pageNo;
      let pageSize = this.promotion.pageSize;
      this.promotion.loading = true;
      let data = {
        policyFlag: 1,
        pageNo: parseInt(pageNo),
        pageSize: pageSize,
      };
      let url = "/product/getlistE";
      getAction(url, data).then((res) => {
        _this.promotion.loading = false;
        if (res.code === 200 && res.success) {
          console.log("获取促销列表的数据为++++", res);
          let resData = res.result;
          _this.promotion.list = resData.pageData.records;
          _this.promotion.pageNo = resData.pageData.current;
          _this.promotion.totalCount = resData.pageData.total;
          if (!resData || resData.pageData.records.length === 0) {
            _this.deleteNavList(3);
          }
        }
      });
    },
    // 获取近效期列表数据
    getNearTermList() {
      let _this = this;
      let pageNo = this.nearTerm.pageNo;
      let pageSize = this.nearTerm.pageSize;
      this.promotion.loading = true;
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
        nearDateFlag: 1,
      };
      let url = "/product/getlistE";
      getAction(url, data).then((res) => {
        _this.promotion.loading = false;
        if (res.code === 200 && res.success) {
          console.log("获取近效期产品的数据为++++", res);
          let resData = res.result;
          _this.nearTerm.list = resData.pageData.records;
          _this.nearTerm.pageNo = resData.pageData.current;
          _this.nearTerm.totalCount = resData.pageData.total;
          if (!resData || resData.pageData.records.length === 0) {
            _this.deleteNavList(4);
          }
        }
      });
    },
    currentChange1(val) {
      this.jump(1)
      this.flashSale.pageNo = val;
      this.getFlashSaleList();
    },
    currentChange2(val) {
      this.jump(2)
      this.promotion.pageNo = val;
      this.getPromotionList();
    },
    currentChange3(val) {
      this.jump(3)
      this.nearTerm.pageNo = val;
      this.getNearTermList();
    },

    // 显示产品图片
    productDataImg(item) {
      if (item.productPicUrl && item.productPicUrl !== "") {
        let imgsArr = item.productPicUrl.split(",");
        let imgSrc = imgsArr[0];
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },

    // 显示产品图片
    productImg(item) {
      if (item.picUrl && item.picUrl !== "") {
        let imgsArr = item.picUrl.split(",");
        let imgSrc = imgsArr[0];
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    defaultInventory(list) {
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },

    // 限时抢购倒计时
    xsqgCountDown(item) {
      let _this = this;
      let nowStatus = _this.limitedStatus(item);
      // 2. 判断不同场次的限时抢购的活动是否开始
      item.nowStatus = nowStatus;
      // 3. 当开始时间小于当前时间，结束时间又大于当前时间，证明活动已经开始，进行结束倒计时显示
      if (nowStatus) {
        let nowTimeArr = item.nowTime.split(" ");
        let ItemStartTime = `${nowTimeArr[0]} ${item.startTime}`;
        let ItemEndTime = `${nowTimeArr[0]} ${item.endTime}`;
        let ItemNowTime = `${item.nowTime}`;
        let endTime = Date.parse(new Date(ItemEndTime)) / 1000;
        let startTime = Date.parse(new Date(ItemStartTime)) / 1000;
        let nowTime = Date.parse(new Date(ItemNowTime)) / 1000;
        // 4. 计算出总的倒计时秒数
        let allTimeS = 0;
        allTimeS = endTime - nowTime;
        // 5. 开始执行定时器倒计时
        _this.limitedTime = setInterval(function (e) {
          allTimeS--;
          _this.countBackTime2(item, allTimeS);
        }, 1000);
      }
    },
    // 判断活动时间是否开始
    limitedStatus(item) {
      let nowTimeArr = item.nowTime.split(" ");
      let ItemStartTime = `${nowTimeArr[0]} ${item.startTime}`;
      let ItemEndTime = `${nowTimeArr[0]} ${item.endTime}`;
      let ItemNowTime = `${item.nowTime}`;
      let endTime = Date.parse(new Date(ItemEndTime)) / 1000;
      let startTime = Date.parse(new Date(ItemStartTime)) / 1000;
      let nowTime = Date.parse(new Date(ItemNowTime)) / 1000;
      if (endTime > nowTime && nowTime > startTime) {
        return true;
      } else {
        this.deleteNavList(2);
        return false;
      }
    },
    // 倒计时返回值
    countBackTime2(item, allTimeS) {
      let _this = this;
      let suM = allTimeS % 60; //求出所于的秒数
      suM = suM < 10 ? "0" + suM : suM;
      let suF = ((allTimeS - suM) / 60) % 60; //求出所于分钟数
      suF = suF < 10 ? "0" + suF : suF;
      let fenS = suF * 60;
      let suH = ((allTimeS - fenS - suM) / (60 * 60)) % 24; //求出所于小时数
      suH = suH < 10 ? "0" + suH : suH;
      let shiS = suH * 60 * 60;
      let suT = (allTimeS - shiS - fenS - suM) / (24 * 60 * 60); //求出所于小时数
      suT = suT < 10 ? "0" + suT : suT;
      if (suM <= 0 && suF <= 0 && suH <= 0 && suT <= 0) {
        // 移除倒计时
        clearInterval(_this.limitedTime);
      } else {
        _this.$set(item, "suT", suT);
        _this.$set(item, "suH", suH);
        _this.$set(item, "suF", suF);
        _this.$set(item, "suM", suM);
      }
    },

    /**
     * 锚点连接平滑滚动
     **/
    jump(index) {
      this.activeIndex = index;
      // 用 class="instance_title" 添加锚点
      let jump = document.querySelectorAll(".activityItem");
      let total = jump[index].offsetTop;
      console.log("total+++++", total);
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      console.log("distance+++++", distance);
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }

      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 2);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }

      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 2);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },
  },
  unmounted() {
    clearInterval(this.limitedTime);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/activity/activity";
@import "../assets/styles/product/img/img";

.pageFooter {
  margin-bottom: 50px;
}
</style>