<template>
  <div class="productListApp">
    <div>
      <div class="proTable">
        <div v-if="productList.length === 0 && !productLoading" class="dataNone">
          <div class="dataImg productNoneImg"></div>
        </div>
        <dhh-img-pro-list v-loading="productLoading" :productList="productList"> </dhh-img-pro-list>

      </div>
    </div>
    <div class="pageFooter">
      <el-pagination background v-if="totalCount > 0" :hide-on-single-page="true" layout="prev,sizes, pager, next, jumper"
        :current-page="pageNo" @current-change="currentChange" :page-sizes="[20, 30, 40, 50]"
        @size-change="handleSizeChange" :page-size="pageSize" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</div></template>
<script>
import { getAction } from "../../api/manage";
import merge from 'webpack-merge'
import Vue from 'vue';
import DhhImgProList from '../../components/product/DhhImgProList.vue';
export default {
  components: { DhhImgProList },
  props: ["searchName"],
  name: "ProductList",
  data() {
    return {
      pageNo: 1,
      pages: 1,
      pageSize: 20,
      totalCount: 1,
      searchVal: "",
      collectFlag: "",
      productShowType: '1',
      productLoading: true,
      productList: [],
      manufacturer: "",
      hasStocket: false, // 只展示有库存的
      maxPrice: "",
      minPrice: "",
      showRangeStatus: false,
      screenList: ["默认排序", "销量", "价格", "人气"],
      screenType: 0, // 4:销量 2:价格 3:人气
      sortType: 1, // 1:升序 2:降序
      sortTypeText: "由低到高", // 排序提示
      productCategory: "", // 产品分类
      nearDateFlag: "", // 产品分类
      productLabelList: "", // 产品标签数据
      newCustomerPriceFlag: "", // 搜索指定新人特价产品
      discountTicketId: "", // 搜索指定优惠券的产品数据
      selectedBrandId: ''
    };
  },
  created() {
    // 检测平台设置的默认产品列表展示模式
    let searchVal = this.$route.query.searchVal;
    let productLabelList = this.$route.query.productLabelList;
    this.selectedBrandId = productLabelList
    this.searchVal = searchVal;
    this.getProductListData();
  },
  methods: {
    // 搜索产品的操作
    searchProduct(searchName, labelFlag) {
      this.searchVal = searchName;
      if (!labelFlag && this.$route.query.productLabelList && this.$route.query.productLabelList !== '') {
        delete this.$route.query.productLabelList
        this.selectedBrandId = ""
      }
      this.$router.push({
        query: merge(this.$route.query, { 'searchVal': searchName })
      })
      this.pageNo = 1;
      this.getProductListData();
    },
    searchNotBrandProduct(searchName) {
      this.searchVal = searchName;
      this.selectedBrandId = ""
      this.$router.push({
        query: merge(this.$route.query, { 'searchVal': searchName })
      })
      this.pageNo = 1;
      this.getProductListData();
    },
    // 搜索指定厂家品种
    searchBrandId(id) {
      console.log("当前筛选的品牌ID为", id)
      this.selectedBrandId = id;
      this.searchVal = "";
      this.$router.push({
        query: merge(this.$route.query, { 'searchVal': "" })
      })
      this.getProductListData()
    },
    inputBlur() {
      let minPrice = this.minPrice;
      let maxPrice = this.maxPrice;
      if (minPrice == '' && maxPrice == '') {
        this.showRangeStatus = false
      }
    },
    // 价格区间查询
    priceSearch() {
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 清空价格区间筛选
    clearPriceNum() {
      this.minPrice = "";
      this.maxPrice = "";
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
    // 选择商品筛选排序条件
    selectScreen(item, index) {
      let sortType = this.sortType;
      let screenType = this.screenType;
      // 人气默认由高到低
      if (screenType == index && index > 0) {
        if (sortType == 1) {
          this.sortTypeText = "由高到低";
          this.sortType = 2;
        } else {
          this.sortTypeText = "由低到高";
          this.sortType = 1;
        }
      } else if (screenType !== index && index > 0) {
        // 初始人气、销量默认从高到低
        if (index == 3 || index == 1) {
          this.sortType = 2;
          this.sortTypeText = "由高到低";
        } else {
          this.sortType = 1;
          this.sortTypeText = "由低到高";
        }
      } else {
        this.sortType = 1;
        this.sortTypeText = "";
      }
      this.screenType = index;
      this.getProductListData();
    },
    // 获取产品列表数据
    getProductListData() {
      this.backTop();
      let _this = this;
      this.productLoading = true;
      let productLabelList = this.selectedBrandId;
      let searchVal = this.searchVal;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let data = {
        searchVal: searchVal,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (productLabelList && productLabelList !== '') {
        data.productLabelList = productLabelList
      }
      let url = "/yushou/yushou/getlistE";
      console.log("请求列表的数据为+++++++", data)
      getAction(url, data, true)
        .then((res) => {
          _this.productLoading = false;
          if (res.code === 200 && res.success) {
            let pageData = res.result.pageData;
            _this.productList = pageData.records;
            _this.pageNo = pageData.current;
            _this.pages = pageData.pages;
            _this.totalCount = pageData.total;
          } else {
            _this.productList = [];
            _this.productLoading = false;
          }
          _this.$emit('loadingOver', _this.productLoading)
        })
        .catch((error) => {
          _this.productList = [];
          _this.productLoading = false;
          _this.$emit('loadingOver', _this.productLoading)
        });
    },
    // 搜索收藏夹产品的操作
    searchCollectProduct(searchName) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.collectFlag = 1;
      this.pageNo = 1;
      this.getProductListData();
    },
    searchProductCategory(productCategory) {
      this.clearSearchUrlData();
      this.productCategory = productCategory;
      this.getProductListData();
    },
    clearSearchUrlData() {
      this.productCategory = "";
      this.productLabelList = "";
      this.manufacturer = "";
      this.collectFlag = "";
      this.searchVal = "";
      this.newCustomerPriceFlag = "";
      this.discountTicketId = "";
      this.pageNo = 1;
    },
    currentChange(val) {
      this.pageNo = val;
      this.getProductListData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getProductListData();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/activity/meetingProduct";

.productListApp {
  margin-top: 20px;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-image: linear-gradient(#ffffff, #FFAB31) !important;
  color: #FFAB31 !important;
}

::v-deep .el-pagination__jump {
  color: #FFAB31;
  font-size: 20px !important;
  font-weight: bold;
  display: flex !important;
  float: right;
  align-items: center;
}

.el-loading-parent--relative {
  min-height: 560px;

}

::v-deep .el-loading-mask {
  background: transparent !important;
}

::v-deep .el-loading-spinner .path {
  stroke: #ffffff !important;
}
</style>