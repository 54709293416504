<template>
  <div class="areaContainer" :style="
    brandDetail &&
      brandDetail.backgroundColor &&
      brandDetail.backgroundColor !== ''
      ? `background-color:${brandDetail.backgroundColor}`
      : ''
  ">
    <!-- banner区域 -->
    <div v-if="brandDetail && brandDetail.picUrl && brandDetail.picUrl !== ''" class="areaBanner container">
      <img :src="brandDetail.picUrl" alt="brandDetail.name" />
    </div>
    <!-- end banner区域 -->
    <!-- 促销活动专区 -->
    <div class="activityBox container_box">

      <!-- 厂家促销 -->
      <div v-loading="promotion.loading" id="cjmzNav" v-if="promotion.list.length > 0" class="cjmzContent activityItem">
        <ul class="imglistbox">
          <li v-for="(item, index) of promotion.list"
            :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }"
            v-bind:key="'imgProduct' + index">
            <div>
              <div class="productImgBox">
                <router-link :to="{
                  path: '/productDetail',
                  query: { productId: item.productId },
                }" :title="item.name" target="_blank">
                  <img class="productImg" v-lazy="productImg(item)" />
                </router-link>
              </div>
            </div>
            <!--产品名称-->
            <div class="img-mingzi">
              {{ item.name }}
            </div>
            <!--判断产品是否有库存-->
            <div v-if="!item.inventoryList || item.inventoryList.length === 0" class="proPriceContent clearfix">
              <p class="priceBox fl" style="font-size: 17px">
                {{ loginState ? "￥--/--" : "会员可见" }}
              </p>
            </div>

            <div
              v-else-if="item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1'"
              class="proPriceContent clearfix">
              <p class="priceBox fl" style="font-size: 17px">
                请联系开票员
              </p>
            </div>
            <!-- 秒杀产品显示的价格 -->
            <div v-else-if="
              item.productActivityPriceStrategyFlag === 1 &&
              item.productActivityPriceStrategyDetail &&
              item.productActivityPriceStrategyDetail.canSaleAmount
            " class="proPriceContent clearfix">
              <p class="priceBox fl">
                ￥{{ item.productActivityPriceStrategyDetail.price }}
              </p>
              <p class="yjPriceBox fl">
                <i class="priceName">原价:</i>
                ￥{{ item.productActivityPriceStrategyDetail.originalPrice }}
              </p>
            </div>
            <!-- 2.再判断产品是否参与折扣活动-->
            <div v-else-if="
              item.inventoryList[0].discountPrice &&
              item.inventoryList[0].discountPrice !== ''
            " class="proPriceContent clearfix">
              <p class="priceBox fl">
                ￥{{ item.inventoryList[0].discountPrice }}
              </p>
              <p v-if="false" class="yjPriceBox fl">
                <i class="priceName">原价:</i>
                ￥{{ item.inventoryList[0].price }}
              </p>
            </div>
            <!-- 3.再判断产品策略里面是否定义了原价-->
            <div v-else-if="
              item.inventoryList[0].originalPrice &&
              item.inventoryList[0].originalPrice !== ''
            " class="proPriceContent clearfix">
              <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
              <p class="yjPriceBox fl">
                <i class="priceName">原价:</i>
                ￥{{ item.inventoryList[0].originalPrice }}
              </p>
            </div>
            <!-- 4.显示原价 -->
            <div v-else class="proPriceContent clearfix">
              <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
            </div>
            <div v-if="
              loginState && item.inventoryList && item.inventoryList.length > 0
            " class="tipBox">

              <el-tooltip v-if="
                item.productActivityPriceStrategyFlag === 1 &&
                item.productActivityPriceStrategyDetail &&
                item.productActivityPriceStrategyDetail.canSaleAmount
              " class="item" effect="dark" content="该产品为秒杀产品" placement="top">
                <span class="tip1">秒杀</span>
              </el-tooltip>

              <el-tooltip v-if="
                item.inventoryList[0].newCustomerPriceFlag &&
                item.inventoryList[0].newCustomerPriceFlag == '1'" class="item" effect="dark" content="新用户特价产品"
                placement="top">
                <span class="tip3">特价</span>
              </el-tooltip>
              <span class="tip1" :style="
                item.inventoryList[0].deliveryTime === '24'
                  ? 'background-color:#009944'
                  : 'background-color:#0f4c81'
              ">{{
  item.inventoryList[0].deliveryTime
  ? item.inventoryList[0].deliveryTime
  : "24H"
}}</span>
              <span class="tip2" v-if="
                item.inventoryList[0].priceTitle &&
                item.inventoryList[0].priceTitle !== ''
              ">{{ item.inventoryList[0].priceTitle }}</span>

              <el-tooltip v-if="item.policyFlag && item.productPolicyList && item.productPolicyList.length > 0"
                class="item" effect="dark" :content="item.productPolicyList[0].remark" placement="top">
                <span class="tip3">促销</span>
              </el-tooltip>
              <el-tooltip v-if="item.inventoryList[0].nearDateFlag" class="item" effect="dark" content="该产品为近效期产品"
                placement="top">
                <span class="tip4">近效期</span>
              </el-tooltip>
              <span class="tip5" v-if="
                (item.productActivityPriceStrategyFlag !== 1 ||
                  item.productActivityPriceStrategyDetail.canSaleAmount ===
                  0) &&
                item.minDiscountTicket &&
                item.minDiscountTicket.name !== ''
              ">
                {{ item.minDiscountTicket.name }}
              </span>
            </div>
            <div class="tipBox" v-else></div>
            <div style="cursor: pointer" @click="searchFatory(item.manufacturer)" class="img-compamy"
              :title="item.manufacturer">
              {{
                item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== ""
                ? item.manufacturer
                : "暂无厂家信息！"
              }}
            </div>
            <div class="img-guige">
              规格：<span :title="item.format">{{ item.format }}</span>
            </div>
            <div class="img-guige">
              有效期:
              {{
                item.inventoryList &&
                item.inventoryList.length > 0 &&
                item.inventoryList[0].endDate &&
                item.inventoryList[0].endDate !== ""
                ? item.inventoryList[0].endDate
                : "暂无效期"
              }}
            </div>
            <div class="img-xiaoqi">
              <span v-if="item.inventoryList && item.inventoryList.length > 0" class="kucun">
                库存：{{ showInventoryText(item.inventoryNum, item) }}
              </span>
              <span v-else class="kucun">库存：暂无库存</span>
              <span class="baozhuang">中/大包装：{{ item.mediumPkgSize }}/{{ item.bigPkgSize }}</span>
            </div>
            <div v-if="!loginState" class="cz_content">
              <div class="caozuoBox">
                <p class="zwkcText">会员可见</p>
              </div>
              <div @click="gotoLogin" class="jionBtnBox">
                <img src="../assets/images/product/img/jionShop.png" />
                加入购物车
              </div>
            </div>

            <div v-else-if="defaultInventory(item.inventoryList).length > 0" class="cz_content">
              <div v-if="item.inventoryList && item.inventoryList.length > 0 && item.productNotputSaleCreateOrder !== '0'"
                class="amoutBox">
                <span @click="lessNum(item.inventoryList[0], item)">-</span>
                <input :class="{
                  shoppingHas:
                    item.inventoryList[0].shoppingCartAmount &&
                    item.inventoryList[0].shoppingCartAmount > 0,
                }" :value="item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
                  @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
                <span @click="addNum(item.inventoryList[0], item)">+</span>
              </div>
              <div @click="jionShopCart(item.inventoryList[0], item, $event)" class="jionBtnBox"
                :style="(item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '0') ? 'width: 100%' : ''">
                <img src="../assets/images/product/img/jionShop.png" />
                {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1') ? item.productNotputSaleCreateOrder == '1' ?
                  '开票员订货' : '请联系开票员订货' : '加入购物车' }}
              </div>
            </div>
            <div v-else class="cz_content">
              <div class="caozuoBox">
                <p class="zwkcText">暂无库存</p>
              </div>
              <div class="jionBtnBox spqgBtnBox">
                <router-link :to="{ path: '/wantToBuyProduct', query: qiugouDenglu(item) }">
                  <img src="../assets/images/product/img/search.png" />
                  商品求购
                </router-link>
              </div>
            </div>
          </li>
        </ul>
        <div class="pageFooter">
          <el-pagination background v-if="promotion.totalCount > 0" :hide-on-single-page="true"
            layout="prev, pager, next, jumper" :current-page="promotion.pageNo" @current-change="currentChange2"
            :page-size="promotion.pageSize" :total="promotion.totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
import { getAction } from "../api/manage";
import { getUserInfo } from "../assets/js/common/common";
export default {
  name: "PromotionZone",
  mixins: [ProductMixin],
  data() {
    return {
      loginState: false,
      brandDetail: {},
      promotion: {
        list: [],
        pageSize: 20,
        pageNo: 1,
        totalCount: 1,
        loading: false,
      },
      areaId: '',
    };
  },
  created() {
    let areaId = this.$route.query.id
    if (areaId && areaId !== '') {
      this.areaId = areaId
    }
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
    }
    this.getPromotionList();
    this.getZoneDetailData();
  },
  methods: {
    
    // 显示产品图片
    productImg(item) {
      if (item.picUrl && item.picUrl !== '') {
        let imgsArr = item.picUrl.split(',');
        // let imgSrc = imgsArr[0]
        let imgSrc = this.$util.resizeImg(imgsArr[0],200,false)
        return imgSrc
      } else {
        let imgSrc = ''
        return imgSrc
      }
    },
    // 获取专区中心的背景色和banner图
    async getZoneDetailData() {
      let areaId = this.areaId
      let url = "/area/brandAreavo/get";
      let data = {
        id: areaId
      }
      let res = await getAction(url, data);
      console.log("获取当前的促销专区的配置信息++++++", res)
      if (res.code == 200 && res.success) {
        this.brandDetail = res.result;
      }
      // this.brandDetail = {
      //   appPicUrl: "/group1/M00/00/05/rBGFfGKEkR2AWAlqAALZS8iwk5Y752.jpg",
      //   backgroundColor: "#FFFFFF",
      //   id: "614110e587f7a9a5592bd88842032b6c",
      //   name: "保健品专区",
      //   picUrl: "/group1/M00/00/04/rBGFfGJxzOmAbe7XAAWb1wqEo0M223.jpg",
      //   productBackgroundPic: "",
      // }
    },
    // 获取促销列表数据
    getPromotionList() {
      let _this = this;
      let pageNo = this.promotion.pageNo;
      let pageSize = this.promotion.pageSize;
      this.promotion.loading = true;
      let data = {
        policyFlag: 1,
        pageNo: parseInt(pageNo),
        pageSize: pageSize,
      };
      let url = "/product/getlistE";
      getAction(url, data).then((res) => {
        _this.promotion.loading = false;
        if (res.code === 200 && res.success) {
          console.log("获取促销列表的数据为++++", res);
          let resData = res.result;
          _this.promotion.list = resData.pageData.records;
          _this.promotion.pageNo = resData.pageData.current;
          _this.promotion.totalCount = resData.pageData.total;
        }
      });
    },
    currentChange2(val) {
      this.promotion.pageNo = val;
      this.getPromotionList();
    },
    defaultInventory(list) {
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/activity/activity";
@import "../assets/styles/product/img/img";

.pageFooter {
  margin-bottom: 50px;
}</style>