import Vue from 'vue'
import store from '@/store'
import axios from 'axios'
import {
  SHOPPING_ACCESS_TOKEN,
  USER_INFO
} from "../../../store/mutation-types";
let Base64 = require('js-base64').Base64

function isMobile(str) {
  const reg = /^1[0-9]{10}$/
  return reg.test(str)
}

// 浮点数乘法运算结果处理
function accMuls(arg1, arg2) {
  if (typeof (arg1) === 'undefined') {
    arg1 = 0
  }
  if (typeof (arg2) === 'undefined') {
    arg2 = 0
  }
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) { }
  try {
    m += s2.split('.')[1].length
  } catch (e) { }
  let numberMoney = Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
  return numberMoney.toFixed(2)
}
// 根据当前地址路径判断引入指定的百度统计
function insetBaiduStatistics() {
  let locationOrigin = window.location.origin;
  console.log("location++++++++", locationOrigin)
  let appId = ""
  if (locationOrigin == 'https://www.jiangzhongcq.com' || locationOrigin == 'https://b2b.jzys.com.cn') {
    if (locationOrigin == 'https://www.jiangzhongcq.com') {
      appId = '68cf1556de34eb3279a6e6ac580ae302'
    } else if (locationOrigin == 'https://b2b.jzys.com.cn') {
      appId = '6356d378229cd197ec31be7858a46d30'
    }
    // eslint-disable-next-line
    const _hmt = _hmt || [];
    // eslint-disable-next-line
    window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
    (function () {
      const hm = document.createElement('script');
      hm.src = 'https://hm.baidu.com/hm.js?' + appId;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(hm, s);
    }());
    _hmt.push(['_requirePlugin', 'UrlChangeTracker', {
      shouldTrackUrlChange: function (newPath, oldPath) {
        return newPath && oldPath;
      }
    }
    ]);
  } else {
    // eslint-disable-next-line
    const _hmt = _hmt || [];
    // eslint-disable-next-line
    window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
  }
}
// 获取当前子公司的配置信息 
// getRouterName all:获取单个公司配置信息; 
// optionName 获取公司配置信息字段名称;
// returnName 需要返回的路由名称;
async function getSubsidiaryData(optionName, getRouterName = 'all') {
  let res = await axios.get('/resources/subsidiaryData.json');
  console.log("res+++++++++", res)
  if (res.status == 200) {
    let subsidiaryData = res.data;
    // 判断返回类型；getRouterName为指定路由名称时；
    if (getRouterName !== 'all') {
      for (let key of subsidiaryData) {
        if (key.routerName == getRouterName) {
          return key[optionName]
        }
      }
    } else if (getRouterName == 'all') {
      let subsidiaryArr = [];
      for (let key of subsidiaryData) {
        subsidiaryArr.push(key[optionName])
      }
      return subsidiaryArr;
    }
  } else {
    return false
  }
}
// 游客token
function createTouristToken() {
  const touristToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjAiLCJleHAiOjE1OTI5NjU2NTR9._nHifSiEeIk6uYIA_JME9cHgSYt_rkswrGqkB29Mtfw'
  return touristToken
}
// 浮点数加法运算结果处理
function accAdd(arg1, arg2) {
  var r1, r2, m, c
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  c = Math.abs(r1 - r2)
  m = Math.pow(10, Math.max(r1, r2))
  if (c > 0) {
    var cm = Math.pow(10, c)
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', '')) * cm
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm
      arg2 = Number(arg2.toString().replace('.', ''))
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''))
    arg2 = Number(arg2.toString().replace('.', ''))
  }
  return (arg1 + arg2) / m
}

// 均分数组
function sliceArr(array, size) {
  var result = []
  for (var x = 0; x < Math.ceil(array.length / size); x++) {
    var start = x * size
    var end = start + size
    result.push(array.slice(start, end))
  }
  return result
}

// 获取用户的登录数据
function getUserInfo() {
  let userDataBas = Vue.ls.get(USER_INFO)
  if (userDataBas) {
    let userDataStr = Base64.decode(userDataBas)
    let userData = JSON.parse(userDataStr)
    return userData
  } else {
    return false
  }
}
// JS转Base64之后的data类型
function getBase64Type(type) {
  switch (type) {
    case 'txt': return 'data:text/plain;base64,';
    case 'doc': return 'data:application/msword;base64,';
    case 'docx': return 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,';
    case 'xls': return 'data:application/vnd.ms-excel;base64,';
    case 'xlsx': return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    case 'pdf': return 'data:application/pdf;base64,';
    case 'pptx': return 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,';
    case 'ppt': return 'data:application/vnd.ms-powerpoint;base64,';
    case 'png': return 'data:image/png;base64,';
    case 'jpg': return 'data:image/jpeg;base64,';
    case 'gif': return 'data:image/gif;base64,';
    case 'svg': return 'data:image/svg+xml;base64,';
    case 'ico': return 'data:image/x-icon;base64,';
    case 'bmp': return 'data:image/bmp;base64,';
  }
}
function getType(file) {
  var filename = file;
  var index1 = filename.lastIndexOf(".");
  var index2 = filename.length;
  var type = filename.substring(index1 + 1, index2);
  return type;
}
function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

function downloadFile(url, name = '下载图片') {
  var a = document.createElement("a")
  a.setAttribute("href", url)
  a.setAttribute("download", name)
  a.setAttribute("target", "_blank")
  let clickEvent = document.createEvent("MouseEvents");
  clickEvent.initEvent("click", true, true);
  a.dispatchEvent(clickEvent);
}
// 下载base64图片到本地
function downloadFileByBase64(base64, name) {
  var myBlob = dataURLtoBlob(base64)
  var myUrl = URL.createObjectURL(myBlob)
  downloadFile(myUrl, name)
}
// 递归转化城市数据
function sort(cityJs) {
  var result = []; //创建新返回的数据
  for (let key1 of cityJs) {
    if (!key1.hasOwnProperty('pid') && !key1.hasOwnProperty('cid')) {
      key1.cityList = []
      for (let key2 of cityJs) {
        if (key2.hasOwnProperty('pid') && !key2.hasOwnProperty('cid')) {
          key2.districtList = []
          if (key2.pid === key1.id) {
            key1.cityList.push(key2)
          }
          for (let key3 of cityJs) {
            if (key3.hasOwnProperty('pid') && key3.hasOwnProperty('cid')) {
              if (key3.cid === key2.id) {
                key2.districtList.push(key3)
              }
            }
          }
        }
      }
      result.push(key1)
    }
  }
  return result;
}
// 处理小于10的字符串
function cleanTime(val) {
  return val < 10 ? '0' + val : val
}
// 获取Uid
function uuid(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
}
// 获取是否是手机端设备
function hasMobilePhone() {
  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return true
  } else {
    return false
  }
}
// 判断IE版本号
function IEVersion() {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
  var isEdge = userAgent.indexOf("Edg") > -1 && !isIE; //判断是否IE的Edge浏览器  
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if (fIEVersion == 7) {
      return 7;
    } else if (fIEVersion == 8) {
      return 8;
    } else if (fIEVersion == 9) {
      return 9;
    } else if (fIEVersion == 10) {
      return 10;
    } else {
      return 6; //IE版本<=7
    }
  } else if (isEdge) {
    return 'edge'; //edge
  } else if (isIE11) {
    return 11; //IE11  
  } else {
    return false; //不是ie浏览器
  }
}
// 关闭当前网页窗口
function closeWindow() {
  var userAgent = navigator.userAgent;
  if (userAgent.indexOf("MSIE") > 0) {
    if (userAgent.indexOf("MSIE 6.0") > 0) {
      window.opener = null;
      window.close();
    } else {
      window.open("", "_top");
      window.top.close();
    }
  } else if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
    window.location.href = "about:blank "; //火狐默认状态非window.open的页面window.close是无效的
    //window.history.go(-2);
  } else {
    window.opener = null;
    window.open("about:blank", "_self");
    window.close();
  }
}
export {
  accMuls,
  createTouristToken,
  accAdd,
  sliceArr,
  isMobile,
  getUserInfo,
  cleanTime,
  uuid,
  sort,
  IEVersion,
  insetBaiduStatistics,
  hasMobilePhone,
  getSubsidiaryData,
  closeWindow,
  downloadFileByBase64,
  downloadFile,
  dataURLtoBlob
}