<template>
  <div class="addressListBox">
    <div class="addTitle">
      <h3>收货地址列表</h3>
      <a @click="addAddress" href="javascript:;">
        <i class="el-icon-plus"></i>
        新增收货地址
      </a>
    </div>
    <div v-if="addressList.length > 0" class="addressListContent">
      <div class="addressItem" v-for="(item, index) of addressList" :key="'address' + index">
        <p class="addressName">{{ item.contactsName }}</p>
        <p class="addressTel">{{ item.contactsTel }}</p>
        <p class="address">{{ item.provinceName }}{{ item.cityName }}{{ item.districtName }}{{ item.address }}</p>
        <div class="operation">
          <a class="editA" @click="editAddress(item)" href="javascript:;"> 修改 </a>
          <a class="delA" @click="deleteAddress(item)" href="javascript:;"> 删除 </a>
          <a class="editImg" @click="editAddress(item)" href="javascript:;">
            <img src="../assets/images/center/address/editIcon.png" alt="" />
          </a>
          <a class="delImg" @click="deleteAddress(item)" href="javascript:;">
            <img src="../assets/images/center/address/delIcon.png" alt="" />
          </a>
          <a class="defaultAddressA" v-if="item.isDefault === 1" href="javascript:;"> 默认收货地址 </a>
          <a v-else @click="editIsDefaultAddress(item)" href="javascript:;"> 设置默认收货地址 </a>
        </div>
      </div>
    </div>

    <div v-else-if="addressList == 0" class="dataNone">
      <div class="dataImg"></div>
    </div>
    <address-model ref="addressModel" @saveAddress="saveAddress"></address-model>
  </div>
</template>

<script>
import { deleteAction, getAction, putAction } from '../api/manage';
import AddressModel from "../components/common/AddressModel.vue";
export default {
  components: { AddressModel },
  data() {
    return {
      addressList: []
    }
  },
  created() {
    this.getAddressList()
  },
  methods: {
    getAddressList() {
      let _this = this;
      let url = '/customer/customerAddress/list';
      getAction(url).then(res => {
        if (res.code === 200 && res.success) {
          _this.addressList = res.result;
        }
      })
    },
    saveAddress() {
      console.log("提交修改成功返回的数据为")
      this.getAddressList()
    },
    addAddress() {
      this.$refs.addressModel.add()
    },
    // 设置默认地址数据
    editIsDefaultAddress(item) {
      let _this = this;
      let url = '/customer/customerAddress/edit';
      let data = {
        id: item.id,
        isDefault: 1
      }
      putAction(url, data).then(res => {
        if (res.code === 200 && res.success) {
          _this.getAddressList()
        }
      })
    },

    // 删除地址的操作
    deleteAddress(item) {
      let _this = this;
      this.$confirm('是否删除当前地址数据?', '删除提示:', {
        type: 'warning'
      }).then((res) => {
        let url = '/customer/customerAddress/delete'
        let delData = {
          id: item.id
        }
        deleteAction(url, delData).then(res => {
          if (res.code === 200 && res.success) {
            _this.getAddressList()
          }
        })
      }).catch(() => {
      })
    },
    // 修改地址操作
    editAddress(item) {
      this.$refs.addressModel.edit(item)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/center/address";
</style>