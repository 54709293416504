<template>
  <div class="myAccount">
    <div class="accountData">
      <div class="userImg">
        <img
          v-if="userInfo.img && userInfo.img !== ''"
          :src="userInfo.img"
          alt=""
        />
        <img
          v-else
          src="@/assets/images/common/logo-icon.jpg"
          alt=""
        />
        <a @click="editUserImgAct" class="editShadow" href="javascript:;">
          <i class="el-icon-edit"></i>
          编辑头像
        </a>
      </div>
      <div class="userDataCenter">
        <h3 class="userName">你好，{{ userInfo.name }}</h3>
        <p>登录账户：{{ userInfo.loginName }}</p>
        <p>
          联系人：{{
            userInfo.linkman && userInfo.linkman !== ""
              ? userInfo.linkman
              : "暂无联系人信息"
          }}
        </p>
        <p>绑定手机：{{ userInfo.phone }}</p>
        <p>
          业务员：{{
            userInfo.salesmanName && userInfo.salesmanName !== ""
              ? userInfo.salesmanName
              : "暂无绑定业务员"
          }}
        </p>
      </div>
    </div>
    <div class="editBox">
      <div @click="editPassWord" class="editPwd">
        <i class="changePwd"></i>
        <p>修改密码</p>
      </div>
      <div @click="editBindingPhone" class="editPhone">
        <i class="changePhone"></i>
        <p>修改绑定手机</p>
      </div>
    </div>
    <div class="caozuoBox">
      <div class="caozuoList">
        <div class="caozuoItem">
          <router-link to="/orderList?state=2">
            <img
              src="../assets/images/center/userCenter/timeOrder.png"
              alt=""
            />
            <p>
              待处理订单<span>{{ pendingOrderCount }}</span>
            </p>
          </router-link>
        </div>
        <div class="caozuoItem">
          <router-link to="/orderList?state=3">
            <img
              src="../assets/images/center/userCenter/successOrder.png"
              alt=""
            />
            <p>已完成订单<span></span></p>
          </router-link>
        </div>
        <div class="caozuoItem">
          <router-link to="/ticketList">
            <img src="../assets/images/center/userCenter/ticket.png" alt="" />
            <p>我的优惠券<span></span></p>
          </router-link>
        </div>
        <div class="caozuoItem">
          <router-link to="/myWallet">
            <img src="../assets/images/center/userCenter/balance.png" alt="" />
            <p>
              账户余额<span>{{ balanceData.balanceMoney }}</span>
            </p>
          </router-link>
        </div>
        <!-- <div class="caozuoItem">
          <router-link to="/center/MyLuckDrawTicket">
            <img src="../assets/images/center/userCenter/balance.png" alt="" />
            <p>
              我的抽奖券<span></span>
            </p>
          </router-link>
        </div> -->
        <div class="caozuoItem">
          <router-link to="/integralCenter">
            <img src="../assets/images/center/userCenter/money.png" alt="" />
            <p>
              我的积分<span>{{ userIntegral }}</span>
            </p>
          </router-link>
        </div>
      </div>
      <div class="tipList">
        <p>
          登录密码：安全性高的密码可以使账号更安全。建议您定期更换密码，且设置一个包含数字和字母，并长度超过6位以上的密码
        </p>
        <p>
          绑定手机：绑定手机后，即可将登录账号与手机号进行关联，方便验证账号的安全性，如找回密码等。
        </p>
      </div>
    </div>
    <div v-if="editStatus" class="editMask">
      <div class="mask"></div>
      <div v-if="editType === 1" class="editContentBox">
        <div class="editPwdBox editContent">
          <div class="editHeader">
            修改密码
            <i class="el-icon-close" @click="closeEditPwd"></i>
          </div>
          <div class="changePwdMain">
            <div class="labelItem">
              <p class="labelLeft">原密码：</p>
              <div class="labelRight">
                <input
                  @blur="verifyPwd(1)"
                  @keyup.enter="enterKeyUp(1)"
                  v-model="oldPwd"
                  type="password"
                />
              </div>
            </div>
            <div class="tip tip1">{{ verifyPwdMsg.tip1 }}</div>
            <div class="labelItem">
              <p class="labelLeft">新密码：</p>
              <div class="labelRight">
                <input
                  @blur="verifyPwd(2)"
                  @keyup.enter="enterKeyUp(2)"
                  v-model="newPwd"
                  type="password"
                />
              </div>
            </div>
            <div class="tip tip2">{{ verifyPwdMsg.tip2 }}</div>
            <div class="labelItem">
              <p class="labelLeft">确认密码：</p>
              <div class="labelRight">
                <input
                  @blur="verifyPwd(3)"
                  @keyup.enter="enterKeyUp(3)"
                  v-model="confirmPwd"
                  type="password"
                />
              </div>
            </div>
            <div class="tip tip3">{{ verifyPwdMsg.tip3 }}</div>
          </div>
          <div class="activeBtnBox">
            <a @click="closeEditPwd" class="cancelBtn" href="javascript:;"
              >取消</a
            >
            <a @click="saveChangePwd" class="saveBtn" href="javascript:;"
              >保存</a
            >
          </div>
        </div>
      </div>

      <div v-else-if="editType === 2" class="editContentBox">
        <div class="editBindingBox editContent">
          <div class="editHeader">
            修改绑定手机
            <i class="el-icon-close" @click="closeBinding"></i>
          </div>
          <div class="changeBindingMain">
            <div class="labelItem labelItem2" style="margin-bottom: 10px">
              <p class="dxTip">点击按钮后，验证码短信将发送到：</p>
            </div>
            <div class="labelItem labelItem2">
              <p class="phoneLeft">+187****0312</p>
              <div class="labelRight">
                <button class="sendCodeBtn">发送验证码</button>
              </div>
            </div>
            <div class="tip tip2"></div>
            <div class="labelItem labelItem2">
              <p class="labelLeft yzm">验证码:</p>
              <div class="labelRight">
                <input type="text" />
              </div>
            </div>
            <div class="tip tip3"></div>
          </div>
          <div class="activeBtnBox">
            <a @click="closeBinding" class="cancelBtn" href="javascript:;"
              >取消</a
            >
            <a class="saveBtn" @click="nextBildingClick" href="javascript:;"
              >下一步</a
            >
          </div>
        </div>
      </div>
      <div v-else-if="editType === 3" class="editContentBox">
        <div class="editBindingBox editContent">
          <div class="editHeader">
            修改绑定手机
            <i class="el-icon-close" @click="closeBinding"></i>
          </div>
          <div class="changeBindingMain2">
            <div class="labelItem labelItem2" style="margin-top: 25px">
              <p class="labelLeft yzm">手机号:</p>
              <div class="labelRight">
                <input
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  type="text"
                />
              </div>
            </div>
            <div class="labelItem labelItem2" style="margin-top: 25px">
              <p class="labelLeft yzm">验证码:</p>
              <div class="labelRight">
                <input style="width: 80px; margin-right: 10px" type="text" />

                <button class="sendCodeBtn">发送验证码</button>
              </div>
            </div>
          </div>
          <div class="activeBtnBox">
            <a @click="closeBinding" class="cancelBtn" href="javascript:;"
              >取消</a
            >
            <a @click="saveBildingPhone" class="saveBtn" href="javascript:;"
              >保存</a
            >
          </div>
        </div>
      </div>
    </div>
    <edit-user-img
      @uploadImgSuccess="uploadImgSuccess"
      ref="userImg"
    ></edit-user-img>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAction, postAction } from "../api/manage";
import { getUserInfo } from "../assets/js/common/common";
import EditUserImg from "../components/common/EditUserImg.vue";
import { USER_INFO } from "../store/mutation-types";
export default {
  components: { EditUserImg },
  name: "MyAccount",
  data() {
    return {
      editStatus: false,
      editType: 1,
      userInfo: null,
      oldPwd: "", // 旧密码
      newPwd: "", // 新密码
      confirmPwd: "", // 确认新密码
      verifyPwdMsg: {
        tip1: "",
        tip2: "",
        tip3: "",
      },
      userIntegral: 0, // 用户积分
      balanceData: {
        balanceMoney: 0,
        ticketMoney: 0,
      }, // 余额药券数据
    };
  },
  created() {
    let userInfo = getUserInfo();
    if (!userInfo) {
      this.$router.push("/login");
    } else {
      this.userInfo = userInfo;

      this.getCustomerBalance();
      this.getUserIntegral();
    }

    this.getPendingOrderCount();
  },
  computed: {
    ...mapGetters(["pendingOrderCount"]),
  },
  methods: {
    ...mapActions(["Logout"]),
    getPendingOrderCount() {
      let _this = this;
      let url = "/saleOrder/saleOrder/querySaleOrderNum";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let waitConfirmNum = resData.waitConfirmNum;
          let waitPaymentNum = resData.waitPaymentNum;
          let pendingOrderCount = waitConfirmNum + waitPaymentNum;
          _this.$store.commit("PENDING_ORDER_COUNT", pendingOrderCount);
        }
      });
    },

    // 获取用户的积分数据
    async getUserIntegral() {
      let _this = this;
      let url = "/integral/customerIntegral/queryForIntegral";
      let res = await getAction(url);
      console.log("获取用户的积分数据为+++++", res);
      if (res.code === 200 && res.success) {
        let resData = res.result;
        _this.userIntegral = resData.integral;
      }
    },
    // 获取用户余额和药券数据
    async getCustomerBalance() {
      let _this = this;
      let url = "/balance/customerBalance/query";
      let res = await getAction(url);
      if (res.code === 200 && res.success) {
        console.log("获取用户余额药券的数据为++++++", res);
        let resData = res.result;
        if (resData && resData !== null) {
          _this.balanceData.balanceMoney = resData.balanceMoney;
          _this.balanceData.ticketMoney = resData.ticketMoney;
        }
      }
    },
    // 修改用户头像
    editUserImgAct() {
      this.$refs.userImg.dialogTableVisible = true;
    },
    // 图片修改成功
    uploadImgSuccess(imgInfo) {
      this.userInfo.img = imgInfo;
      let userInfo = this.userInfo;
      let encodeUserInfo = this.$base64.encode(JSON.stringify(userInfo));
      this.$ls.set(USER_INFO, encodeUserInfo, 24 * 60 * 60 * 1000);
    },
    // 修改用户绑定的手机号
    editBindingPhone() {
      this.editStatus = true;
      this.editType = 2;
    },
    // 修改用户密码
    editPassWord() {
      this.editStatus = true;
      this.editType = 1;
    },
    // 取消修改密码
    closeEditPwd() {
      this.editStatus = false;
    },
    // 密码输入款验证
    verifyPwd(type) {
      let oldPwd = this.oldPwd;
      let newPwd = this.newPwd;
      let confirmPwd = this.confirmPwd;
      let passwordValue = null;
      if (type == 1) {
        passwordValue = oldPwd;
      } else if (type == 2) {
        passwordValue = newPwd;
      } else if (type == 3) {
        passwordValue = confirmPwd;
      }
      // 首先验证密码是否为空和符合规则
      if (passwordValue == "") {
        this.verifyPwdMsg["tip" + type] = "*密码不能为空！";
      } else {
        let re = /^\w+$/;
        if (re.test(passwordValue)) {
          this.verifyPwdMsg["tip" + type] = "";
        } else {
          this.verifyPwdMsg["tip" + type] = "*密码只能包含字母、数字和下划线！";
        }
      }
      // 验证新密码和旧密码不能一致
      if (oldPwd !== "" && newPwd !== "" && oldPwd === newPwd) {
        this.verifyPwdMsg["tip2"] = "*新密码和旧密码不能一致！";
      }
      // 在验证确认密码和新密码能否一致
      if (confirmPwd !== "" && newPwd !== "" && confirmPwd !== newPwd) {
        this.verifyPwdMsg["tip3"] = "*两次输入的新密码不一致！";
      }
    },
    // 回车操作
    enterKeyUp(type) {
      let oldPwd = this.oldPwd;
      let newPwd = this.newPwd;
      let confirmPwd = this.confirmPwd;
      if (oldPwd !== "" && newPwd !== "" && confirmPwd !== "") {
        this.saveChangePwd();
      }
    },
    // 取消修改绑定手机
    closeBinding() {
      this.editStatus = false;
    },
    // 修改绑定手机号下一步操作
    nextBildingClick() {
      this.editType = 3;
    },
    // 保存修改密码操作
    saveChangePwd() {
      let _this = this;
      // 再次验证三个密码框
      this.verifyPwd(1);
      this.verifyPwd(2);
      this.verifyPwd(3);
      let oldPwd = this.oldPwd;
      let newPwd = this.newPwd;
      let confirmPwd = this.confirmPwd;
      let verifyPwdMsg = this.verifyPwdMsg;
      if (
        verifyPwdMsg.tip1 == "" &&
        verifyPwdMsg.tip2 == "" &&
        verifyPwdMsg.tip3 == ""
      ) {
        console.log("密码输入符合条件");
        let url = "/customer/customer/updateCustomerPassword";
        let data = {
          oldPassword: _this.$md5(oldPwd).substring(8, 24),
          newPassword: _this.$md5(newPwd).substring(8, 24),
          confirmPassword: _this.$md5(confirmPwd).substring(8, 24),
        };
        postAction(url, data, true).then((res) => {
          console.log("修改密码返回的数据为++++++", res);
          if (res.code == 200 && res.success) {
            _this.$alert("密码修改成功请重新登录！", "提示信息", {
              type: "success",
            });
            _this.Logout();
          }
        });
      } else {
        console.log("密码不符合条件");
      }
      // this.editStatus = false;
    },
    // 保存更改后的手机号
    saveBildingPhone() {
      this.editStatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/myAccount/myAccount";
</style>
