<template>
  <div class="order-placing-box">
    <!-- 满额必得 -->
    <div class="me-box">
      <h2 class="me-title">购买指定商品</h2>
      <div class="me-main">
        <p @click="searchBrandId(340)" class="activity-tip">{{ categoryMoney.categoryMoneyDistance1 > 0 ?
          `距一类商品满额还需：${categoryMoney.categoryMoneyDistance1} 元` : `恭喜您，一类商品采购任务已达标！` }}</p>
        <p @click="searchBrandId(341)" class="activity-tip">{{ categoryMoney.categoryMoneyDistance2 > 0 ?
          `距二类商品满额还需：${categoryMoney.categoryMoneyDistance2} 元` : `恭喜您，二类商品采购任务已达标！` }}</p>
        <p @click="searchBrandId(342)" class="activity-tip">{{ categoryMoney.categoryMoneyDistance3 > 0 ?
          `距三类商品满额还需：${categoryMoney.categoryMoneyDistance3} 元` : `恭喜您，三类商品采购任务已达标！` }}</p>
      </div>
      <div class="me-bg"></div>
    </div>
    <!-- 抽奖列表 -->
    <div class="choujiang">
      <!-- 转盘抽奖活动 -->
      <div class="lottery-item" v-for="(item, index) of lotteryList" :key="'lottery' + index">
        <div @click="navtToLuckDrawNew(item.id)" class="lottery-box">
          <h3 class="title">{{ item.name }}</h3>
          <p class="activity-tip">{{ `下单金额满${item.ticketNumber}元参与抽奖` }}</p>
          <img v-if="item.type == '1'" class="zhuanpan" src="../../assets/images/activity/yabang/zhuanpan.png" alt="">
          <img v-if="item.type == '2'" class="jindan" src="../../assets/images/activity/yabang/jindan.png" alt="">
        </div>
      </div>
    </div>
    <!-- END 抽奖列表 -->
    <div class="customer-integral">
      <p>下单金额已满：{{ customerIntegral.moneySum }}元</p>
      <p>可抽奖金额：{{ customerIntegral.money }}元</p>
    </div>
    <div class="cjhc-box">
      <div class="title">参会厂家</div>
      <div class="brand-list clear">
        <div @click="searchBrandId(item.id)" class="brand-item" v-for="(item, index) in brandList" :key="item.id">
          <img :src="item.title_url" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from '../../api/manage';

export default {
  name: 'OrderPlacing',
  props: {
    categoryMoney: {
      type: Object,
      default: function() {
        return {

        }
      }
    }
  },
  data() {
    return {
      lotteryList: [],
      brandList: [],
      // 客户订货分数据
      customerIntegral: {
        moneySum: 0, //总积分              
        money: 0,  //剩余积分        
        moneyXianshang: "", //线上积分            
        moneyXianxia: "", // 线下积分            
        moneyUse: "" //已使用积分
      },
    }
  },
  created() {
    // 判断当前用户是否能参与订货会活动
    this.getCustomerAuth();

  },
  methods: {
    // 获取当前用户是否具有订货会资格
    async getCustomerAuth() {
      let url = '/yushou/yushou/getCustomerCan';
      let res = await getAction(url);
      console.log("获取当前客户的参加身份", res)
      if (res.code == 200 && res.message === '1') {
        this.getCustomerIntegral();
        // 获取抽奖活动列表数据
        this.getLuckDrawList();
        // 获取主板厂家
        this.getHomeBrandList();
      } else {
        this.$router.push('/');
        this.$message({
          message: '您当前不能参与该活动',
          offset: 400,
          duration: 3000,
          type: 'error'
        })
      }
    },
    // 搜索专区品牌编码
    searchBrandId(brandId) {
      if (brandId === 340 || brandId === 341 || brandId === 342) {
        let routeUrl = this.$router.resolve({
          name: 'classifiedProducts', query: {
            productLabelList: brandId
          }
        })
        window.open(routeUrl.href, '_blank')
      } else {
        this.$router.push({
          name: 'brandProductArea', query: {
            productLabelList: brandId
          }
        })
      }
    },
    // 获取客户订货会积分
    getCustomerIntegral() {
      let _this = this;
      let url = '/yushou/yushou/getJifen';
      getAction(url).then(res => {
        console.log("获取当前用户的订货会积分为++++++", res)
        if (res.code == 200 && res.success) {
          _this.customerIntegral = res.result;
        } else {
          _this.$message({
            message: '请求用户订货会积分失败！',
            offset: 400,
            duration: 2000,
            type: 'error'
          })
        }
      })
    },
    // 获取客户三类商品积分
    getProductIntegral() {
      let _this = this;
      let url = '/yushou/yushou/get1';
      getAction(url).then(res => {
        console.log("获取客户三类商品积分为++++++", res)
        if (res.code == 200 && res.success) {
          _this.categoryMoney = res.result;
        } else {
          _this.$message({
            message: '获取定制商品积分失败！',
            offset: 400,
            duration: 2000,
            type: 'error'
          })
        }
      })
    },
    // 获取当前订货会的抽奖活动
    getLuckDrawList() {
      let _this = this;
      let url = "/yushou/yushou/getCjList";
      getAction(url).then(res => {
        console.log("获取抽奖活动为++++++", res)
        if (res.code == 200 && res.success) {
          _this.lotteryList = res.result;
        } else {
          _this.$message({
            message: '获取抽奖活动列表失败！',
            offset: 400,
            duration: 2000,
            type: 'error'
          })
        }
      })

    },
    // 获取主板厂家
    getHomeBrandList() {
      let _this = this;
      let url = '/yushou/yushou/getZb';
      getAction(url).then(res => {
        console.log("获取主板厂家为++++++", res)
        if (res.code == 200 && res.success) {
          _this.brandList = res.result;
        } else {
          _this.$message({
            message: '获取主板厂家列表失败！',
            offset: 400,
            duration: 2000,
            type: 'error'
          })
        }
      })
    },
    navtToLuckDrawNew(id) {
      this.$router.push({ path: '/luckDrawNew', query: { activityId: id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.order-placing-box {
  width: 1188px;
  // height: 2566px;
  background: url("~@/assets/images/activity/yabang/main-bg.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 85px;
  padding-bottom: 120px;

  .me-box {
    width: 1018px;
    height: 247px;
    margin-top: 53px;
    background-size: 100% 100%;
    position: relative;
    background: url("~@/assets/images/activity/yabang/me-border.png");

    .me-bg {
      width: 446px;
      height: 238px;
      background: url("~@/assets/images/activity/yabang/me-bg-img.png");
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      bottom: 29px;
    }

    .me-main {
      padding-left: 50px;
      padding-top: 55px;

      p {
        max-width: 480px;
        @include ellipsis;
        font-size: 28px;
        line-height: 50px;
        font-weight: bold;
        color: #E60005;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .me-title {
      width: 359px;
      height: 80px;
      background: url("~@/assets/images/activity/yabang/me-title-bg.png");
      background-size: 100% 100%;
      position: absolute;
      top: -29px;
      left: 74px;
      font-size: 30px;
      letter-spacing: 10px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF3E0;
    }
  }

  .choujiang {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 35px;
    justify-content: space-around;

    .lottery-item {
      width: 466px;
      height: 472px;
      background: url("~@/assets/images/activity/yabang/cj-border-bg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      cursor: pointer;

      .lottery-box {
        .zhuanpan {
          display: block;
          width: 391px;
          height: 333px;
          margin: 15px auto;
        }

        .jindan {
          display: block;
          width: 425px;
          height: auto;
          margin: 0 auto;
        }
      }

      .title {
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -195px;
        width: 390px;
        height: 76px;
        background: url("~@/assets/images/activity/yabang/cj-title-bg.png") no-repeat;
        background-size: 100% 100%;
        font-size: 36px;
        font-weight: bold;
        color: #FFF9DF;
        line-height: 60px;
        text-align: center;
        letter-spacing: 10px;
        text-indent: 10px;
      }

      .activity-tip {
        width: 100%;
        margin: 0 auto;
        @include ellipsis;
        font-size: 21px;
        font-weight: 500;
        color: #D77A01;
        margin-top: 82px;
        line-height: 1;
        text-align: center;
      }
    }
  }

  .customer-integral {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: 50%;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: #D77A01;
      line-height: 80px;
    }
  }

  .cjhc-box {
    width: 1026px;
    height: 1404px;
    background: url("~@/assets/images/activity/yabang/chcj-bg.png") no-repeat;
    background-size: 100% 100%;

    .title {
      font-size: 65px;
      text-align: center;
      font-weight: bold;
      color: #FFFFFF;
      letter-spacing: 10px;
      text-indent: 10px;
      line-height: 150px;
    }

    .brand-list {
      @include clear;
      width: 100%;
      padding: 20px;

      .brand-item {
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 197px;
        height: 197px;
        background: url("~@/assets/images/activity/yabang/pinpai-box-bg.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        img {
          max-width: 160px;
          max-height: 160px;
          width: auto;
          height: auto;

          // &:hover {
          //   border: 4px solid $base-color;
          // }
        }
      }
    }
  }
}
</style>