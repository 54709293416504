<template>
  <div class="product-content">
    <div class="product-box">
      <meeting-product ref="productList" />
    </div>
  </div>
</template>

<script>
import { getAction } from '../../api/manage'
import MeetingProduct from './MeetingProduct.vue'
export default {
  components: { MeetingProduct },
  name: 'BrandProductArea',
  data() {
    return {
      brandId: '', // 品牌编码
      recommendBrand: [],
      productList: [],
      selectedBrandId: '', // 选中的品牌ID为
    }
  },
  methods: {
    async getBrandList() {
      let url = '/yushou/yushou/getFb'
      let res = await getAction(url);
      console.log("获取当前副厂品牌数据", res);
      if (res.code == 200 && res.success) {
        this.recommendBrand = res.result;
      }
    },
    searchBrandId(item) {
      if (this.selectedBrandId == item.id) {
        this.selectedBrandId = ''
      } else {
        this.selectedBrandId = item.id
      }
      this.$refs.productList.searchBrandId(this.selectedBrandId)
    },
    searchProduct(searchVal) {
      this.$refs.productList.searchProduct(searchVal, true);
    }
  }
}
</script>

<style lang="scss" scoped>
.product-content {
  min-width: 1188px;
  @include clear;
  .product-box {
    width: 1190px;
    float: right;
    overflow: hidden;
  }
  ::v-deep .imglistbox li {
    margin-right: 10px !important;
    margin-bottom: 10px;
    &:nth-child(5n) {
      margin-right: 0px !important;
    }
  }
}
</style>