<template>
  <div class="wantBuyBox">
    <div class="header">
      求购
    </div>
    <div class="content">
      <div class="formdata">
        <div class="name">
          <p>商品名称</p>
          <input v-model="productName" type="text">
        </div>
        <div class="format">
          <p>规格</p>
          <input v-model="format" type="text">
        </div>
        <div class="manufacturer">
          <p>生产厂家</p>
          <input v-model="manufacturer" type="text">
        </div>
        <div class="wantBuyNumber">
          <p>需求数量</p>
          <input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="amount" type="text">
        </div>
        <div class="remark">
          <textarea v-model="remarks" placeholder="备注（选填）" name="" id="" cols="30" rows="10"></textarea>
        </div>
        <div class="submitBox">
          <a @click="submitWantBuy" href="javascript:;">提交</a>
        </div>
      </div>
      <div class="qiugouList" v-loading="loadingStatus">
        <div v-for="(item,index) of wantBuyList" :key="'wantBuyItem'+ index" class="itemList">
          <div class="qgxx">
            <p>求购信息：{{`${item.productName}/${item.format}，`}}{{`${item.manufacturer}，`}}{{`需求数量：${item.demandAmount}，`}}{{`（${item.leaveMessage}）`}}</p>
            <p class="qgsj">{{item.demandTime}}</p>
          </div>
          <div v-if="item.replyStatus === 1" class="hfxx">
            <p>回复信息：{{item.replyMessage}}</p>
            <p class="qgsj">{{item.updateTime}}</p>
          </div>
        </div>
      </div>
      <div class="pageFooter">
        <el-pagination
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-size="pageSize"
          :background="true"
          layout="total, prev, pager, next, jumper"
          :total="totalPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from '../api/manage'
export default {
  name: "WantToBuyProduct",
  data () {
    return {
      productName: '', // 产品名称，
      productId: '', // 产品id，
      format: '', // 产品规格
      manufacturer: '', // 生产厂家
      amount: '', // 求购数量
      linkMen: '', // 联系人
      linkPhone: '', // 联系电话
      remarks: '', // 备注
      wantBuyList: [], // 求购列表
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, // 总页数
      loadingStatus: false,
    }
  },
  created () {
    let productName = this.$route.query.productName
    let manufacturer = this.$route.query.manufacturer
    let format = this.$route.query.format
    let productId = this.$route.query.productId
    this.productId = productId
    this.format = format
    this.manufacturer = manufacturer
    this.productName = productName
    this.getListData()
  },
  methods: {
    // 提交求购
    submitWantBuy () {
      let _this = this
      let productId = this.productId
      let linkman = this.linkMen
      let phone = this.linkPhone
      let productName = this.productName
      let productFormat = this.format
      let productManufacturer = this.manufacturer
      let productQuantity = this.amount
      let leaveMessage = this.remarks
      if (productName === '' || productFormat === '' || productManufacturer === '' || productQuantity === '') {
        this.$alert('求购必填选项不能为空！', '提示信息：', { type: 'warning' })
      } else {
        let data = {
          productId: productId, // 产品ID
          linkman: linkman, // 联系人
          phone: phone, // 联系电话
          productName: productName, // 求购药品名称
          format: productFormat, // 药瓶规格
          manufacturer: productManufacturer, // 生产厂家
          demandAmount: productQuantity, // 求购数量
          leaveMessage: leaveMessage, // 求购留言
        }
        let url = '/customer/customerDemandArea/add'
        postAction(url,data).then(res => {
          if(res.code === 200 && res.success){
            this.$alert('求购信息已提交给后台','信息提示：', {type: 'success'})
            _this.getListData()
            _this.clearFormData()
          }
        })
      }
    },
    // 清空求购信息
    clearFormData() {
      this.productId = ""
      this.linkMen = ""
      this.linkPhone = ""
      this.productName = ""
      this.format = ""
      this.manufacturer = ""
      this.amount = ""
      this.remarks = ""
    },
    // 跳转页数为
    handleCurrentChange (val) {
      this.pageSize = val
      this.getListData()
    },
    // 获取求购单列表
    getListData () {
      this.loadingStatus = true
      let pageNo = this.pageNo
      let pageSize = this.pageSize
      let _this = this
      let url = '/customer/customerDemandArea/list'
      let data = {
        pageNo: pageNo,
        pageSize: pageSize
      }
      getAction(url,data).then(res => {
        if(res.code === 200 && res.success){
          _this.wantBuyList = res.result.records
          _this.loadingStatus = false
        }
      })

    },
    // 删除求购单列表
    deleteThis (item) {
      let _this = this
      this.$confirm('是否删除当前记录?', '删除提示:', {
        type: 'warning'
      }).then((res) => {
        let data = {
          id: item.id
        }
        let url = '/customer/customerDemandArea/delete'
        deleteAction(url,data).then(res => {
          if(res.code === 200 && res.success){
            _this.pageNo = 1
            _this.getListData()
          }
        })

      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/center/wantToBuyProduct"
</style>