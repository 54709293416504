<template>
  <div class="giftRightBox">
    <div class="giftListBox">
      <ul class="title">
        <li class="type">反馈类型</li>
        <li class="detail">反馈内容</li>
        <li class="dateTime">反馈时间</li>
        <li class="backText">回复</li>
        <li class="dateTime">反馈时间</li>
      </ul>
      <div class="listMain" v-if="feedbackList.length > 0" v-loading="listLoading">
        <ul class="listContent" v-for="(item,index) of feedbackList" :key="'giftItem' + item.id + index">
          <li class="type">{{renderBackType(item.backType)}}</li>
          <li class="detail">{{item.val}}</li>
          <li class="dateTime">{{item.createTime}}</li>
          <li class="backText">{{item.replyMessage}}</li>
          <li class="dateTime">{{item.updateTime}}</li>
        </ul>
      </div>
      <div v-else-if="feedbackList == 0" class="dataNone">
        <div class="dataImg "></div>
      </div>
    </div>
     <div class="pageFooter">
      <el-pagination
        background
        :hide-on-single-page="true"
        v-if="pageCount > 0"
        layout="prev, pager, next, jumper"
        :current-page="pageNo"
        @current-change="currentChange"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";
export default {
  name: "FeedbackList",
  data() {
    return {
      feedbackList: [],
      listLoading: false,
      pageCount: 0, // 总页数
      total: 0, // 总页数
      pageNo: 1, // 当前页
      pageSize: 10, // 当前页
    };
  },
  created() {
    this.getFeedbackList();
  },
  methods: {
    renderBackType(type) {
      if(type == 1) {
        return '意见建议'
      } else if(type == 2) {
        return '药品咨询'
      } else if(type == 3) {
        return '服务投诉'
      } else if(type == 4) {
        return '商城使用问题'
      }
    },
    getFeedbackList() {
      let _this = this;
      this.listLoading = true;
      let url = "customer/customerFeedback/list";
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      // 给后台传的数据
      let data = {
        customerId: '001',
        pageNo: pageNo,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.listLoading = false;
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.feedbackList = resData.records;
          _this.pageNo = resData.current;
          _this.total = resData.total;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/center/giftList";
.type {
  width: 130px;
}
.dateTime {
  width: 185px;
}
.backText {
  width: 200px;
}
.detail,.backText {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>