<template>
  <div class="luckDrawBox">
    <header-top></header-top>
    <div class="contentTop container">
      <div class="luckMain container_box">
        <div class="logo">
          <router-link class="logoLink" to="/index">
            <img v-if="companyConfigData && companyConfigData.pcWhiteLogoUrl && companyConfigData.pcWhiteLogoUrl !== ''"
            :src="companyConfigData.pcWhiteLogoUrl" alt="江药商城"/>
            <img v-else src="@/assets/images/common/logo2.png" alt="江药商城" />
          </router-link>
        </div>
        <div class="zhuanpan">
          <div class="giftIconListBox">
            <div class="giftItem" v-for="(item, index) of activityInfo" :key="'giftItem' + index">
              <img v-lazy="item.iconUrl" alt="" />
            </div>
          </div>
          <div class="center">
            <div :style="`transform: rotate(${times}deg);`" class="zhizhen"></div>
            <div :class="{ active: btnActive }" @click="buttonClick" class="anniu">
              <div class="begin"></div>
              <p>剩余抽奖次数：{{ residualTicket }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contentBottom"></div>
    <div class="container_box">
      <div class="activeBottom">
        <div class="figtList">
          <div v-for="(item, index) of newActivityInfo" :key="'giftImg' + index" class="giftItem">
            <div>
              <img v-lazy="item.iconUrl" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="hdgz">
            <h3>活动规则</h3>
            <p>
              {{ activityData.introduce }}
            </p>
          </div>
          <div class="zjmd">
            <h3>中奖名单</h3>
            <div class="giftNotice">
              <div class="tipList" ref="winning" :style="`height: ${config.height * winningListNew.length}px; 
              top: -${top}px;`">
                <p v-for="(item, index) of winningList" :key="'wining' + index">
                  <span :title="item.customerName">{{ item.customerName }}</span>
                  <span :title="item.giftName">奖品：{{ item.giftName }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="taggletMain" class="zhongjianBox">
      <div class="mask"></div>
      <div class="zhongjiangMain">
        <i class="el-icon-circle-close" @click="closeMask"></i>
        <div class="content">
          <h3 v-if="userGiftData.emptyFlag" class="lpxx">很遗憾，您没有中奖</h3>
          <h3 v-else class="lpxx">恭喜您，获得了</h3>
          <div v-if="!userGiftData.emptyFlag" class="giftName">
            {{ userGiftData.name }}
          </div>
          <p v-if="userGiftData.emptyFlag" class="tip">
            最重要的是开心喽
            <span class="xl"></span>
          </p>
          <p class="tip">
            真是太棒了
            <span class="dz"></span>
          </p>
          <a @click="closeMask" class="anniu" href="javascript:;">我知道了</a>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAction } from "../api/manage";
import { accAdd } from "../assets/js/common/common";
import Footer from "../components/common/Footer.vue";
import HeaderTop from "../components/common/HeaderTop.vue";
export default {
  components: { HeaderTop, Footer },
  name: "LuckDraw",
  data() {
    return {
      activityId: null,
      activityData: {}, // 活动数据
      residualTicket: 0,
      isGamble: 1,
      activityInfo: [], // 活动礼品数据
      ticketData: [], // 活动奖券数据
      accountId: "", // 用户账户id
      // 抽奖动画
      timer: null, // setTimeout的ID，用clearTimeout清除
      speed: 0.25, // 初始旋转角度
      times: 0, // 转动角度
      cycle: 20, // 转动基本次数：即至少需要转动多少次再进入抽奖环节
      count: 0, // 转动的回合数
      prize: -1, // 中奖位置
      click: false, // 是否已经点击了按钮
      btnActive: false, // 按钮按下去动画操作
      taggletMain: false, // 弹出层显示隐藏效果
      userGiftData: "", // 最终抽到的礼品数据
      timers: null, // 中奖名单定时器
      winningList: [],
      config: {
        height: 35,
        current: 1,
      },
    };
  },
  created() {
    let activityId = this.$route.query.activityId;
    this.activityId = activityId;
    // 获取奖品数据
    this.getActiveData();
    // 获取中奖客户名单滚动
    this.getWinningList()
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
    top() {
      return this.config.height * this.config.current;
    },
    winningListNew() {
      let winningList = this.winningList;
      let newList = [];
      if (winningList && winningList.length > 0) {
        newList = [
          winningList[winningList.length - 1],
          winningList[winningList.length - 2],
          winningList[winningList.length - 3],
          winningList[winningList.length - 4],
          winningList[winningList.length - 5],
          ...winningList,
          winningList[0],
          winningList[1],
          winningList[2],
          winningList[3],
          winningList[4],
        ];
      }
      return newList;
    },
    newActivityInfo() {
      let activityInfo = this.activityInfo
      let newActivityInfo = activityInfo.filter(item => {
        return item.emptyFlag == 0
      })
      return newActivityInfo
    }
  },
  methods: {
    // 首次请求,活动数据
    getActiveData() {
      let _this = this;
      let activityId = this.activityId;
      let data = {
        id: activityId,
      };
      let url = "/activityGamble/activityGamble/queryById";
      getAction(url, data, true).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          console.log("当前抽奖活动返回的数据为+++++", resData);
          _this.activityInfo = resData.activityGambleDetailList;
          _this.activityData = resData;
          _this.residualTicket = resData.ticketNum;
          // 抽奖活动的奖品已结束 不允许抽奖
          if (resData.isGamble === 0 || !resData.isGamble) {
            _this.isGamble = 0;
            _this.residualTicket = 0
          }
        }
      });
    },
    // 获取中奖客户列表
    getWinningList() {
      let _this = this
      let url = "/gift/customerGift/listByNew"
      let activityId = this.activityId
      let data = {
        activityId: activityId
      }
      getAction(url, data).then(res => {
        console.log("获取奖券列表的数据为++++", res)
        if (res.code == 200 && res.success) {
          _this.winningList = res.result
          _this.timers = setInterval(this.animation, 5000);
        }
      })
    },
    // 抽奖按钮点击
    buttonClick() {
      let _this = this;
      let isGamble = this.isGamble;
      if (isGamble) {
        // 判断当前是否可点击
        let click = this.click;
        // 按钮按下弹起效果
        this.btnActive = true;
        setTimeout(function () {
          _this.btnActive = false;
        }, 200);
        let ticketNum = this.residualTicket;
        // 如果click为true  这正在转动  不可点击
        if (click || ticketNum === 0) {
          if (ticketNum === 0) {
            _this.$alert("抱歉,您没有可用抽奖券!", "提示信息:", {
              type: "info",
            });
          }
          return false;
        } else {
          _this.click = true;
          // 初始转速为每秒动10次
          _this.speed = 0.25;
          // 抽奖动画操作开始
          _this.zhuangdongAgain();
        }
      } else {
        this.$alert("很遗憾，活动已结束！", "提示信息", { type: "error" });
      }
    },
    // 转动指针
    zhuangdongAgain() {
      let _this = this;
      // 指针转动的角度
      let times = parseFloat(this.times);
      // 转动角度
      let spend = this.speed;
      // 转动的回合
      let count = this.count;
      // 设置的转动回合
      let cycle = this.cycle;
      // 中奖位置
      let prize = this.prize;
      let prizeDeg = 0;
      if (prize > 0) {
        prizeDeg = -101.25 + 22.5 * prize;
      }
      // 转动到两侧  折回重新转动
      this.roll();
      // 如果转动的回合大于设置的转动回合并且中奖的角度等于当前角角度，停止转动，弹出中奖效果，按钮重置可操作性
      if (prize > 0 && count > cycle && times == prizeDeg) {
        // 结束转动
        clearTimeout(_this.timer);
        // 显示中奖弹出层
        setTimeout(function (e) {
          _this.taggletMain = true;
          // 中奖数值变为-1
          _this.prize = -1;
          _this.count = 0,
          // 转动角度初始化
          _this.times = 0;
          // 按钮变成可点击
          _this.click = false;
        }, 1000);
      } else {
        // 达到转动位置时  请求后台客户中奖结果
        if (count === cycle) {
          // 请求后台客户中奖信息
          _this.getGiftData();
        }
        _this.times = accAdd(times, spend);

        _this.timer = setTimeout(function () {
          _this.zhuangdongAgain();
        }, 1);
      }
    },
    // 转动到两改变旋转方向
    roll() {
      let times = this.times;
      if (times >= 78.75) {
        this.speed = -0.25;
        this.count = this.count + 5;
        console.log("半圈了");
      } else if (times <= -78.75) {
        this.speed = 0.25;
        this.count = this.count + 5;
        console.log("1圈了");
      }
    },
    // 获取用户中奖信息
    getGiftData() {
      let _this = this;
      let url = "/activityGamble/activityGamble/gamble";
      let activityId = this.activityId;
      let data = {
        id: activityId,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.residualTicket = parseInt(_this.residualTicket) - 1;
          _this.prize = parseInt(resData.giftDeg);
          _this.userGiftData = resData;
          _this.getWinningList()
        }
      });
    },
    // 关闭礼品展示层
    closeMask() {
      this.taggletMain = false;
    },
    // 返回礼品图片
    giftImg(item) {
      if (item.picUrl === "" || typeof item.picUrl === "undefined") {
        return "";
      } else {
        return item.picUrl;
      }
    },
    // 返回礼品图标
    giftImgIcon(item) {
      if (item.iconUrl === "" || typeof item.iconUrl === "undefined") {
        return "";
      } else {
        return item.iconUrl;
      }
    },
    // 礼品提示
    giftImgAlt(item) {
      if (item.giftName === "" || typeof item.giftName === "undefined") {
        return "";
      } else {
        return item.giftName;
      }
    },
    cleanTime(time) {
      let activityData = this.activityData;
      let startTime = activityData.startTime;
      return time;
    },
    // 资质轮播动画
    animation() {
      const $this = this;
      const length = this.winningList.length;
      let timer = null;
      const $ul = this.$refs.winning;
      if ($ul && typeof $ul !== "undefined") {
        $ul.style.transition = "1s";
        this.config.current++;
        clearTimeout(timer);
        if (this.config.current > length - 6) {
          timer = setTimeout(() => {
            $ul.style.transition = "0s";
            $this.config.current = 1;
            clearTimeout(timer);
          }, 1050);
          timer = setTimeout(() => {
            $ul.style.transition = "1s";
            clearTimeout(timer);
          }, 1100);
        }
        if (this.config.current < 1) {
          timer = setTimeout(() => {
            $ul.style.transition = "0s";
            $this.config.current = length;
            clearTimeout(timer);
          }, 1050);
          timer = setTimeout(() => {
            $ul.style.transition = "1s";
            clearTimeout(timer);
          }, 1100);
        }
      }
    },
  },
  unmounted() {
    clearTimeout(this.timer);
    clearTimeout(this.timers);
  },
  destroyed() {
    clearTimeout(this.timer);
    clearTimeout(this.timers);
  },
};
</script>

<style lang="scss" scpoed>
@import "../assets/styles/activity/luckDraw";
</style>