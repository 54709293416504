<template>
  <div class="list-box">
    <div class="goods-list">
      <div class="goods-content" v-for="item in 3" :key="item">
        <div class="activity-content clear">
          <div class="activity-data">
            <p class="name">江小药家庭常备组合套餐（套餐名称多行显示）</p>
            <p class="promotion-tips">
              满赠：购买20盒/瓶赠送价值超过50元的富光1.5L大容量水杯1个，购买40盒赠太阳遮雨伞1个，活动时间2023.06.01-2023.06.23。
            </p>
            <p class="act-amount">
              共4件商品
            </p>
          </div>
          <div class="activity-price">
            <p>
              <span class="label">套餐价</span>：<span class="price">￥12.56</span>
            </p>
            <p>
              <span class="label">库存</span>：<span>256套</span>
            </p>
            <p>
              1盒起购，最高限购20盒
            </p>
          </div>
          <div class="act-box">
            <p class="input-content">
              <span class="el-icon-plus"></span>
              <input type="text">
              <span class="el-icon-minus"></span>
            </p>
            <div class="jionBtnBox">
              <img src="../../assets/images/product/img/jionShop.png" />
              加入购物车
            </div>
          </div>
        </div>
        <div class="goods-scroll">
          <el-scrollbar direction="horizontal" ref="scrollbar" class="scroll-content">
            <ul class="imglistbox img-list-flex">
              <li v-for="(item, index) of productList" v-bind:key="'imgProduct' + index"
                :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }">
                <div>
                  <div class="productImgBox">
                    <router-link :to="{
                      path: '/productDetail',
                      query: { productId: item.productId },
                    }" :title="item.name" target="_blank">
                      <!-- 查看专区产品遮罩图 -->
                      <img v-if="specialAreaMaskImg && specialAreaMaskImg !== ''" :src="specialAreaMaskImg"
                        class="maskImg" alt="产品遮罩图" />
                      <!-- 查看产品标签遮罩图 -->
                      <img v-else-if="item.productLabelList && item.productLabelList.length > 0
                        && prolabelMaskImg(item.productLabelList) !== ''
                        " :src="prolabelMaskImg(item.productLabelList)" class="maskImg" alt="产品遮罩图" />
                      <img class="productImg" v-lazy="productImg(item)" />
                    </router-link>
                  </div>
                </div>
                <!--产品名称-->
                <div class="img-mingzi">
                  {{ item.name }}
                </div>
                <!--判断产品是否有库存-->
                <div v-if="!item.inventoryList || item.inventoryList.length === 0" class="proPriceContent clearfix">
                  <p class="priceBox fl" style="font-size: 17px">
                    {{ loginState ? "￥--/--" : "会员可见" }}
                  </p>
                </div>
                <div
                  v-else-if="item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1'"
                  class="proPriceContent clearfix">
                  <p class="priceBox fl" style="font-size: 17px">
                    请联系开票员
                  </p>
                </div>
                <!-- 秒杀产品显示的价格 -->
                <div v-else-if="item.productActivityPriceStrategyFlag === 1 &&
                  item.productActivityPriceStrategyDetail &&
                  item.productActivityPriceStrategyDetail.canSaleAmount
                  " class="proPriceContent clearfix">
                  <p class="priceBox fl">
                    ￥{{ item.productActivityPriceStrategyDetail.price }}
                  </p>
                  <p class="yjPriceBox fl">
                    <!-- <i class="priceName">原价:</i> -->
                    ￥{{ item.productActivityPriceStrategyDetail.originalPrice }}
                  </p>
                </div>
                <!-- 2.再判断产品是否参与折扣活动-->
                <div v-else-if="item.inventoryList[0].discountPrice &&
                  item.inventoryList[0].discountPrice !== ''
                  " class="proPriceContent clearfix">
                  <p class="priceBox fl">
                    ￥{{ item.inventoryList[0].discountPrice }}
                  </p>
                  <p class="yjPriceBox fl">
                    <!-- <i class="priceName">原价:</i> -->
                    ￥{{ item.inventoryList[0].price }}
                  </p>
                </div>
                <!-- 3.再判断产品策略里面是否定义了原价-->
                <div v-else-if="item.inventoryList[0].originalPrice &&
                  item.inventoryList[0].originalPrice !== ''
                  " class="proPriceContent clearfix">
                  <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
                  <p class="yjPriceBox fl">
                    <!-- <i class="priceName">原价:</i> -->
                    ￥{{ item.inventoryList[0].originalPrice }}
                  </p>
                </div>
                <!-- 4.显示原价 -->
                <div v-else class="proPriceContent clearfix">
                  <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
                </div>
                <div v-if="loginState && item.inventoryList && item.inventoryList.length > 0
                  " class="tipBox">
                  <el-tooltip v-if="item.productActivityPriceStrategyFlag === 1 &&
                    item.productActivityPriceStrategyDetail &&
                    item.productActivityPriceStrategyDetail.canSaleAmount
                    " class="item" effect="dark" content="该产品为秒杀产品" placement="top">
                    <span class="tip1">秒杀</span>
                  </el-tooltip>

                  <el-tooltip v-if="item.inventoryList[0].newCustomerPriceFlag &&
                    item.inventoryList[0].newCustomerPriceFlag == '1'" class="item" effect="dark" content="新用户特价产品"
                    placement="top">
                    <span class="tip3">特价</span>
                  </el-tooltip>
                  <span class="tip1" :style="item.inventoryList[0].deliveryTime === '24'
                    ? 'background-color:#009944'
                    : 'background-color:#0f4c81'
                    ">{{
    item.inventoryList[0].deliveryTime
    ? item.inventoryList[0].deliveryTime
    : "24H"
  }}</span>
                  <span class="tip2" v-if="item.inventoryList[0].priceTitle &&
                      item.inventoryList[0].priceTitle !== ''
                      ">{{ item.inventoryList[0].priceTitle }}</span>

                  <el-tooltip v-if="item.policyFlag && item.productPolicyList && item.productPolicyList.length > 0"
                    class="item" effect="dark" :content="item.productPolicyList[0].remark" placement="top">
                    <span class="tip3">促销</span>
                  </el-tooltip>
                  <el-tooltip v-if="item.inventoryList[0].nearDateFlag" class="item" effect="dark" content="该产品为近效期产品"
                    placement="top">
                    <span class="tip4">近效期</span>
                  </el-tooltip>
                  <span class="tip5" v-if="(item.productActivityPriceStrategyFlag !== 1 ||
                    item.productActivityPriceStrategyDetail.canSaleAmount ===
                    0) &&
                    item.minDiscountTicket &&
                    item.minDiscountTicket.name !== ''
                    ">
                    {{ item.minDiscountTicket.name }}
                  </span>
                  <span class="tip7">
                    换购
                  </span>

                </div>
                <div class="tipBox" v-else>
                  <span v-if="!loginState" class="tip1" style="background-color:#009944">24H</span>
                </div>
                <div style="cursor: pointer" @click="searchFatory(item.manufacturer)" class="img-compamy"
                  :title="item.manufacturer">
                  {{
                    item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== ""
                    ? item.manufacturer
                    : "暂无厂家信息！"
                  }}
                </div>
                <div class="img-guige">
                  规格：<span :title="item.format">{{ item.format }}</span>
                </div>
                <div v-if="loginState" class="img-guige">
                  有效期:
                  {{
                    item.inventoryList &&
                    item.inventoryList.length > 0 &&
                    item.inventoryList[0].endDate &&
                    item.inventoryList[0].endDate !== ""
                    ? item.inventoryList[0].endDate
                    : "暂无效期"
                  }}
                </div>
                <div v-else class="img-guige">
                  有效期: <span style="color:#fd2c17">会员可见</span>
                </div>
                <div class="img-xiaoqi">
                  <span v-if="item.inventoryList && item.inventoryList.length > 0" class="kucun">
                    库存：{{ showInventoryText(item.inventoryNum, item) }}
                  </span>
                  <span v-else-if="loginState" class="kucun">库存：暂无库存</span>
                  <span v-else class="kucun">库存：<i style="font-style:normal;color:#fd2c17">会员可见</i></span>
                  <span :title="`${item.mediumPkgSize}/${item.bigPkgSize}`" class="baozhuang">包装规格：{{
                    item.mediumPkgSize
                  }}/{{ item.bigPkgSize }}</span>
                </div>
                <div class="cz_content">
                  <p class="goods-amount">套餐内数量：2</p>
                </div>
              </li>
            </ul>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</div></template>

<script>
import { getAction } from '../../api/manage';
import { ProductMixin } from "@/mixins/ProductMixin";
export default {
  mixins: [ProductMixin],
  data() {
    return {
      productList: [],
      specialAreaMaskImg: '',
      productLoading: false,
    }
  },
  created() {
    this.getProductListData()
  },
  methods: {
    defaultInventory(list) {
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },
    // 获取产品列表数据
    getProductListData() {
      let _this = this;
      let data = {
        pageNo: 1,
        pageSize: 10,
      };
      let url = "/product/getlistE";
      console.log("请求列表的数据为+++++++", data)
      getAction(url, data, true)
        .then((res) => {
          _this.productLoading = false;
          if (res.code === 200 && res.success) {
            let pageData = res.result.pageData;
            _this.productList = pageData.records;
          } else {
            _this.productList = [];
          }
        })
        .catch((error) => {
          _this.productList = [];
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.clear {
  &::after {
    display: block;
    content: '';
    clear: both;

  }
}

.goods-content {
  width: 1200px;
  height: 400px;
  background-color: #ffffff;
  margin-top: 20px;
  .activity-content {
    float: left;
    width: 230px;
    height: 400px;
    padding: 10px 20px;
    font-size: 14px;
    background-color: #EDEEEF;

    .activity-data {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-height: 250px;

      .name {
        color: #0F0F0F;
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .promotion-tips {
        color: #585858;
        line-height: 23px;
        overflow: hidden;
        // 处理文字多行溢出隐藏
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      .act-amount {
        margin-top: 10px;
        width: 160px;
        height: 40px;
        background: #F53002;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        color: #ffffff;
      }
    }

    .activity-price {
      margin-top: 10px;

      p {
        line-height: 25px;

        .label {
          color: #333333;
          display: inline-block;
          width: 48px;
          text-align: justify;
          -moz-text-align-last: justify;
          text-align-last: justify;
        }

        .price {
          font-size: 20px;
          font-weight: bold;
          color: #FD2C17;
        }
      }
    }

    .act-box {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      .input-content {
        width: 94px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        height: 25px;
        border: 1px solid #BFD1DF;

        span {
          display: inline-block;
          width: 20px;
          height: 23px;
          text-align: center;
          line-height: 23px;
          font-size: 12px;
          font-weight: bold;
          color: #798c9c;
          cursor: pointer;

          &:first-child {
            border-right: 1px solid #BFD1DF;
          }

          &:last-child {
            border-left: 1px solid #BFD1DF;
          }
        }

        input {
          display: inline-block;
          width: 50px;
          height: 23px;
          border: none;
        }
      }
    }

    .jionBtnBox {
      font-size: 12px;
    }
  }

  .goods-scroll {
    float: left;
    width: 970px;
    height: 400px;

    .imglistbox {

      &.img-list-flex {
        width: auto;
        display: inline-flex;

        li {
          flex-shrink: 0;
        }
      }

      li {
        border-color: #dcdcdc;
        margin: 0 5px;

        &:first-child {
          margin-left: 10px;
        }
        &:last-child {
          margin-right: 10px;
        }
        .goods-amount {
          color: #333333;
          font-size: 14px;
        }
      }
    }
  }

}

::v-deep .el-scrollbar__bar {
  &.is-horizontal {
    background: #CFCFCF;
  }
}

::v-deep .el-scrollbar__thumb {
  background-color: #0F4C81 !important;
}

@import "../../assets/styles/product/img/img";
</style>