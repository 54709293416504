<template>
  <div class="container">
    <div class="searchContent container_box">
      <div class="logoContent">
        <router-link class="logoLink" to="/index">
          <img v-if="companyConfigData && companyConfigData.pcDarkLogoUrl && companyConfigData.pcDarkLogoUrl !== ''"
            :src="companyConfigData.pcDarkLogoUrl" alt="江药商城" />
          <img v-else src="@/assets/images/common/logo.png" alt="江药商城" />
        </router-link>
        <div class="certificationListBox">
          <div ref="qualificate" class="certificationList"
            :style="`height: ${config.height * qualificateListNew.length}px; 
                                                                                                                                                                                                                                                                              top: -${top}px;`">
            <p v-for="(item, index) of qualificateListNew" :key="'qualificate' + index">
              <a :href="(item.url && item.url !== '') ? item.url : 'javascript:;'"
                :target="(item.url && item.url !== '') ? '_blank' : '_self'">{{ item.name + '：' + item.value }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="searchInputBox">
        <input type="text" ref="searchInput" placeholder="输入商品/厂家汉字或拼音首字母" @focus="selectAllText1('searchInput')"
          @blur="searchBlur()" @keydown="searchIns($event)" @input="fuzzySearch" v-model="searchInputValue"
          @keyup.enter="searchProEnter" class="searchInput" />

        <button v-if="hasCollect" @click="searchProAllClick" class="searchBtn">
          <img src="@/assets/images/common/collectIcon.png" alt="搜索" />
          {{ hasBrandSearch ? '搜全品' : '搜索' }}
        </button>
        <button v-else @click="searchProAllClick" class="searchBtn">
          <img src="@/assets/images/searchContent/searchIcon.png" alt="搜索" />
          {{ hasBrandSearch ? '搜全品' : '搜索' }}
        </button>
        <button v-if="hasBrandSearch" @click="searchBrandProClick" class="searchBtn searchBtn2">
          <img src="@/assets/images/searchContent/searchIcon.png" alt="专区搜索" />
          搜专区
        </button>
        <div v-if="fuzzyTexts.length > 0" id="suggestions_wrap">
          <ul class="fuzzyBox">
            <li :class="{ onLI: onActive == index }" @click="proSearchName(item)" v-for="(item, index) of fuzzyTexts"
              :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
        <div v-if="hotSearch.length > 0" class="hot">
          <span>热门搜索：</span>
          <a @click="inquirePro(itemC)" :key="indexC" v-for="(itemC, indexC) of hotSearch" href="javascript:;">{{
            itemC.val
          }}</a>
        </div>
      </div>

      <router-link to="/shoppingCart" class="shoppingCartBtn">
        <img src="@/assets/images/searchContent/cartIcon.png" alt="购物车" />
        <p>我的购物车</p>
        <span class="cartNums">{{ shoppingCartCount }}</span>
      </router-link>
    </div>
    <div v-show="searchTopShow" class="container searchTop">
      <div class="searchContent container_box">
        <div class="logoContent">
          <router-link class="logoLink" to="/index">
            <img v-if="companyConfigData && companyConfigData.pcDarkLogoUrl && companyConfigData.pcDarkLogoUrl !== ''"
              :src="companyConfigData.pcDarkLogoUrl" alt="江药商城" />
            <img v-else src="@/assets/images/common/logo.png" alt="江药商城" />
          </router-link>
          <div class="certificationListBox">
            <div ref="qualificate" class="certificationList"
              :style="`height: ${config.height * qualificateListNew.length}px; 
                                                                                                                                                                                                                                                                                                                                top: -${top}px;`">
              <p v-for="(item, index) of qualificateListNew" :key="'qualificate' + index">
                <a :href="(item.url && item.url !== '') ? item.url : 'javascript:;'"
                  :target="(item.url && item.url !== '') ? '_blank' : '_self'">{{ item.name + '：' + item.value }}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="searchInputBox">
          <input type="text" ref="searchInput2" placeholder="输入商品/厂家汉字或拼音首字母" @focus="selectAllText1('searchInput2')"
            @blur="searchBlur()" @keydown="searchIns($event)" @input="fuzzySearch" v-model="searchInputValue"
            @keyup.enter="searchProEnter" class="searchInput" />
          <button @click="searchProAllClick" class="searchBtn">
            <img src="@/assets/images/searchContent/searchIcon.png" alt="搜索" />
            {{ hasBrandSearch ? '搜全品' : '搜索' }}
          </button>
          <button v-if="hasBrandSearch" @click="searchBrandProClick" class="searchBtn searchBtn2">
            <img src="@/assets/images/searchContent/searchIcon.png" alt="专区搜索" />
            搜专区
          </button>
          <div v-if="fuzzyTexts.length > 0" id="suggestions_wrap">
            <ul class="fuzzyBox">
              <li :class="{ onLI: onActive == index }" @click="proSearchName(item)" v-for="(item, index) of fuzzyTexts"
                :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div v-if="hotSearch.length > 0" class="hot">
            <span>热门搜索：</span>
            <a @click="inquirePro(itemC)" :key="indexC" v-for="(itemC, indexC) of hotSearch" href="javascript:;">{{
              itemC.val
            }}</a>
          </div>
        </div>
        <router-link to="/shoppingCart" class="shoppingCartBtn">
          <img src="@/assets/images/searchContent/cartIcon.png" alt="购物车" />
          <p>我的购物车</p>
          <span class="cartNums">{{ shoppingCartCount }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getAction } from "../../api/manage";
export default {
  name: "SearchContent",
  data() {
    return {
      searchInputValue: "",
      qualificateList: [
      ],
      timers: null,
      config: {
        height: 20,
        current: 1,
      },
      scrollTop: 0,
      searchTopShow: false,
      routerPath: "",
      onActive: -1,
      fuzzyTexts: [],
      hotSearch: [],
      hasBrandSearch: false,
    };
  },
  created() {
    let path = this.$route.path;
    this.routerPath = path;
    let routerQuery = this.$route.query;
    if ((routerQuery.productLabelList && routerQuery.productLabelList !== '') || path == '/specialArea') {
      this.hasBrandSearch = true
    } else {
      this.hasBrandSearch = false
    }
    this.routerChange();
    this.getHotProduct();
    this.timers = setInterval(this.animation, 5000);
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  destroyed() {
    clearInterval(this.timers);
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters(["shoppingCartCount", "companyConfigData"]),
    top() {
      return this.config.height * this.config.current;
    },
    qualificateListNew() {
      if (this.companyConfigData && this.companyConfigData !== '') {
        let qualificateList = this.companyConfigData.recordNoList;
        let newList = [];
        if (qualificateList && qualificateList.length > 0) {
          newList = [
            qualificateList[qualificateList.length - 1],
            ...qualificateList,
            qualificateList[0],
          ];
        }
        return newList;
      } else {
        return []
      }
    },
    hasCollect() {
      let collectFlag = this.$route.query.collectFlag;
      if (collectFlag && collectFlag == "1") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 点击模糊搜索列表文字
    proSearchName(item) {
      this.searchInputValue = item;
      this.searchProClick()
    },
    // 模糊查询keyup请求事件
    fuzzySearch() {
      this.$util.debounce(() => {
        let proName = this.searchInputValue
        // let proNameArr = proName.split('')// 拆分搜索文字判断搜索的最后一个是字母还是汉字
        // let lastContent = proNameArr[proNameArr.length - 1]
        // // 利用正则判断是否为字母
        // let isZiMu = /[a-z]/i
        // let hasZiMu = isZiMu.test(lastContent)
        // // 如果是字母末尾加*传给后台
        // if (hasZiMu) {
        //   proName = proName + '*'
        // }
        let url = "product/searchName?name=" + encodeURI(proName)
        this.onActive = -1
        getAction(url).then(res => {
          if (res.code == 200 && res.success) {
            let fuzzyTexts = []
            for (let i in res.result) {
              if (i < 10) {
                fuzzyTexts.push(res.result[i])
              }
            }
            this.fuzzyTexts = fuzzyTexts;
          }
        });
      }, 300)
    },
    searchBlur() {
      let _this = this
      let fuzzyTextArr = _this.fuzzyTexts
      let index = _this.onActive
      if (_this.onActive !== -1) {
        _this.searchInput = fuzzyTextArr[index]
      }
      setTimeout(function () {
        _this.onActive = -1
        _this.fuzzyTexts = []
      }, 500)
    },
    // keyDown事件 上下移动事件
    searchIns(ent) {
      let event = ent || window.event
      let allLength = this.fuzzyTexts.length - 1
      if (event.keyCode === 40 && this.onActive < allLength) {
        this.onActive = this.onActive + 1
      } else if (event.keyCode === 38 && this.onActive > 0) {
        this.onActive = this.onActive - 1
      } else if (event.keyCode === 40 && this.onActive === allLength) {
        this.onActive = 0
      } else if (event.keyCode === 38 && this.onActive === 0) {
        this.onActive = allLength
      }
    },
    // 获取热搜列表
    async getHotProduct() {
      let url = "product/getTopSearchList";
      let res = await getAction(url);
      if (res.code == 200 && res.success) {
        this.hotSearch = res.result;
      }
      console.log("获取热搜列表数据为++++++", res);
    },
    // 查询产品
    inquirePro(item) {
      if (item.type == 1) {
        let searchVal = item.val;
        if (this.$route.name === "productList") {
          this.$emit("searchProduct", searchVal);
        } else {
          this.$router.push({
            name: "productList",
            query: { searchVal: searchVal },
          });
        }
      } else if (item.type == 2) {
        location.href = item.goToUrl
      }
    },
    selectAllText() {
      this.$refs.searchInput2.select();
    },
    selectAllText1(searchInput) {
      // 获取光标时请求模糊搜索数据
      // this.fuzzyTexts = ["阿莫西林胶囊", "阿莫西林克拉维酸钾", "阿莫西林", "阿莫西林", "阿莫西林", "阿莫西林", "阿莫西林", "阿莫西林"]
      this.$refs[searchInput].select();
    },
    // 查询专区产品
    searchBrandProClick() {
      let path = this.routerPath;
      // 1. 先判断当前路由地址为产品列表还是专区
      if (path == '/productList') {
        this.hasBrandSearch = true
        this.searchProClick()
      } else if (path == '/specialArea') {
        this.searchProClick(path)
      }
    },
    searchProAllClick() {
      let _this = this;
      this.$util.debounce(() => {
        _this.hasBrandSearch = false
        _this.searchProClick('/productList')
      }, 300)
    },
    searchProEnter() {
      let _this = this;
      this.$util.debounce(() => {
        let routerName = _this.$route.name;
        if (routerName === 'specialArea') {
          _this.hasBrandSearch = true
          _this.searchProClick('/specialArea')
        } else {
          _this.searchProClick()
        }
      }, 300)
    },
    // 查询产品
    searchProClick(path) {
      // 搜索产品移除光标
      if (
        this.$refs.searchInput &&
        typeof this.$refs.searchInput !== "undefined"
      ) {
        this.$refs.searchInput.blur();
        this.searchBlur()
      }
      if (
        this.$refs.searchInput2 &&
        typeof this.$refs.searchInput2 !== "undefined"
      ) {
        this.$refs.searchInput2.blur();
        this.searchBlur()
      }
      let onActive = this.onActive;
      console.log("回车判断用户是否选择了指定的搜索关键词", onActive)
      if (onActive > -1) {
        let fuzzyTexts = this.fuzzyTexts;
        this.searchInputValue = fuzzyTexts[onActive]
      }
      console.log("最终用户搜索的关键词为", this.searchInputValue)
      let searchVal = this.searchInputValue;
      let hasBrandSearch = this.hasBrandSearch;
      if (path === '/specialArea' && this.$route.name === "specialArea") {
        if (this.$route.name === "specialArea") {
          let data = {
            searchVal: searchVal,
            labelFlag: hasBrandSearch
          }
          this.$emit("searchProduct", data);
        } else {
          this.$router.push({
            name: "specialArea",
            query: { searchVal: searchVal },
          });
        }
      } else {
        if (this.$route.name === "productList") {
          let data = {
            searchVal: searchVal,
            labelFlag: hasBrandSearch
          }
          this.$emit("searchProduct", data);
        } else {
          this.$router.push({
            name: "productList",
            query: { searchVal: searchVal },
          });
        }
      }
    },
    // 资质轮播动画
    animation() {
      const $this = this;
      let length = 0
      if (this.companyConfigData && this.companyConfigData.recordNoList && this.companyConfigData.recordNoList.length > 0) {
        length = this.companyConfigData.recordNoList.length;
      }
      let timer = null;
      const $ul = this.$refs.qualificate;
      if ($ul && typeof $ul !== "undefined") {
        $ul.style.transition = "1s";
        this.config.current++;
        clearTimeout(timer);
        if (this.config.current > length) {
          timer = setTimeout(() => {
            $ul.style.transition = "0s";
            $this.config.current = 1;
            clearTimeout(timer);
          }, 1050);
          timer = setTimeout(() => {
            $ul.style.transition = "1s";
            clearTimeout(timer);
          }, 1100);
        }
        if (this.config.current < 1) {
          timer = setTimeout(() => {
            $ul.style.transition = "0s";
            $this.config.current = length;
            clearTimeout(timer);
          }, 1050);
          timer = setTimeout(() => {
            $ul.style.transition = "1s";
            clearTimeout(timer);
          }, 1100);
        }
      }
    },
    // 监听滚动条滚动事件
    handleScroll() {
      this.scrollTop = this.scrollTop =
        window.pageYOffset || document.body.scrollTop;
      const routerName = this.$route.name;
      if (
        this.scrollTop > 200 &&
        (routerName == "" ||
          routerName == "index" ||
          routerName == "productList")
      ) {
        this.searchTopShow = true;
      } else {
        this.searchTopShow = false;
      }
    },
    // 监听路由变化获取购物车数量
    routerChange() {
      let path = this.routerPath;
      if (path === "/index" || path === "/" || path === "/productDetail") {
        this.getPendingOrderCount();
        this.getShoppingCartNum();
      }
    },
    // 获取待确认订单数量
    getPendingOrderCount() {
      let _this = this;
      let url = "/saleOrder/saleOrder/querySaleOrderNum";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let waitConfirmNum = resData.waitConfirmNum;
          let waitPaymentNum = resData.waitPaymentNum;
          let pendingOrderCount = waitConfirmNum + waitPaymentNum;
          _this.$store.commit("PENDING_ORDER_COUNT", pendingOrderCount);
        }
      });
    },
    // 获取购物车列表数量
    getShoppingCartNum() {
      let _this = this;
      let url = "/shopping/cart/getListCount";
      getAction(url).then((res) => {
        if (res.code == 200 && res.success) {
          let shopCartNum = res.result.shoppingCartListcount;
          _this.$store.commit("SHOPPING_CART_COUNT", shopCartNum);
        }
      });
    },
  },

  watch: {
    $route(to) {
      let routerPath = to.path;
      this.routerPath = routerPath;
      let routerQuery = to.query;
      if ((routerQuery.productLabelList && routerQuery.productLabelList !== '') || routerPath == '/specialArea') {
        this.hasBrandSearch = true
      } else {
        this.hasBrandSearch = false
      }
      this.routerChange();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/searchContent";
</style>