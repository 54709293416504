<template>
  <div class="container_box clear">
    <!-- 订单详情头部数据 -->
    <div class="order-header">
      <div class="detail-left">
        <h3 class="title">
          <span>订单信息</span>
        </h3>
        <div class="order-header-data">
          <p>订单编号：{{ saleOrder.id }}</p>
          <p>订单时间：{{ saleOrder.createTime }}</p>
          <p>
            付款类型：{{ saleOrder.feeType === 1 ? "线下支付" : "线上支付" }}
          </p>
          <p v-if="saleOrder.remark && saleOrder.remark !== ''">
            订单备注：{{ saleOrder.remark }}
          </p>
          <p v-if="saleOrder.auditRemark && saleOrder.auditRemark !== ''">
            审核备注：{{ saleOrder.auditRemark }}
          </p>
        </div>
      </div>
      <div class="detail-right">
        <!-- 订单信息 -->
        <div class="order-data-box">
          <div class="order-content">
            <div class="order-main-box clear">
              <span class="fl"></span>
              <p class="status-text">
                订单状态：{{ saleOrder.orderStatusName }}
              </p>
            </div>
            <div class="price-list clear">
              <p>商品总额：{{ saleOrder.originalSaleMoney }}元</p>
              <p v-if="
                saleOrder.confirmDiscountMoney &&
                saleOrder.confirmDiscountMoney > 0
              ">优惠金额：{{ saleOrder.confirmDiscountMoney }}元</p>
              <p v-if="saleOrder.oosMoney > 0">缺货金额：{{ saleOrder.oosMoney }}元</p>
              <p v-if="saleOrder.logisticsMoney > 0">运费金额：{{ saleOrder.logisticsMoney }}元</p>
              <p>实付金额：{{ saleOrder.confirmMoney }}元</p>
            </div>
          </div>
          <div v-if="saleOrder.feeType == 2 && saleOrder.status == 2 && saleOrder.payStatus == 1" class="ope-btn">
            <router-link :to="{
              name: 'applyReturn', query: {
                saleOrderId: saleOrderId
              }
            }" class="apply-return">
              申请退款
            </router-link>
          </div>
        </div>
        <!-- 订单地址信息 -->
        <div v-if="saleOrder.customerAddress && saleOrder.customerAddress !== ''" class="order-address">
          <p class="address-data">收货地址：
            <span>{{ saleOrder.customerAddress.contactsName }}</span>
            <span>{{ saleOrder.customerAddress.contactsTel }}</span>
            <span>{{ saleOrder.customerAddress.address }}</span>
          </p>
          <el-button v-if="false" class="confirm-receipt">确认收货</el-button>
        </div>
      </div>
    </div>
    <!-- 订单明细列表 -->
    <div class="order-pro-list">
      <!-- 订单商品列表 -->
      <div v-if="saleOrderItemList && saleOrderItemList.length > 0" class="pro-list">
        <!-- 订单明细头部信息 -->
        <div class="order-item-title">
          <p class="order-status">订单状态：{{ saleOrder.orderStatusName }}</p>
          <div v-if="false" class="logistics">
            <div class="log-data">
              <span class="express-name">申通快递</span>
              <span class="express-num">运单号：773221696118317</span>
              <span class="current-logistics">2023-05-15 19:06:47包裹已签收！包裹已签收！</span>
            </div>
            <div class="logistics-btn">
              <span class="act-btn">
                查看物流
              </span>
            </div>
          </div>

        </div>
        <!-- 订单产品数据 -->
        <div class="sale-order-content">
          <div class="product-item" v-for="(item, index) of saleOrderItemList" :key="item.id + '_' + index">
            <!-- 产品信息 -->
            <div class="pro-data" @click="linkProDetail(item)">
              <div class="pro-img">
                <a href="javascript:;" @click="linkProDetail(item)">
                  <span v-if="item.returnAmount && item.returnAmount > 0" class="tips tipzp">退货</span>
                  <span v-else-if="item.cxInfo && item.cxInfo !== ''" @click.stop="showCxInfo(item.cxInfo)"
                    class="tips tipzp">促销</span>
                </a>
                <img v-lazy="productImg(item)" alt="" class="img-box">
              </div>
              <div class="base-data">
                <p class="name">{{ item.productName }}</p>
                <p class="format">{{ item.productFormat }}</p>
                <p class="manufacturer">{{ item.productManufacturer }}</p>
              </div>
            </div>
            <div class="pro-right">
              <!-- 订单明细价格 -->
              <div class="pro-price">
                <p class="original-price">单价：￥{{ item.salePrice }}</p>
                <p v-if="item.originalSalePrice !== item.salePrice">原价：￥{{ item.originalSalePrice }}</p>
              </div>
              <!-- 明细数量 -->
              <div class="detailed-quantity">
                <!-- 确认数量 -->
                <div class="confirm-data flex-row">
                  <p>提交数量：{{ item.saleAmount }}</p>
                  <p>出库数量：{{ item.dealAmount }}</p>
                  <p>小计金额：{{ item.confirmMoney }}</p>
                </div>
                <!-- 异常数量 -->
                <div v-if="item.oosDealAmount || item.oosDealMoney || item.errorInfo" class="error-data flex-row">
                  <p>异常数量：{{ item.oosDealAmount }}</p>
                  <p v-if="item.oosDealMoney">异常金额：{{ item.oosDealMoney }}</p>
                  <p class="error-text" @click="showCkErrorInfo(item)">异常原因：{{ item.ckErrorInfo }}</p>
                </div>
                <!-- 已退数量 -->
                <div v-if="item.returnAmount || item.returnMoney" class="return-data flex-row">
                  <p>已退数量：{{ item.returnAmount }}</p>
                  <p>退款金额：{{ item.returnMoney }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="saleOrderItemOosList && saleOrderItemOosList.length > 0" class="pro-list">
        <!-- 订单明细头部信息 -->
        <div class="order-item-title">
          <p class="order-status">缺货列表</p>
        </div>
        <!-- 缺货产品信息 -->
        <div class="sale-order-content">
          <div class="product-item" v-for="(item, index) of saleOrderItemOosList" :key="item.id + '_' + index">
            <!-- 产品信息 -->
            <div class="pro-data" @click="linkProDetail(item)">
              <div class="pro-img">
                <a href="javascript:;" @click="linkProDetail(item)">
                  <span v-if="item.returnAmount && item.returnAmount > 0" class="tips tipzp">退货</span>
                  <span v-else-if="item.cxInfo && item.cxInfo !== ''" @click.stop="showCxInfo(item.cxInfo)"
                    class="tips tipzp">促销</span>
                </a>
                <img v-lazy="productImg(item)" alt="" class="img-box">
              </div>
              <div class="base-data">
                <p class="name">{{ item.productName }}</p>
                <p class="format">{{ item.productFormat }}</p>
                <p class="manufacturer">{{ item.productManufacturer }}</p>
              </div>
            </div>
            <div class="pro-right">
              <!-- 订单明细价格 -->
              <div class="pro-price">
                <p class="original-price">单价：￥{{ item.salePrice }}</p>
                <p>原价：￥{{ item.originalSalePrice }}</p>
              </div>
              <!-- 明细数量 -->
              <div class="detailed-quantity">
                <!-- 提交数量 -->
                <div class="oss-data flex-row">
                  <p>提交数量：{{ item.saleAmount }}</p>
                  <p>缺货数量：{{ item.oosAmount }}</p>
                  <p>缺货金额：{{ item.oosMoney }}</p>
                </div>
                <!-- 缺貨原因 -->
                <div @click="showErrorInfo(item)" v-if="item.errorInfo && item.errorInfo !== ''"
                  class="error-info flex-row error-text">缺货原因：{{ item.errorInfo }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 订单价格信息 -->
      <div class="order-footer">
        <div v-if="discountTicketList.length > 0" class="activity-data">
          <p>{{ discountTicketList[0].name }}：<span>￥{{ saleOrder.confirmDiscountMoney }}</span></p>
        </div>
        <div class="ope-btn">
          <el-button v-if="false" class="qua-download">商品资质下载</el-button>
          <a v-if="saleOrder.invoiceUrl && saleOrder.invoiceUrl !== ''" :href="saleOrder.invoiceUrl" target="_blank">
            <el-button class="invoice-download">发票下载</el-button>
          </a>
          <el-button @click="confirmJionShop" class="add-cart">重新添加购物车</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from "../api/manage";
import { accAdd } from "../assets/js/common/common";
export default {
  name: "OrderDetail",
  data() {
    return {
      saleOrderId: "",
      interestMoney: 0, // 优惠的金额
      saleOrder: {}, // 订单头部数据
      customerAddress: {}, // 订单头部数据
      saleOrderItemList: [], //创建的产品列表数据
      saleOrderItemOosList: [], // 缺货列表的数据
      checked: false,
      returnLogisticsStatus: 1,
      orderStatusType: 0, // 订单的状态 1:待确认 2.待支付
      discountTicketList: [], //订单使用优惠券列表数据
    };
  },
  computed: {
    originalOosMoney() {
      let originalSaleMoney = this.saleOrder.originalSaleMoney
        ? this.saleOrder.originalSaleMoney
        : 0;
      let originalConfirmMoney = this.saleOrder.originalConfirmMoney
        ? this.saleOrder.originalConfirmMoney
        : 0;
      let originalOosMoney = accAdd(originalSaleMoney, -originalConfirmMoney);
      return originalOosMoney;
    },
  },
  created() {
    let saleOrderId = this.$route.query.saleOrderId;
    this.saleOrderId = saleOrderId;
    if (saleOrderId) {
      this.getOrderDeatil();
    }
  },
  methods: {
    linkProDetail(item) {
      let text = this.$router.resolve({ path: 'productDetail', query: { productId: item.productId } });
      window.open(text.href, '_blank');
    },
    showCxInfo(cxInfo) {
      this.$alert(cxInfo ? cxInfo : '', "促销信息", {
        type: "info",
      });
    },
    // 展示商品缺货原因
    showErrorInfo(item) {
      this.$alert(item.errorInfo ? item.errorInfo : '缺货原因不明，请联系开票员！', "缺货原因", {
        type: "error",
      });
    },
    // 展示商品缺货原因
    showCkErrorInfo(item) {
      this.$alert(item.ckErrorInfo ? item.ckErrorInfo : '异常原因不明，请联系开票员！', "异常原因", {
        type: "error",
      });
    },
    gotoOrderList() {
      this.$router.push({
        name: "orderList",
        query: {
          state: 3,
        },
      });
    },
    productImg(item) {
      if (item.productPic && item.productPic !== "") {
        let picUrlArr = item.productPic.split(",");
        const imgsArr = picUrlArr[0];
        const imgSrc = this.$util.resizeImg(this.$imgPath + imgsArr,80)
        return imgSrc;
      } else {
        const imgSrc = "../../assets/images/common/dataNone.png";
        return imgSrc;
      }
    },
    // 查看订单批次效期
    lookProductBatchData(item) {
      let url = "/saleOrder/saleOrderItemBatch/listBySaleOrderItemId";
      let data = {
        saleOrderItemId: item.id,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          if (resData && resData.length > 0) {
            let batchData = res.result[0];
            let productBatchData = `批次：${batchData.batchId}<br>批号：${batchData.batchNumber}<br>生产日期：${batchData.productDate}<br>有效期：${batchData.expireDate}`;
            this.$alert(productBatchData, "产品批次信息：", {
              dangerouslyUseHTMLSdiving: divue,
            });
          } else {
            this.$alert("暂无批次效期数据！", "产品批次信息：", {
              type: "error",
            });
          }
        }
      });
    },
    // 订单申请展示的方式
    showStatus(saleOrder) {
      // 订单付款方式
      let feeType = saleOrder.feeType;
      // 订单付款状态
      let payStatus = saleOrder.payStatus;
      // 订单的创建状态
      let status = saleOrder.status;
      if (feeType === 2 && payStatus === 1 && status === 2) {
        return true;
      } else {
        return false;
      }
    },
    // 确认订单操作
    linkConfirmOrder(item) {
      let saleOrderId = item.id;
      this.$router.push({
        path: "/confirmOrder",
        query: { saleOrderId: saleOrderId },
      });
    },
    // 跳转支付操作
    linkPayOrder(item) {
      let payOrderId = item.payOrderId;
      let saleOrderId = item.id;
      this.$router.push({
        path: "/orderPay",
        query: { payOrderId: payOrderId, saleOrderId: saleOrderId },
      });
    },
    // 获取订单详情数据
    getOrderDeatil() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      let url = "/saleOrder/saleOrder/querySaleOrder";
      let data = {
        saleOrderId: saleOrderId,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          that.returnLogisticsStatus = resData.returnLogisticsStatus;
          that.saleOrder = resData.saleOrder;
          that.discountTicketList = resData.discountTicketList;
          that.customerAddress = resData.customerAddress;
          that.saleOrderItemList = resData.saleOrderItemList;
          that.saleOrderItemOosList = resData.saleOrderItemOosList;
        }
      });
    },
    // 订单状态
    orderStatus(item) {
      let status = item.status; // -1:已取消 0:草稿 1:待确认 2:已完成
      let feeType = item.feeType; // 付款类型1：线下2：线上
      let cancelFlag = item.cancelFlag; // 订单取消状态
      let payStatus = item.payStatus; //付款状态，0初始 1支付完成
      let ckStatus = item.ckStatus; // 出库状态 0 未出库 1.已出库 2.出库失败
      if (cancelFlag == 1) {
        return "已取消";
      } else if (status === 1) {
        this.orderStatusType = 1;
        return "待确认";
      } else if (status === 2) {
        if (feeType === 2 && payStatus === 0) {
          this.orderStatusType = 2;
          return "待支付";
        } else {
          return "已完成";
        }
      }
    },
    // 申请退货的操作
    applyForReturn(item) {
      let saleOrderId = item.id;
      this.$router.push({
        path: "returnRequest",
        query: { saleOrderId: saleOrderId },
      });
    },
    // 批量加入购物车提示操作
    confirmJionShop() {
      let _this = this;
      this.$confirm("是否将订单批量添加至购物车?", "确认提示:", {
        type: "success",
      })
        .then((res) => {
          _this.jionShoppingCart()
        })
        .catch((error) => { });
    },
    // 批量添加购物车
    jionShoppingCart() {
      let _this = this;
      let saleOrderItemList = this.saleOrderItemList;
      let jionProList = [];
      for (let key of saleOrderItemList) {
        let data = {
          uid: key.inventoryUid,
          addPrice: key.confirmMoney,
          amount: key.confirmAmount,
        };
        jionProList.push(data);
      }
      let url = "/shopping/cart/addList";
      postAction(url, jionProList).then((res) => {
        if (res.code === 200 && res.success) {
          this.$alert(res.message, "提示消息：", { type: "success" });
          _this.$router.push("/shoppingCart");
        }
      });
    },
    // 申请退运费
    applyReturnLog() {
      let _this = this;
      this.$prompt("", "申请退运费原因:", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        let saleOrder = _this.saleOrder;
        let saleOrderId = saleOrder.id;
        let payOrderId = saleOrder.payOrderId;
        let url = "/saleOrder/saleReturnOrder/add";
        let data = {
          saleReturnOrder: {
            saleOrderId: saleOrderId,
            payOrderId: payOrderId,
            type: 3,
            remark: value,
          },
        };
        postAction(url, data, divue).then((res) => {
          if (res.code === 200 && res.success) {
            _this.returnLogisticsStatus = 1;
            _this.$alert("申请退运费已提交，请耐心等待审核！", {
              type: "success",
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/order/detail"
</style>