<template>
  <div id="goodsImgBox" class="container">
    <div class="container_box">
      <ul class="imglistbox">
        <li v-for="(item, index) of productList" v-bind:key="'imgProduct' + index"
          :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }">
          <div>
            <div class="productImgBox">
              <router-link :to="{
                path: '/productDetail',
                query: { productId: item.productId },
              }" :title="item.name" target="_blank">
                <!-- 查看专区产品遮罩图 -->
                <img v-if="specialAreaMaskImg && specialAreaMaskImg !== ''" :src="specialAreaMaskImg" class="maskImg"
                  alt="产品遮罩图" />
                <!-- 查看产品标签遮罩图 -->
                <img v-else-if="
                  item.productLabelList && item.productLabelList.length > 0
                  && prolabelMaskImg(item.productLabelList) !== ''
                " :src="prolabelMaskImg(item.productLabelList)" class="maskImg" alt="产品遮罩图" />
                <img class="productImg" v-lazy="productImg(item)" />
              </router-link>
            </div>
          </div>
          <!--产品名称-->
          <div class="img-mingzi">
            {{ item.name }}
          </div>
          <!--判断产品是否有库存-->
          <div v-if="!item.inventoryList || item.inventoryList.length === 0" class="proPriceContent clearfix">
            <p class="priceBox fl" style="font-size: 17px">
              {{ loginState? "￥--/--": "会员可见" }}
            </p>
          </div>
          <div v-else-if="item.offlineSaleFlag && item.offlineSaleFlag == '1'  && item.productNotputSaleShowPrice !== '1'" class="proPriceContent clearfix">
            <p class="priceBox fl" style="font-size: 17px">
              请联系开票员
            </p>
          </div>
          <!-- 秒杀产品显示的价格 -->
          <div v-else-if="
            item.productActivityPriceStrategyFlag === 1 &&
            item.productActivityPriceStrategyDetail &&
            item.productActivityPriceStrategyDetail.canSaleAmount
          " class="proPriceContent clearfix">
            <p class="priceBox fl">
              ￥{{ item.productActivityPriceStrategyDetail.price }}
            </p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.productActivityPriceStrategyDetail.originalPrice }}
            </p>
          </div>
          <!-- 2.再判断产品是否参与折扣活动-->
          <div v-else-if="
            item.inventoryList[0].discountPrice &&
            item.inventoryList[0].discountPrice !== ''
          " class="proPriceContent clearfix">
            <p class="priceBox fl">
              ￥{{ item.inventoryList[0].discountPrice }}
            </p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.inventoryList[0].price }}
            </p>
          </div>
          <!-- 3.再判断产品策略里面是否定义了原价-->
          <div v-else-if="
            item.inventoryList[0].originalPrice &&
            item.inventoryList[0].originalPrice !== ''
          " class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.inventoryList[0].originalPrice }}
            </p>
          </div>
          <!-- 4.显示原价 -->
          <div v-else class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
          </div>
          <div v-if="
            loginState && item.inventoryList && item.inventoryList.length > 0
          " class="tipBox">
            <el-tooltip v-if="
              item.productActivityPriceStrategyFlag === 1 &&
              item.productActivityPriceStrategyDetail &&
              item.productActivityPriceStrategyDetail.canSaleAmount
            " class="item" effect="dark" content="该产品为秒杀产品" placement="top">
              <span class="tip1">秒杀</span>
            </el-tooltip>

            <el-tooltip v-if="
            item.inventoryList[0].newCustomerPriceFlag &&
            item.inventoryList[0].newCustomerPriceFlag == '1'" class="item" effect="dark" content="新用户特价产品"
              placement="top">
              <span class="tip3">特价</span>
            </el-tooltip>
            <span class="tip1" :style="
              item.inventoryList[0].deliveryTime === '24'
                ? 'background-color:#009944'
                : 'background-color:#0f4c81'
            ">{{
  item.inventoryList[0].deliveryTime
    ? item.inventoryList[0].deliveryTime
    : "24H"
}}</span>
            <span class="tip2" v-if="
              item.inventoryList[0].priceTitle &&
              item.inventoryList[0].priceTitle !== ''
            ">{{ item.inventoryList[0].priceTitle }}</span>

            <el-tooltip v-if="item.policyFlag && item.productPolicyList && item.productPolicyList.length > 0"
              class="item" effect="dark" :content="item.productPolicyList[0].remark" placement="top">
              <span class="tip3">促销</span>
            </el-tooltip>
            <el-tooltip v-if="item.inventoryList[0].nearDateFlag" class="item" effect="dark" content="该产品为近效期产品"
              placement="top">
              <span class="tip4">近效期</span>
            </el-tooltip>
            <span class="tip5" v-if="
              (item.productActivityPriceStrategyFlag !== 1 ||
                item.productActivityPriceStrategyDetail.canSaleAmount ===
                0) &&
              item.minDiscountTicket &&
              item.minDiscountTicket.name !== ''
            ">
              {{ item.minDiscountTicket.name }}
            </span>
          </div>
          <div class="tipBox" v-else>
            <span v-if="!loginState" class="tip1" style="background-color:#009944">24H</span>
          </div>
          <div style="cursor: pointer" @click="searchFatory(item.manufacturer)" class="img-compamy"
            :title="item.manufacturer">
            {{
              item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== ""
                ? item.manufacturer
                : "暂无厂家信息！"
            }}
          </div>
          <div class="img-guige">
            规格：<span :title="item.format">{{ item.format }}</span>
          </div>
          <div v-if="loginState" class="img-guige">
            有效期:
            {{
              item.inventoryList &&
                item.inventoryList.length > 0 &&
                item.inventoryList[0].endDate &&
                item.inventoryList[0].endDate !== ""
                ? item.inventoryList[0].endDate
                : "暂无效期"
            }}
          </div>
          <div v-else class="img-guige">
            有效期: <span style="color:#fd2c17">会员可见</span>
          </div>
          <div class="img-xiaoqi">
            <span v-if="item.inventoryList && item.inventoryList.length > 0" class="kucun">
              库存：{{ showInventoryText(item.inventoryNum, item) }}
            </span>
            <span v-else-if="loginState" class="kucun">库存：暂无库存</span>
            <span v-else class="kucun">库存：<i style="font-style:normal;color:#fd2c17">会员可见</i></span>
            <span :title="`${item.mediumPkgSize}/${item.bigPkgSize}`" class="baozhuang">中/大包装：{{
              item.mediumPkgSize
            }}/{{ item.bigPkgSize }}</span>
          </div>
          <div v-if="!loginState" class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText" style="color:#fd2c17">会员可见</p>
            </div>
            <div @click="gotoLogin" class="jionBtnBox">
              <img src="../../assets/images/product/img/jionShop.png" />
              加入购物车
            </div>
          </div>
          <div v-else-if="defaultInventory(item.inventoryList).length > 0" class="cz_content">
            <div v-if="item.inventoryList && item.inventoryList.length > 0 && item.productNotputSaleCreateOrder !== '0'" class="amoutBox">
              <span @click="lessNum(item.inventoryList[0], item)">-</span>
              <input :class="{
                shoppingHas:
                  item.inventoryList[0].shoppingCartAmount &&
                  item.inventoryList[0].shoppingCartAmount > 0,
              }" :value="item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
                @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
              <span @click="addNum(item.inventoryList[0], item)">+</span>
            </div>
            <div @click="jionShopCart(item.inventoryList[0], item, $event)" class="jionBtnBox" :style="(item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '0') ? 'width: 100%' : ''">
              <img src="../../assets/images/product/img/jionShop.png" />
              {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1') ? item.productNotputSaleCreateOrder == '1' ? '开票员订货' : '请联系开票员订货' : '加入购物车' }}
            </div>
          </div>
          <div v-else class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText">暂无库存</p>
            </div>
            <div class="jionBtnBox spqgBtnBox">
              <router-link :to="{ path: '/wantToBuyProduct', query: qiugouDenglu(item) }">
                <img src="../../assets/images/product/img/search.png" />
                商品求购
              </router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
export default {
  name: "GoodsImg",
  props: [
    "productList",
    "isClinicAccount",
    "hyProductStatus",
    "specialAreaMaskImg",
  ],
  mixins: [ProductMixin],
  data() {
    return {
      showMoveDot: [],
      elLeft: 0,
      elTop: 0,
      dropImage: false,
    };
  },
  methods: {
    listTypeChange(num) {
      this.$emit("listTypeChange", num);
    },
    beforeEnter(el) {
      el.style.transform = `translate3d(${this.elLeft + 50}px,${this.elTop - 300
        }px , 0)`;
      el.style.opacity = 0;
    },
    defaultInventory(list) {
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/product/img/img";
</style>
