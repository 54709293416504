<template>
  <div class="wantBuyBox">
    <div class="header">
      开票员订货系统
    </div>
    <div class="content">
      <div class="formdata">
        <!-- <div class="name">
          <p>商品名称</p>
          <input disabled="true" v-model="productDetail.name" type="text">
        </div>
        <div class="format">
          <p>产品规格</p>
          <input disabled="true" v-model="productDetail.format" type="text">
        </div>
        <div class="manufacturer">
          <p>生产厂家</p>
          <input disabled="true" v-model="productDetail.manufacturer" type="text">
        </div>
        <div class="batch-no">
          <p>产品批号</p>
          <input disabled="true" v-model="inventoryAct.batchNumber" type="text">
        </div>
        <div class="stock">
          <p>产品库存</p>
          <input disabled="true" v-model="inventoryAct.inventory" type="text">
        </div> -->
        <div class="invoiced-amount">
          <p>开票金额</p>
          <input disabled="true" style="color:#fd2c17" v-model="inventoryAct.price" type="text">
        </div>
        <div class="invoiced-quantity">
          <p>开票数量</p>
          <input class="amount" v-model="saleProductAmount" @keyup.enter="submitWantBuy" @input="changeSaleAmount($event)" type="text">
        </div>
        <div class="total-amount">
          <p>总金额</p>
          <input disabled="true" style="color:#fd2c17" v-model="saleProductMoney" type="text">
        </div>
        <div class="submitBox">
          <a @click="submitWantBuy" href="javascript:;">保存</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from '../api/manage'
import { accMuls } from "../assets/js/common/common";
export default {
  name: "LinkToSale",
  data() {
    return {
      productDetail: {
      }, // 产品详情数据
      inventoryAct: {}, // 当前选中的产品库存
      tId: '',
      loading: false,
      productId: '',
      saleProductMoney: 0, // 采购产品的总价格
      saleProductAmount: 0,
    }
  },
  created() {
    let productId = this.$route.query.productId;
    this.backTop()
    if (productId && productId !== '') {
      this.productId = productId;
      this.getProductDetail()
    } else {
      this.$router.push('/productList')
    }
  },
  methods: {
    // 获取产品详情数据
    async getProductDetail() {
      const productId = this.productId;
      const url = "/product/getProductDetail";
      const data = {
        productId: productId,
      };
      this.loading = true;
      let res = await getAction(url, data);

      this.loading = false;
      if (res.code === 200 && res.success) {
        this.productDetail = res.result;
        console.log("当前产品详情数据为", this.productDetail)
        if (res.result.inventoryList && res.result.inventoryList.length > 0) {
          let inventoryList = res.result.inventoryList;
          if (inventoryList && inventoryList.length > 0) {
            this.inventoryAct = inventoryList[0];
            console.log("当前选中的产品库存数据为", this.inventoryAct)
            if (this.inventoryAct.uid) {
              const uidArr = this.inventoryAct.uid.split(",,");
              const tId = uidArr[uidArr.length - 1];
              this.tId = tId;
            }
          }
        } else {
          this.$message({
            offset: 200,
            duration: 1000,
            message: "当前产品暂无库存！",
            type: 'error'
          })
          this.$router.push('/productList')
        }
      }
    },
    // 修改采购未挂网产品数量
    changeSaleAmount(e) {
      let saleAmout = this.saleProductAmount.replace(/[^0-9]/g, '');
      let inventory = this.inventoryAct.inventory
      if (saleAmout > inventory) {
        saleAmout = inventory
        this.$message({
          offset: 200,
          duration: 1000,
          message: "当前产品最大库存为：" + inventory,
          type: 'error'
        })
      } else if (saleAmout <= 0) {
        saleAmout = 1
      }
      this.saleProductAmount = saleAmout;
      // 触发计算总金额
      this.calculateTotalPrice()
    },
    // 计算采购总金额
    calculateTotalPrice() {
      let amount = this.saleProductAmount;
      let price = this.inventoryAct.price;
      let allMoney = accMuls(price, amount)
      this.saleProductMoney = allMoney
    },
    // 点击保存未挂网产品
    async submitWantBuy() {
      let addPrice = this.inventoryAct.price;
      let amount = this.saleProductAmount;
      let uid = this.inventoryAct.uid;
      let addShopData = {
        addPrice: addPrice,
        amount: amount,
        uid: uid
      };
      let addShopUrl = "/shopping/cart/add?getList=1";
      let res = await postAction(addShopUrl, addShopData);
      if (res.code == 200 && res.success) {
        this.$message({
          message: `商品已添加到购物车！`,
          offset: 400,
          duration: 2000,
          type: 'success'
        })
        this.$router.push('/productList');
        // closeWindow()
      }
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/center/wantToBuyProduct";
.amount {
  background-color: #fddbce !important;
}
.invoiced-amount,.invoiced-quantity,.total-amount {
  width: 100% !important;
  display: flex;
  justify-content: center;
}
</style>